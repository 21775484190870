import React, { useState, useEffect, useRef } from "react";
import './componentstyle.css';

export function ReadMoreComponent({
  title = null,
  paragraph = '',
  percent = 40, // Percentage of container width to determine visible text
  containerStyle = {},
  titleStyle = {},
  paragraphStyle = {},
  readMoreComponent = 'read more',
  readLessComponent = 'read less'
}) {
  const [expanded, setExpanded] = useState(false);
  const [visibleChars, setVisibleChars] = useState(paragraph.length); // Initial state to show full text
  const containerRef = useRef(null);

 
  // Function to calculate visible characters based on container width
  const calculateVisibleChars = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const targetWidth = containerWidth * ((percent - 5) / 100);

      const tempSpan = document.createElement("span");
      tempSpan.style.visibility = "hidden";
      tempSpan.style.position = "absolute";
      tempSpan.style.whiteSpace = "nowrap";
      document.body.appendChild(tempSpan);

      let charCount = 0;
      let currentText = '';

      while (charCount < paragraph.length) {
        currentText += paragraph[charCount];
        tempSpan.innerText = currentText;
        if (tempSpan.offsetWidth > targetWidth) break;
        charCount++;
      }

      setVisibleChars(charCount);
      document.body.removeChild(tempSpan);
    }
  };
  useEffect(() => {
    calculateVisibleChars();

    window.addEventListener("resize", calculateVisibleChars);
    return () => window.removeEventListener("resize", calculateVisibleChars);
  }, [paragraph, percent]);

  const toggleExpand = () => setExpanded(!expanded);

  return (
    <div className="expandable-container" style={containerStyle}>
      {title && (
        <label className="flex" style={{ fontWeight: 900, marginBottom: 10, ...titleStyle }}>{title}</label>
      )}
      <p ref={containerRef} className="expandable-paragraph" style={{fontWeight:400,fontSize:16,color:'#828282', ...paragraphStyle}}>
        {expanded ? paragraph : paragraph.slice(0, visibleChars)}
        {!expanded && paragraph.length > visibleChars && (
          <small className="expand-toggle-label" onClick={toggleExpand} >... {readMoreComponent}</small>
        )}
        {expanded && (
          <small className="expand-toggle-label less" onClick={toggleExpand}> {readLessComponent}</small>
        )}
      </p>
    </div>
  );
}
