import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from 'primereact/radiobutton'
import { useDropzone } from 'react-dropzone';
import { Badge } from 'primereact/badge';
import { Message } from 'primereact/message';
import moment from "moment";
import useForceUpdate from "use-force-update";
import { DateTime } from 'luxon';
import Axios from 'axios';
import APIServices from '../../../service/APIService';
import { API } from '../../../components/constants/api_url';
import { useSelector } from 'react-redux';
import { name } from 'xlsx-populate/lib/RichTextFragment';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import Swal from 'sweetalert2';
import { Tooltip } from 'primereact/tooltip';
import { TabPanel, TabView } from 'primereact/tabview';
import { InputTextarea } from 'primereact/inputtextarea';
import { getLocationData } from '../../../components/BGHF/helper';

const getID = () => {
    return parseInt(Date.now() * Math.random()) + moment().unix() + randomIntFromInterval(1, 1000)

}
const randomIntFromInterval = (min, max) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}
const STTInitiative = ({ }) => {

    const [activetab, setActiveTab] = useState(0)
    const [type, setType] = useState(null)
    const [visible, setVisible] = useState({ one: [], two: [], three: [] })

    const [record, setRecord] = useState([])
    const [dialog1, setDialog1] = useState(false)
    const [dialog2, setDialog2] = useState(false)
    const [dialog3, setDialog3] = useState(false)
    const forceUpdate = useForceUpdate();
    const tier_label = useSelector(state => state.user.tierLabel)
    const login_data = useSelector((state) => state.user.userdetail)
    const admin_data = useSelector((state) => state.user.admindetail)
    const [submitted, setSubmitted] = useState(false)
    const allRoles = useSelector((state) => state.user.allRoles)
    const [selectedaspects, setSelectedAspects] = useState(null)
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [countryList, setCountryList] = useState([])
    const DPANN0027 = [
        { label: 'Desktop & Study', value: 1 },
        { label: 'In-progress Implementation', value: 2 },
        { label: 'Implemented', value: 3 },
        { label: 'On-hold', value: 4 },
        { label: 'Cancelled', value: 5 },
        { label: 'Feasibility Study', value: 6 },
        { label: 'Cancelled Implementation', value: 7 },
        { label: 'Completed POC/ Pilot Deployment', value: 8 },
        { label: 'In-progress - Roll out program to applicable geographies', value: 9 },
        { label: 'Completed - Roll out program to applicable geographies', value: 10 },
        { label: 'Planning', value: 11 },
        { label: 'In-progress', value: 12 },
        { label: 'Completed', value: 13 },

    ]
    const [boundarylist, setBoundaryList] = useState([{ name: 'POC/ pilot scope', id: 1 }, { name: 'Full deployment in selected data centre', id: 2 }, { name: 'Full deployment in all data centres', id: 3 }])

    const [rawsitelist, setRawSitelist] = useState([]);
    const [allcategorylist, setAllCategoryList] = useState([{ name: 'GHG Reduction', id: 1 }, { name: 'Energy Reduction', id: 2 }, { name: 'Water Use Reduction', id: 3 }, { name: 'Water Discharge Reduction', id: 4 }, { name: 'Waste Reduction', id: 5 }, { name: 'Scope 1 & 2 GHG Reduction', id: 6 }, { name: 'Scope 3 GHG Reduction', id: 7 }, { name: 'Number of Beneficiaries', id: 8 }, { name: 'Economic benefit to communities (USD)', id: 9 }, { name: 'Number of Volunteer Manhours', id: 10 }, { name: 'Number of Employee Volunteers', id: 11 }, { name: 'Others - Please Enter Unit in Remarks', id: 12 }])

    const [allunitlist, setAllUnitList] = useState([{ name: 'tCO2e', id: 1 }, { name: 'kWh', id: 2 }, { name: 'm3', id: 3 }, { name: 'm3', id: 4 }, { name: 'tonnes', id: 5 }, { name: 'tCO2e', id: 6 }, { name: 'tCO2e', id: 7 }])
    const [categorylist1, setCategoryList1] = useState([{ name: 'GHG Reduction', id: 1 }, { name: 'Energy Reduction', id: 2 }, { name: 'Water Use Reduction', id: 3 }, { name: 'Water Discharge Reduction', id: 4 }, { name: 'Waste Reduction', id: 5 }])
    const [unitlist1, setUnitList1] = useState([{ name: 'tCO2e', id: 1 }, { name: 'kWh', id: 2 }, { name: 'm3', id: 3 }, { name: 'm3', id: 4 }, { name: 'tonnes', id: 5 }])
    const [categorylist3, setCategoryList3] = useState([{ name: 'Scope 1 & 2 GHG Reduction', id: 6 }, { name: 'Scope 3 GHG Reduction', id: 7 }, { name: 'Energy Reduction', id: 2 }, { name: 'Water Use Reduction', id: 3 }])
    const [unitlist3, setUnitList3] = useState([{ name: 'tCO2e', id: 6 }, { name: 'tCO2e', id: 7 }, { name: 'kWh', id: 2 }, { name: 'm3', id: 3 }])
    const [categorylist2, setCategoryList2] = useState([{ name: 'Number of Beneficiaries', id: 8 }, { name: 'Economic benefit to communities (USD)', id: 9 }, { name: 'Number of Volunteer Manhours', id: 10 }, { name: 'Number of Employee Volunteers', id: 11 }, { name: 'Others - Please Enter Unit in Remarks', id: 12 }])

    const [obj, setObject] = useState({
        initiativeTitle: '',
        additionalDescription: '',
        data: [] || {},
        remarks: '',
        attachment: []
    });

    useEffect(() => {


        let uriString = {
            include: [
                {
                    relation: "locationTwos",
                    scope: { include: [{ relation: "locationThrees" }] },
                },
            ],
        };
        const promise2 = APIServices.get(
            API.LocationOne_UP(admin_data.id) +
            `?filter=${encodeURIComponent(JSON.stringify(uriString))}`
        );
        const promise3 = APIServices.get(API.GetRole_Up(admin_data.id))
        const promise4 = APIServices.get(API.NewClientInitiative_UP(admin_data.id))
        const promise5 = APIServices.get(API.NewClientInitiativeUserAss_UP(admin_data.id))

        Promise.all([

            promise2, promise3, promise4, promise5

        ]).then((values) => {
            setRecord(values[2].data)
            setVisible(values[3].data)
            let shapedSite = values[0].data.map((item) => {
                if (item.locationTwos) {
                    item.locationTwos = item.locationTwos.filter(
                        (locationTwo) =>
                            locationTwo.locationThrees &&
                            locationTwo.locationThrees.length > 0
                    );
                }
                return item;
            })
                .filter((item) => item.locationTwos && item.locationTwos.length > 0);
            if (login_data.role !== 'clientadmin') {
                shapedSite = getLocationData(values[0].data, values[1].data.filter(i => i.user_id === login_data.id), [18]).map((item) => {
                    if (item.locationTwos) {
                        item.locationTwos = item.locationTwos.filter(
                            (locationTwo) =>
                                locationTwo.locationThrees &&
                                locationTwo.locationThrees.length > 0
                        );
                    }
                    return item;
                })
                    .filter((item) => item.locationTwos && item.locationTwos.length > 0);

            }

            setRawSitelist(shapedSite)
            setCountryList(shapedSite.map(i => ({ name: i.name, id: i.id })))

        })
    }, [])

    const dropzoneStyle = {
        border: '2px dashed gray',
        borderRadius: '5px',
        padding: '40px 20px', // Increased padding as requested
        textAlign: 'center',
        cursor: 'pointer'
    };
    const getDate = (date, format) => {
        console.log(date)
        if (typeof date === 'string') {
            return DateTime.fromISO(date, { zone: 'utc' }).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        } else if (DateTime.isDateTime(date)) {
            return date.toFormat(format ? format : 'dd-MM-yyyy')
        } else {
            return DateTime.fromJSDate(date).toLocal().toFormat(format ? format : 'dd-MM-yyyy')
        }

    };
    const openNewDilaog = (type) => {
        setType(type)
        if (type === 1) {
            setObject({
                plannedImplementationDate: null, locationOfProject: '', currentStatus: null,
                initiativeTitle: '',
                additionalDescription: '',
                data: [{
                    country: null, city: null, location: null, DPANN0023: null, DPANN0024: null, DPANN0025: null, aspects: []
                }],
                remarks: '',
                attachment: []
            });
            setDialog1(true)
        } else if (type === 2) {
            setObject({
                plannedImplementationDate: null, locationOfProject: '', currentStatus: null,
                data: [],
                initiativeTitle: '',
                additionalDescription: '',
                attachment: [],
                remarks: ''
            })
            setDialog2(true)
        } else if (type === 3) {
            setObject({
                plannedImplementationDate: null, locationOfProject: '', currentStatus: null,
                initiativeTitle: '',
                additionalDescription: '',
                data: [{
                    country: null, city: null, location: null, DPANN0043: null, DPANN0044: null, DPANN0045: null, aspects: []
                }],
                remarks: '',
                attachment: []
            });
            setDialog3(true)
        }

    }
    function getFileExtension_(filename) {
        const lastDot = filename.lastIndexOf('.');
        let ext = lastDot === -1 ? '' : filename.slice(lastDot + 1).toLowerCase();
        let ext_types = ['ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx', 'pdf', 'jpeg', 'jpg', 'png', 'bmp']
        console.log(ext)
        return ext_types.includes(ext)
    }
    const { getRootProps, getInputProps, isDragActive } = useDropzone({

        accept: "image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint",
        onDrop: (acceptedFiles) => {
            console.log(acceptedFiles);
            if (acceptedFiles.filter((a) => { return a.size <= 20000000 }).length + obj.attachment && getFileExtension_(acceptedFiles[0].name)) {
                let formData = new FormData()
                acceptedFiles.filter((a) => { return a.size <= 20000000 }).forEach((file) => {
                    formData.append('file', file);
                })

                APIServices.post(API.FilesUpload, formData, {
                    headers: {
                        'content-type': 'multipart/form-data'

                    }
                }).then((res) => {
                    let loc = obj
                    let filelist = []

                    res.data.files.forEach((file) => {
                        filelist.push(file)
                    })
                    loc.attachment = filelist
                    console.log(res.data)
                    setObject(loc)
                    forceUpdate();

                })
            }

            // Handle file logic here
        }
    });
    function getFileExtension(filename) {
        const lastDot = filename.lastIndexOf('.');
        return lastDot === -1 ? '' : filename.slice(lastDot + 1).toLowerCase();
    }
    const attachmentTemplate = (file) => {
        console.log(file)
        return (<div className="flex align-items-center text-three-dot col-10 mt-2">
            {(getFileExtension(file.originalname) === 'pdf') ?
                <img src={require('../../../assets/images/pdf_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                (getFileExtension(file.originalname) === 'xlsx' || getFileExtension(file.originalname) === 'xls') ?
                    <img src={require('../../../assets/images/excel_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                    (getFileExtension(file.originalname) === 'pptx' || getFileExtension(file.originalname) === 'ppt') ?
                        <img src={require('../../../assets/images/ppt_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                        (getFileExtension(file.originalname) === 'docx' || getFileExtension(file.originalname) === 'doc') ?
                            <img src={require('../../../assets/images/word_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
            <span className="flex align-items-center text-left ml-3  clr-navy cur-pointer" onClick={() => { window.open(API.Docs + file.originalname) }}>
                {file.originalname.slice(13)} <Tag className={'status-tag-gray ml-2'} style={{ display: 'block', textDecoration: 'none' }} value={'Updated_on: ' + DateTime.fromISO(file.uploaded_on, { zone: 'utc' }).toLocal().toFormat('dd-LL-yyyy hh:mm a')} />
                {/* <small>{new Date().toLocaleDateString()}</small> */}
            </span>
            <span>
                <i onClick={() => { obj.attachment.splice(0, 1); forceUpdate() }} className='material-icons clr-delete cur-pointer'>delete</i>
            </span>
        </div>)
    }
    const updateObject = (x, val) => {
        let local = obj;
        local[x] = val;
        console.log(local)
        setObject(local)
        forceUpdate();
    };

    const addNewAspects = (index) => {
        let loc = obj

        if (loc.data[index].aspects) {
            // let check = loc.data[index].aspects.every(i => i.DPANN0028 !== null && parseFloat(i.DPANN0028) >= 0 && i.DPANN0027 !== null)


            loc.data[index].aspects = [...loc.data[index].aspects, {
                DPANN0026: null,
                DPANN0028: 0,
                DPANN0027: null
            }];
            console.log(loc.data[index].aspects)


        } else {
            loc.data[index].aspects = [{
                DPANN0026: null,
                DPANN0028: 0,
                DPANN0027: null
            }]
        }

        console.log(loc)
        setObject(loc)
        forceUpdate()
    }
    const addNewAspects3 = (index) => {
        let loc = obj

        if (loc.data) {
            // let check = loc.aspects.every(i => i.DPANN0037 !== null && parseFloat(i.DPANN0037) >= 0)
            if (true) {

                loc.data = [...loc.data, {
                    DPANN0036: null,
                    DPANN0037: 0
                }];

            }

        } else {
            loc.data = [{
                DPANN0036: null,
                DPANN0037: 0
            }]
        }

        console.log(loc)
        setObject(loc)
        forceUpdate()
    }
    const saveData = () => {
        // Logic to save data
        // onHide();

        setSubmitted(true);
        let local = obj;
        console.log(local)
        let local2 = record;
        if (obj.initiativeTitle && obj.initiativeTitle.trim().length && obj.additionalDescription && obj.additionalDescription.trim().length && obj.data.length && checkEntity()) {
            if (local.id) {

                let newObj = { ...obj }
                newObj['modified_on'] = DateTime.utc()
                newObj['modified_by'] = login_data.id
                delete newObj.plannedImplementationDate
                delete newObj.currentStatus
                delete newObj.locationOfProject
                delete newObj.created_on
                delete newObj.created_by
                APIServices.patch(API.NewClientInitiative_Edit(local.id), newObj).then((res) => {
                    let index = local2.findIndex(i => i.id === local.id)
                    if (index !== -1) {
                        local2[index] = { ...obj }

                        setRecord(local2);
                    }
                    setObject({
                        plannedImplementationDate: null, locationOfProject: '', currentStatus: null,
                        initiativeTitle: '',
                        additionalDescription: '',
                        data: [{
                            country: null, city: null, location: null, DPANN0023: null, DPANN0024: null, DPANN0025: null, aspects: []
                        }],
                        remarks: '',
                        attachment: []
                    });

                    forceUpdate();
                    setDialog1(false);
                })
            } else {
                let newObj = { ...obj }
                newObj['created_on'] = DateTime.utc()
                newObj['created_by'] = login_data.id
                newObj['type'] = type
                delete newObj.plannedImplementationDate
                delete newObj.currentStatus
                delete newObj.locationOfProject
                APIServices.post(API.NewClientInitiative_UP(admin_data.id), newObj).then((res) => {
                    local2.push(res.data);
                    setRecord(local2);
                    setObject({
                        plannedImplementationDate: null, locationOfProject: '', currentStatus: null,
                        initiativeTitle: '',
                        additionalDescription: '',
                        data: [{
                            country: null, city: null, location: null, DPANN0023: null, DPANN0024: null, DPANN0025: null, aspects: []
                        }],
                        remarks: '',
                        attachment: []
                    });

                    forceUpdate();
                    setDialog1(false);
                })


            }






        }

    };
    const saveData2 = () => {
        // Logic to save data
        // onHide();

        setSubmitted(true);
        let local = obj;
        console.log(local)
        let local2 = record;
        if (obj.initiativeTitle && obj.initiativeTitle.trim().length && obj.additionalDescription && obj.additionalDescription.trim().length && obj.currentStatus !== null && obj.plannedImplementationDate !== null && obj.locationOfProject && obj.locationOfProject.trim().length && obj.data.length && checkAspects3()) {
            if (local.id) {

                let newObj = { ...obj }
                newObj['modified_on'] = DateTime.utc()
                newObj['modified_by'] = login_data.id

                delete newObj.created_on
                delete newObj.created_by
                APIServices.patch(API.NewClientInitiative_Edit(local.id), newObj).then((res) => {
                    let index = local2.findIndex(i => i.id === local.id)
                    if (index !== -1) {
                        local2[index] = { ...obj }
                        setRecord(local2);
                    }
                })
            } else {
                let newObj = { ...obj }
                newObj['created_on'] = DateTime.utc()
                newObj['created_by'] = login_data.id
                newObj['type'] = type
                APIServices.post(API.NewClientInitiative_UP(admin_data.id), newObj).then((res) => {
                    local2.push(res.data);
                    setRecord(local2);
                })


            }
            setObject({
                plannedImplementationDate: null, locationOfProject: '', currentStatus: null,
                initiativeTitle: '',
                additionalDescription: '',
                data: [{
                    country: null, city: null, location: null, DPANN0043: null, DPANN0044: null, DPANN0045: null, aspects: []
                }],
                remarks: '',
                attachment: []
            });
            setDialog2(false)

            forceUpdate();






        }

    };
    const updateAspects = (aspid) => {
        setSelectedAspects(aspid)
    }
    const checkEntity = () => {

        return obj.data.every(i => i.DPANN0023 !== null && i.DPANN0024 !== null && i.DPANN0025 !== null && i.aspects && i.aspects.length !== 0 && i.aspects.every(i => i.DPANN0028 !== null && parseFloat(i.DPANN0028) >= 0 && i.DPANN0027 !== null && i.DPANN0026 !== null))

    }
    const checkAspects = (index) => {
        return obj.data[index].aspects.every(i => i.DPANN0028 !== null && parseFloat(i.DPANN0028) >= 0 && i.DPANN0027 !== null)

    }
    const addNewEntity = () => {
        let loc = obj
        if (loc.data) {
            // let check = loc.data.every(i => i.DPANN0023 !== null && i.DPANN0024 !== null && i.DPANN0025 !== null)

            loc.data.push({
                country: null, city: null, location: null, DPANN0023: null, aspects: [], DPANN0024: null, DPANN0025: null
            })

        } else {
            loc.data = [{
                country: null, city: null, location: null, DPANN0023: null, aspects: [], DPANN0024: null, DPANN0025: null
            }]
        }

        setObject(loc)
        forceUpdate()
    }
    const addNewEntity2 = () => {
        let loc = obj
        if (loc.data) {
            // let check = loc.data.every(i => i.country !== null && i.location !== null &&  i.DPANN0043 !== null &&  i.DPANN0044 !== null && i.DPANN0045 !== null && i.aspects && i.aspects.length !== 0 && i.aspects.every(x => x.DPANN0049 !== null && parseFloat(x.DPANN0049) >= 0 && x.DPANN0048 !== null && i.DPANN0047 !== null))
            if (true) {
                loc.data.push({
                    country: null, city: null, location: null, DPANN0043: null, aspects: [], DPANN0044: null, DPANN0045: null
                })
            }
        } else {
            loc.data = [{
                country: null, city: null, location: null, DPANN0043: null, aspects: [], DPANN0044: null, DPANN0045: null
            }]
        }

        setObject(loc)
        forceUpdate()
    }
    const removeEntity = (index) => {
        let loc = obj
        loc.data.splice(index, 1)
        setObject(loc)
        forceUpdate()
    }
    const removeAspect = (index1, index2) => {
        let loc = obj
        loc.data[index1].aspects.splice(index2, 1)
        setObject(loc)
        forceUpdate()
    }
    const removeAspect2 = (index1) => {
        let loc = obj
        loc.data.splice(index1, 1)
        setObject(loc)
        forceUpdate()
    }
    const updateTargetValue = (obj, val, index) => {
        let loc = obj
        loc.DPANN0023[index][obj] = val
        setObject(loc)
        forceUpdate()
    }
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData();
                }}
            />
        </>
    );
    const handleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const getStatus = (rowData) => {
        let text = 'NA'
        let index = DPANN0027.find(i => i.value === rowData)
        if (index) {
            text = index.label
        }
        return text
    }
    const getCategory = (cid) => {
        let text = 'NA'
        let index = allcategorylist.find(i => i.id === cid)
        if (index) {
            text = index.name
        }
        return text
    }
    const getUnit = (cid) => {
        let text = 'NA'
        let index = allunitlist.find(i => i.id === cid)
        if (index) {
            text = index.name
        }
        return text
    }
    const implementationDateTemplate = (rowData) => {
        return <>{getDate(rowData.DPANN0022)} </>
    }
    const getCoverageText = (rowData) => {
        console.log(rowData)
        let text = 'Not Found'
        if (rowData.country === 0 && rowData.city === 0 && rowData.location === 0) {
            text = 'Corporate'
        } else if (rowData.country !== 0 && rowData.city === 0 && rowData.location === 0) {
            let country_index = rawsitelist.findIndex(i => i.id === rowData.country)
            console.log(country_index)
            if (country_index !== -1) {
                text = "All Data Centres Within " + rawsitelist[country_index].name
            }
        } else if (rowData.country !== 0 && rowData.city !== 0 && rowData.location === 0) {
            let city_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.city_id === rowData.city })
            if (city_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[city_index].city_name
            }
        } else {
            let site_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.site_id === rowData.location })
            if (site_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[site_index].site_name
            }
        }


        return text
    }
    const getLocationList = (val, type) => {
        let item = val
        let selected_item = { country: null, city: 0, location: 0 }
        let country_list = [{}]
        let city_list = [{ name: 'All Data Centres Within Country', id: 0 }]
        let location_list = [{ name: 'All Business Unit Within Country', id: 0 }]
        rawsitelist.forEach((country) => {
            country_list.push({ name: country.name, id: country.id })
            if (country.id === item.country || item.country === 0) {
                if (country.locationTwos) {
                    country.locationTwos.forEach((city) => {
                        city_list.push({ name: city.name, id: city.id })
                        if (city.id === item.city || item.city === 0) {
                            if (city.locationThrees) {
                                city.locationThrees.forEach((site) => {
                                    location_list.push({ name: site.name, id: site.id })

                                })
                            }
                        }
                    })

                }

            }

        })

        if (type === 'city') {
            return city_list
        } else {
            return location_list
        }
    }
    const editVal1 = (item) => {
        let items = JSON.parse(JSON.stringify(item))
        items.data = items.data.map(x => ({ ...x, DPANN0024: DateTime.fromISO(x.DPANN0024, { zone: 'utc' }).toJSDate() }))

        setObject(items)

        setDialog1(true);
        forceUpdate()
    }
    const editVal2 = (item) => {
        let items = JSON.parse(JSON.stringify(item))
        items.plannedImplementationDate = DateTime.fromISO(items.plannedImplementationDate, { zone: 'utc' }).toJSDate()


        setObject(items)

        setDialog2(true);
        forceUpdate()
    }
    const editVal3 = (item) => {
        let items = JSON.parse(JSON.stringify(item))
        items.data = items.data.map(x => ({ ...x, DPANN0044: DateTime.fromISO(x.DPANN0044, { zone: 'utc' }).toJSDate() }))


        setObject(items)

        setDialog3(true);
        forceUpdate()
    }
    const titleTemplate = (rowData) => {
        return (
            <></>
            // <div className='clr-navy cur-pointer text-underline fw-5 fs-14' onClick={() => { editVal(rowData) }}> {rowData.DPANN0021} </div>
        )
    }
    const deleteRow = (item) => {
        let loc = record;
        Swal.fire({
            title: `Are you sure want to delete ${item.initiativeTitle} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                APIServices.delete(API.NewClientInitiative_Edit(item.id)).then((res) => {
                    let index = loc.findIndex((k) => {
                        return k.id === item.id;
                    });
                    loc.splice(index, 1);
                    setRecord(loc);
                    forceUpdate();
                })
            }
        })

    };
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">

                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded hover-red mt-2"
                        onClick={() => {
                            deleteRow(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    const checkEntity2 = () => {

        return obj.data.every(i => i.country !== null && i.location !== null && i.DPANN0043 !== null && i.DPANN0044 !== null && i.DPANN0045 !== null && i.aspects && i.aspects.length !== 0 && i.aspects.every(x => x.DPANN0049 !== null && parseFloat(x.DPANN0049) >= 0 && x.DPANN0048 !== null && i.DPANN0047 !== null && i.DPANN0046 !== null))

    }
    const checkAspects2 = (index) => {
        return obj.data[index].aspects.every(i => i.DPANN0049 !== null && parseFloat(i.DPANN0049) >= 0 && i.DPANN0048 !== null && i.DPANN0047 !== null)

    }
    const checkAspects3 = (index) => {
        return obj.data.every(i => i.DPANN0036 !== null && i.DPANN0037 !== null && parseFloat(i.DPANN0037) >= 0)

    }
    const addNewAspects2 = (index) => {
        let loc = obj

        if (loc.data[index].aspects) {
            // let check = loc.data[index].aspects.every(i => i.DPANN0049 !== null && parseFloat(i.DPANN0049) >= 0 && i.DPANN0048 !== null && i.DPANN0047 !== null)
            if (true) {

                loc.data[index].aspects = [...loc.data[index].aspects, {
                    DPANN0046: null,
                    DPANN0049: 0,
                    DPANN0048: null,
                    DPANN0047: null
                }];
                console.log(loc.data[index].aspects)
            }

        } else {
            loc.data[index].aspects = [{
                DPANN0046: null,
                DPANN0049: 0,
                DPANN0048: null,
                DPANN0047: null
            }]
        }

        console.log(loc)
        setObject(loc)
        forceUpdate()
    }
    const saveData3 = () => {
        // Logic to save data
        // onHide();

        setSubmitted(true);
        let local = obj;
        console.log(local)
        let local2 = record;
        if (obj.initiativeTitle && obj.initiativeTitle.trim().length && obj.additionalDescription && obj.additionalDescription.trim().length && obj.data.length && checkEntity2()) {
            if (local.id) {

                let newObj = { ...obj }
                newObj['modified_on'] = DateTime.utc()
                newObj['modified_by'] = login_data.id
                delete newObj.plannedImplementationDate
                delete newObj.currentStatus
                delete newObj.locationOfProject
                delete newObj.created_on
                delete newObj.created_by
                APIServices.patch(API.NewClientInitiative_Edit(local.id), newObj).then((res) => {
                    let index = local2.findIndex(i => i.id === local.id)
                    if (index !== -1) {
                        local2[index] = { ...obj }
                        setRecord(local2);
                    }
                })
            } else {
                let newObj = { ...obj }
                newObj['created_on'] = DateTime.utc()
                newObj['created_by'] = login_data.id
                newObj['type'] = type
                delete newObj.plannedImplementationDate
                delete newObj.currentStatus
                delete newObj.locationOfProject
                APIServices.post(API.NewClientInitiative_UP(admin_data.id), newObj).then((res) => {
                    local2.push(res.data);
                    setRecord(local2);
                })


            }
            setObject({
                plannedImplementationDate: null, locationOfProject: '', currentStatus: null,
                initiativeTitle: '',
                additionalDescription: '',
                data: [{
                    country: null, city: null, location: null, DPANN0043: null, DPANN0044: null, DPANN0045: null, aspects: []
                }],
                remarks: '',
                attachment: []
            });

            forceUpdate();
            setDialog3(false);


        }

    };
    const getBoundary = (cid) => {
        let text = 'NA'
        let index = boundarylist.find(i => i.id === cid)
        if (index) {
            text = index.name
        }
        return text
    }
    const DialogFooter2 = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData2();
                }}
            />
        </>
    );
    const DialogFooter3 = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveData3();
                }}
            />
        </>
    );
    return (
        <div className='font-lato'>

            {allRoles.includes(18) ? (
                <div>
                    <div
                        className="col-12 "
                        style={{
                            justifyContent: "center",
                        }}
                    >
                        <label className="text-big-one clr-navy flex fs-18 fw-6 flex justify-content-start">

                            Initiative
                        </label>
                    </div>
                    <TabView activeIndex={activetab} onTabChange={(e) => { setActiveTab(e.index) }} >
                        {visible.one.includes(login_data.id) && <TabPanel header={"Environment " + (record.filter(i => i.type === 1).length ? '(' + record.filter(i => i.type === 1).length + ')' : '')}>
                            <div className={'flex justify-content-end'}>
                                <Button label='Add New' onClick={() => { openNewDilaog(1) }} />

                            </div>

                            <div className='custom-table-one-container' style={{ marginTop: 10, height: 'calc(100vh - 340px)' }}>
                                <table className="custom-table-one">
                                    <thead>
                                        <tr>
                                            <th>S No</th>
                                            <th>Initiative Name</th>
                                            <th>Location</th>
                                            <th>Implementation Date</th>
                                            <th>Current Status</th>
                                            <th>Environmental Aspect</th>
                                            <th>Unit</th>
                                            <th>Estimated Annual Reduction</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {record.length ?
                                            <>
                                                {
                                                    record.filter(i => i.type === 1).map((initiative, index) => {
                                                        let entityRowCount = initiative.data.reduce(
                                                            (sum, entity) => sum + (entity.aspects.length || 1),
                                                            0
                                                        );

                                                        return (
                                                            <React.Fragment key={index}>
                                                                <tr>
                                                                    <td rowSpan={entityRowCount}>{index + 1}</td>
                                                                    <td rowSpan={entityRowCount} class='cur-pointer text-underline' onClick={() => { editVal1(initiative) }}>{initiative.initiativeTitle}</td>
                                                                    <td rowSpan={initiative.data[0].aspects.length || 1}>
                                                                        {getCoverageText(initiative.data[0])}
                                                                    </td>
                                                                    <td rowSpan={initiative.data[0].aspects.length || 1}>
                                                                        {getDate(initiative.data[0].DPANN0024) || "N/A"}
                                                                    </td>
                                                                    <td rowSpan={initiative.data[0].aspects.length || 1}>
                                                                        {getStatus(initiative.data[0].DPANN0025) || "N/A"}
                                                                    </td>
                                                                    <td>{getCategory(initiative.data[0].aspects[0]?.DPANN0026)}</td>
                                                                    <td>{getUnit(initiative.data[0].aspects[0]?.DPANN0027)}</td>
                                                                    <td>{initiative.data[0].aspects[0]?.DPANN0028}</td>
                                                                    <td rowSpan={entityRowCount}> {initiative.created_by === login_data.id && <i onClick={() => { deleteRow(initiative) }} style={{ color: 'red' }} className='pi pi-trash' />} </td>
                                                                </tr>

                                                                {initiative.data.map((entity, entityIndex) => (
                                                                    <React.Fragment key={entityIndex}>
                                                                        {entityIndex > 0 && (
                                                                            <tr>
                                                                                <td rowSpan={entity.aspects.length || 1}>
                                                                                    {getCoverageText(entity)}
                                                                                </td>
                                                                                <td rowSpan={entity.aspects.length || 1}>
                                                                                    {getDate(entity.DPANN0024) || "N/A"}
                                                                                </td>
                                                                                <td rowSpan={entity.aspects.length || 1}>
                                                                                    {getStatus(entity.DPANN0025) || "N/A"}
                                                                                </td>
                                                                                <td>{getCategory(entity.aspects[0]?.DPANN0026)}</td>
                                                                                <td>{getUnit(entity.aspects[0]?.DPANN0027)}</td>
                                                                                <td>{entity.aspects[0]?.DPANN0028}</td>
                                                                            </tr>
                                                                        )}
                                                                        {entity.aspects.slice(1).map((aspect, aspectIndex) => (
                                                                            <tr key={aspectIndex}>
                                                                                <td>{getCategory(aspect.DPANN0026)}</td>
                                                                                <td>{getUnit(aspect.DPANN0027)}</td>
                                                                                <td>{aspect.DPANN0028}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </React.Fragment>
                                                                ))}
                                                            </React.Fragment>
                                                        );
                                                    })}
                                            </> :
                                            <tr colSpan={'9'}> No Initiative Found </tr>
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </TabPanel>}
                        {visible.two.includes(login_data.id) && <TabPanel header={"Social " + (record.filter(i => i.type === 2).length ? '(' + record.filter(i => i.type === 2).length + ')' : '')}>
                            <div className={'flex justify-content-end'}>
                                <Button label='Add New' onClick={() => { openNewDilaog(2) }} />

                            </div>

                            <div className='custom-table-one-container' style={{ marginTop: 10, height: 'calc(100vh - 340px)' }}>
                                <table className="custom-table-one">
                                    <thead>
                                        <tr>
                                            <th>S No</th>
                                            <th>Initiative Name</th>
                                            <th>Location of Project</th>

                                            <th>Planned Implementation Date</th>
                                            <th>Current Status</th>
                                            <th>Social Impact Measurement</th>

                                            <th>Measureable Social Impact</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {record.length ?
                                            <>
                                                {
                                                    record.filter(i => i.type === 2).map((entity, index) => {
                                                        let entityRowCount = 1

                                                        return (
                                                            <React.Fragment key={index}>



                                                                <React.Fragment key={index}>
                                                                    {index >= 0 && (
                                                                        <tr>
                                                                            <td rowSpan={entity.data.length || 1}>
                                                                                {index + 1}
                                                                            </td>
                                                                            <td class='cur-pointer text-underline' onClick={() => { editVal2(entity) }} rowSpan={entity.data.length || 1}>
                                                                                {entity.initiativeTitle}
                                                                            </td>
                                                                            <td rowSpan={entity.data.length || 1}>
                                                                                {entity.locationOfProject}
                                                                            </td>

                                                                            <td rowSpan={entity.data.length || 1}>
                                                                                {getDate(entity.plannedImplementationDate) || "N/A"}
                                                                            </td>
                                                                            <td rowSpan={entity.data.length || 1}>
                                                                                {getStatus(entity.currentStatus) || "N/A"}
                                                                            </td>
                                                                            <td>{getCategory(entity.data[0]?.DPANN0036)}</td>
                                                                            <td>{entity.data[0]?.DPANN0037}</td>
                                                                            <td rowSpan={entity.data.length}> {entity.created_by === login_data.id && <i onClick={() => { deleteRow(entity) }} style={{ color: 'red' }} className='pi pi-trash' />} </td>

                                                                        </tr>
                                                                    )}
                                                                    {entity.data.slice(1).map((aspect, aspectIndex) => (
                                                                        <tr key={aspectIndex}>
                                                                            <td>{getCategory(aspect.DPANN0036)}</td>

                                                                            <td>{aspect.DPANN0037}</td>
                                                                        </tr>
                                                                    ))}
                                                                </React.Fragment>

                                                            </React.Fragment>
                                                        )

                                                    })}
                                            </> :
                                            <tr colSpan={'9'}> No Initiative Found </tr>
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </TabPanel>}
                        {visible.three.includes(login_data.id) && <TabPanel header={"R & D " + (record.filter(i => i.type === 3).length ? '(' + record.filter(i => i.type === 3).length + ')' : '')}>
                            <div className={'flex justify-content-end'}>
                                <Button label='Add New' onClick={() => { openNewDilaog(3) }} />

                            </div>
                            <div className='custom-table-one-container' style={{ marginTop: 10, height: 'calc(100vh - 340px)' }}>
                                <table className="custom-table-one">
                                    <thead>
                                        <tr>
                                            <th>S No</th>
                                            <th>Initiative Name</th>
                                            <th>Country to be implemented</th>
                                            <th>Facility</th>
                                            <th>Planned Implementation Date</th>
                                            <th>Current Status</th>
                                            <th>ESG Aspect</th>
                                            <th>Unit</th>
                                            <th>Boundary of Reduction Estimates</th>
                                            <th>Potential Annual Reduction</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {record.length ?
                                            <>
                                                {
                                                    record.filter(i => i.type === 3).map((initiative, index) => {
                                                        let entityRowCount = initiative.data.reduce(
                                                            (sum, entity) => sum + (entity.aspects.length || 1),
                                                            0
                                                        );

                                                        return (
                                                            <React.Fragment key={index}>
                                                                <tr>
                                                                    <td rowSpan={entityRowCount}>{index + 1}</td>
                                                                    <td rowSpan={entityRowCount} class='cur-pointer text-underline' onClick={() => { editVal3(initiative) }}>{initiative.initiativeTitle}</td>
                                                                    <td rowSpan={initiative.data[0].aspects.length || 1}>
                                                                        {getCoverageText({ ...initiative.data[0], city: 0, location: 0 })}
                                                                    </td>
                                                                    <td rowSpan={initiative.data[0].aspects.length || 1}>
                                                                        {getCoverageText(initiative.data[0])}
                                                                    </td>
                                                                    <td rowSpan={initiative.data[0].aspects.length || 1}>
                                                                        {getDate(initiative.data[0].DPANN0044) || "N/A"}
                                                                    </td>
                                                                    <td rowSpan={initiative.data[0].aspects.length || 1}>
                                                                        {getStatus(initiative.data[0].DPANN0045) || "N/A"}
                                                                    </td>
                                                                    <td>{getCategory(initiative.data[0].aspects[0]?.DPANN0047)}</td>
                                                                    <td>{getUnit(initiative.data[0].aspects[0]?.DPANN0047)}</td>
                                                                    <td>{getBoundary(initiative.data[0].aspects[0]?.DPANN0048)}</td>

                                                                    <td>{initiative.data[0].aspects[0]?.DPANN0049}</td>
                                                                    <td rowSpan={entityRowCount}> {initiative.created_by === login_data.id && <i onClick={() => { deleteRow(initiative) }} style={{ color: 'red' }} className='pi pi-trash' />} </td>
                                                                </tr>

                                                                {initiative.data.map((entity, entityIndex) => (
                                                                    <React.Fragment key={entityIndex}>
                                                                        {entityIndex > 0 && (
                                                                            <tr>

                                                                                <td rowSpan={entity.aspects.length || 1}>
                                                                                    {getCoverageText({ ...entity, city: 0, location: 0 })}
                                                                                </td>
                                                                                <td rowSpan={entity.aspects.length || 1}>
                                                                                    {getCoverageText(entity)}
                                                                                </td>
                                                                                <td rowSpan={entity.aspects.length || 1}>
                                                                                    {getDate(entity.DPANN0044) || "N/A"}
                                                                                </td>
                                                                                <td rowSpan={entity.aspects.length || 1}>
                                                                                    {getStatus(entity.DPANN0045) || "N/A"}
                                                                                </td>
                                                                                <td>{getCategory(entity.aspects[0]?.DPANN0046)}</td>
                                                                                <td>{getUnit(entity.aspects[0]?.DPANN0047)}</td>
                                                                                <td>{getBoundary(entity.aspects[0]?.DPANN0048)}</td>


                                                                                <td>{entity.aspects[0]?.DPANN0049}</td>
                                                                            </tr>
                                                                        )}
                                                                        {entity.aspects.slice(1).map((aspect, aspectIndex) => (
                                                                            <tr key={aspectIndex}>
                                                                                <td>{getCategory(aspect.DPANN0046)}</td>
                                                                                <td>{getUnit(aspect.DPANN0047)}</td>
                                                                                <td>{getBoundary(aspect.DPANN0048)}</td>
                                                                                <td>{aspect.DPANN0049}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </React.Fragment>
                                                                ))}
                                                            </React.Fragment>
                                                        );
                                                    })}
                                            </> :
                                            <tr colSpan={'9'}> No Initiative Found </tr>
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </TabPanel>}
                    </TabView>

                    <Dialog contentClassName='fw-5 font-lato' header={obj.id ? "Edit " + obj.initiativeTitle : "Add Environment Initiative"} visible={dialog1} style={{ width: '70vw' }} footer={DialogFooter} onHide={() => { setDialog1(false) }} modal>

                        <div className="p-fluid grid m-0 p-3">

                            <div className="p-field col-12  ">
                                <label htmlFor="title" >Initiative Title  <span className="mandatory"> *</span>    </label>
                                <InputText
                                    id="title"
                                    className='mt-2'
                                    value={obj.initiativeTitle}
                                    onChange={(e) => { updateObject("initiativeTitle", e.target.value) }}
                                    placeholder='Name'
                                />

                                {submitted && obj.initiativeTitle.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >

                                        Enter Initiative Title
                                    </small>
                                )}
                            </div>
                            <div className="p-field col-12  ">
                                <label htmlFor="title" >Additional Description of Initiative <span className="mandatory"> *</span> </label>

                                <InputTextarea value={obj.additionalDescription} className='mt-2' onChange={(e) => { updateObject("additionalDescription", e.target.value) }} style={{ width: '100%', height: 100, overflow: 'auto' }} />
                                {submitted && obj.additionalDescription.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >

                                        Enter Additional Description of Initiative
                                    </small>
                                )}
                            </div>
                            <div className='flex justify-content-between col-12 '>
                                <label htmlFor="baselineData" className='flex'>Entity<span className="mandatory"> *</span>     </label>
                                <i onClick={() => { addNewEntity() }} className='pi pi-plus rounded cur-pointer clr-navy fw-5 fs-14 text-underline' > New Entity </i> </div>
                            {obj.data.map((ent, index) => {
                                return (
                                    <div className="p-field col-12 " style={{ position: 'relative' }}>
                                        <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                            <i className='pi pi-trash rounded-icon' onClick={() => { removeEntity(index) }} />
                                        </div>
                                        <fieldset style={{
                                            padding: '20px',
                                            borderRadius: '10px',
                                            border: '1px solid #ced4da',

                                        }}>
                                            <legend>Entity - {index + 1}  <span className="mandatory"> *</span> </legend>
                                            <div>
                                                <div className='grid m-0 '>
                                                    <div className='col-4'>
                                                        <label>{tier_label[0]}<span className="mandatory"> *</span></label>
                                                        <Dropdown className='m-2' style={{ width: '100%' }} value={ent.country} options={countryList} optionLabel="name" optionValue="id"
                                                            onChange={(e) => { obj.data[index] = { ...ent, DPANN0023: 0, country: e.value, city: 0, location: 0 }; forceUpdate() }} placeholder={"Select " + tier_label[0]} />

                                                    </div>

                                                    {ent.country !== null && <div className='col-4'>
                                                        <label>{tier_label[2]}<span className="mandatory"> *</span></label>
                                                        <Dropdown className='m-2' style={{ width: '100%' }} value={ent.location} options={getLocationList({ country: ent.country, city: 0, location: 0 })} optionLabel="name" optionValue="id"
                                                            onChange={(e) => { obj.data[index] = { ...ent, DPANN0023: e.value, location: e.value }; forceUpdate() }} placeholder={"Select " + tier_label[2]}
                                                        />
                                                    </div>}
                                                </div>
                                                <div className='col-12'>
                                                    <div className='col-12 grid m-0 '>
                                                        <div className="p-field col-6 ps-0 pb-4">
                                                            <label htmlFor="startDate" className='flex'> Planned Implementation Date <span className="mandatory"> *</span>    </label>
                                                            <Calendar className='mt-2' id="implementationDate" value={ent.DPANN0024} onChange={(e) => { obj.data[index] = { ...ent, DPANN0024: e.value }; forceUpdate(); }} showIcon dateFormat='dd-MM-yy' />

                                                            {submitted && ent.DPANN0024 === null && (
                                                                <small
                                                                    className="p-invalid"
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                >

                                                                    Select Implementation Date
                                                                </small>
                                                            )}
                                                        </div>

                                                        <div className="p-field col-6 ps-0 pb-4">
                                                            <label htmlFor="status">Current Status <span className="mandatory"> *</span>  </label>
                                                            <Dropdown id="status" className='mt-2' value={ent.DPANN0025} optionValue='value' options={DPANN0027} onChange={(e) => { obj.data[index] = { ...ent, DPANN0025: e.value }; forceUpdate(); }} placeholder="Select a Current Status" />

                                                            {submitted && ent.DPANN0025 === null && (
                                                                <small
                                                                    className="p-invalid"
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                >

                                                                    Select Current Status
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {ent.country !== null && <div className='p-field col-12 ps-0 pb-4 flex justify-content-end '>


                                                        <label className='text-underline cur-pointer fw-6 fs-14' onClick={() => { addNewAspects(index) }}>Add Aspects </label>



                                                    </div>}

                                                    {ent.aspects && ent.aspects.map((asp, index2) => {


                                                        return (
                                                            <div >
                                                                <div className='col-12 grid m-0 ' style={{ background: 'lightgray' }} ><div className="text-three-dot col-11"> Aspect #{(index2 + 1)}  </div> <div className='col-1'> <i className='pi pi-trash' style={{ color: 'red' }} onClick={() => { removeAspect(index, index2) }} /> </div></div>
                                                                <div className="p-field col-6 ps-0 pb-4">
                                                                    <Tooltip className="form-tt-1" target={".envinit1"} mouseTrack mouseTrackLeft={10} />
                                                                    <label htmlFor="startDate" className='flex'> Select Aspects <span className="mandatory"> *</span>
                                                                        <span><i style={{
                                                                            marginTop: 2,
                                                                            marginLeft: 3
                                                                        }} className={`material-icons fs-14 envinit1`} data-pr-tooltip={`Add at least one aspect`}
                                                                            data-pr-position="right"
                                                                            data-pr-at="right+5 top"
                                                                            data-pr-my="left center-2"> help</i></span>    </label>
                                                                    <Dropdown className='mt-2' id="aspects" onChange={(e) => { obj.data[index].aspects[index2].DPANN0026 = e.value; obj.data[index].aspects[index2].DPANN0027 = e.value; forceUpdate() }} value={asp.DPANN0026} options={categorylist1} optionValue="id" optionLabel='name' />
                                                                    {submitted && asp.DPANN0026 === null && (
                                                                        <small
                                                                            className="p-invalid"
                                                                            style={{
                                                                                color: "red",
                                                                            }}
                                                                        >

                                                                            Select Aspect
                                                                        </small>
                                                                    )}

                                                                </div>
                                                                <div className='col-12 grid m-0 '>
                                                                    <div className="p-field col-6 pe-0 pb-4">
                                                                        <label htmlFor="targetReduction" className='flex'> Unit <span className="mandatory"> *</span>    </label>
                                                                        <Dropdown className='mt-2 hide-dd-arrow' id="aspects" disabled onChange={(e) => { obj.data[index].aspects[index2].DPANN0027 = e.value; forceUpdate() }} value={asp.DPANN0027} options={unitlist1} optionValue="id" optionLabel='name' />


                                                                        {submitted && asp.DPANN0027 === null && (
                                                                            <small
                                                                                className="p-invalid"
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            >

                                                                                Enter Measurement Unit
                                                                            </small>
                                                                        )}

                                                                    </div>
                                                                    <div className="p-field  col-6 ">
                                                                        <Tooltip className="form-tt-1" target={".envinit2"} mouseTrack mouseTrackLeft={10} />
                                                                        <label htmlFor="baselineData" className='flex'>Estimated Annual Reduction<span className="mandatory"> *</span>
                                                                            <span><i style={{
                                                                                marginTop: 2,
                                                                                marginLeft: 3
                                                                            }} className={`material-icons fs-14 envinit2`} data-pr-tooltip={`Please input as zero (0) if estimates are not available.
If available, please include a short description in the remarks of the calculation basis to estimate the reductions. E.g.- Engineering calculations based on generator load profile.`}
                                                                                data-pr-position="right"
                                                                                data-pr-at="right+5 top"
                                                                                data-pr-my="left center-2"> help</i></span>

                                                                        </label>
                                                                        <InputNumber className='mt-2' min={0} id="baselineData" value={asp.DPANN0028} onChange={(e) => { obj.data[index].aspects[index2].DPANN0028 = e.value; forceUpdate() }} />

                                                                        {submitted && parseFloat(asp.DPANN0028) < 0 && (
                                                                            <small
                                                                                className="p-invalid"
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            >

                                                                                Enter  Estimated Annual Reduction
                                                                            </small>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })

                                                    }
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                )
                            })}







                            <div className="p-field col-12 ">
                                <label htmlFor="description" className='flex'>Remarks  </label>
                                <InputText className='mt-2' id="description" value={obj.remarks} onChange={(e) => setObject({ ...obj, remarks: e.target.value })} />


                            </div>

                            <div className="p-field  col-12 ">
                                <label htmlFor="attachment" className='flex' >Attachment        </label>
                                {obj.attachment && obj.attachment.length ?
                                    obj.attachment.map((i) => {
                                        return attachmentTemplate(i)
                                    })
                                    : <div {...getRootProps()} className='mt-2' style={dropzoneStyle}>
                                        <input {...getInputProps()} />
                                        <i className='pi pi-cloud-upload'></i>
                                        <p>Drag and drop or click to upload</p>
                                    </div>}
                            </div>



                        </div>
                    </Dialog>
                    <Dialog contentClassName='fw-5 font-lato' header={obj.id ? "Edit " + obj.initiativeTitle : "Add R&D Initiative"} visible={dialog3} style={{ width: '70vw' }} footer={DialogFooter3} onHide={() => { setDialog3(false) }} modal>

                        <div className="p-fluid grid m-0 p-3">

                            <div className="p-field col-12  ">
                                <label htmlFor="title" >Initiative Title  <span className="mandatory"> *</span>    </label>
                                <InputText
                                    id="title"
                                    className='mt-2'
                                    value={obj.initiativeTitle}
                                    onChange={(e) => { updateObject("initiativeTitle", e.target.value) }}
                                    placeholder='Name'
                                />

                                {submitted && obj.initiativeTitle.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >

                                        Enter Initiative Title
                                    </small>
                                )}
                            </div>
                            <div className="p-field col-12  ">
                                <label htmlFor="title" >Additional Description of Initiative <span className="mandatory"> *</span> </label>

                                <InputTextarea value={obj.additionalDescription} className='mt-2' onChange={(e) => { updateObject("additionalDescription", e.target.value) }} style={{ width: '100%', height: 100, overflow: 'auto' }} />
                                {submitted && obj.additionalDescription.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >

                                        Enter Additional Description of Initiative
                                    </small>
                                )}
                            </div>

                            <div className='flex justify-content-between col-12 '>
                                <label htmlFor="baselineData" className='flex'>Country/Facility to be implemented<span className="mandatory"> *</span>     </label>
                                <i onClick={() => { addNewEntity2() }} className='pi pi-plus rounded cur-pointer clr-navy fw-5 fs-14 text-underline' > New Facility  </i> </div>
                            {obj.data.map((ent, index) => {
                                return (
                                    <div className="p-field col-12 " style={{ position: 'relative' }}>
                                        <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                            <i className='pi pi-trash rounded-icon' onClick={() => { removeEntity(index) }} />
                                        </div>
                                        <fieldset style={{
                                            padding: '20px',
                                            borderRadius: '10px',
                                            border: '1px solid #ced4da',

                                        }}>
                                            <legend>Entity - {index + 1} <span className="mandatory"> *</span></legend>
                                            <div>
                                                <div className='grid m-0 '>
                                                    <div className='col-4'>
                                                        <label>{tier_label[0]}<span className="mandatory"> *</span></label>
                                                        <Dropdown className='m-2' style={{ width: '100%' }} value={ent.country} options={countryList} optionLabel="name" optionValue="id"
                                                            onChange={(e) => { obj.data[index] = { ...ent, country: e.value, city: 0, location: null }; forceUpdate() }} placeholder={"Select " + tier_label[0]} />

                                                    </div>

                                                    {ent.country !== null && <div className='col-4'>
                                                        <label>{tier_label[2]}<span className="mandatory"> *</span></label>
                                                        <Dropdown className='m-2' style={{ width: '100%' }} value={ent.location} options={getLocationList({ country: ent.country, city: 0, location: 0 })} optionLabel="name" optionValue="id"
                                                            onChange={(e) => { obj.data[index] = { ...ent, DPANN0043: e.value, location: e.value }; forceUpdate() }} placeholder={"Select " + tier_label[2]}
                                                        />
                                                    </div>}
                                                </div>
                                                <div className='col-12'>
                                                    <div className='col-12 grid m-0 '>
                                                        <div className="p-field col-6 ps-0 pb-4">
                                                            <label htmlFor="startDate" className='flex'> Planned Implementation Date <span className="mandatory"> *</span>    </label>
                                                            <Calendar className='mt-2' id="implementationDate" value={ent.DPANN0044} onChange={(e) => { obj.data[index] = { ...ent, DPANN0044: e.value }; forceUpdate(); }} showIcon dateFormat='dd-MM-yy' />

                                                            {submitted && ent.DPANN0044 === null && (
                                                                <small
                                                                    className="p-invalid"
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                >

                                                                    Select Implementation Date
                                                                </small>
                                                            )}
                                                        </div>

                                                        <div className="p-field col-6 ps-0 pb-4">
                                                            <label htmlFor="status">Current Status <span className="mandatory"> *</span>  </label>
                                                            <Dropdown id="status" className='mt-2' value={ent.DPANN0045} optionValue='value' options={DPANN0027} onChange={(e) => { obj.data[index] = { ...ent, DPANN0045: e.value }; forceUpdate(); }} placeholder="Select a Current Status" />

                                                            {submitted && ent.DPANN0045 === null && (
                                                                <small
                                                                    className="p-invalid"
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                >

                                                                    Select Current Status
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {ent.country !== null && <div className='p-field col-12 ps-0 pb-4 flex justify-content-end '>


                                                        <label className='text-underline cur-pointer fw-6 fs-14' onClick={() => { addNewAspects2(index) }}>Add Aspects  </label>



                                                    </div>}

                                                    {ent.aspects && ent.aspects.map((asp, index2) => {


                                                        return (
                                                            <div >
                                                                <div className='col-12 grid m-0 ' style={{ background: 'lightgray' }} ><div className="text-three-dot col-11"> Aspect #{(index2 + 1)}  </div> <div className='col-1'> <i className='pi pi-trash' style={{ color: 'red' }} onClick={() => { removeAspect(index, index2) }} /> </div></div>
                                                                <div className="p-field col-6 ps-0 pb-4">
                                                                    <Tooltip className="form-tt-1" target={".envinit1"} mouseTrack mouseTrackLeft={10} />
                                                                    <label htmlFor="startDate" className='flex'> Select Aspects <span className="mandatory"> *</span>
                                                                        <span><i style={{
                                                                            marginTop: 2,
                                                                            marginLeft: 3
                                                                        }} className={`material-icons fs-14 envinit1`} data-pr-tooltip={`Add at least one aspect`}
                                                                            data-pr-position="right"
                                                                            data-pr-at="right+5 top"
                                                                            data-pr-my="left center-2"> help</i></span>    </label>
                                                                    <Dropdown className='mt-2' id="aspects" onChange={(e) => { obj.data[index].aspects[index2].DPANN0046 = e.value; obj.data[index].aspects[index2].DPANN0047 = e.value; forceUpdate() }} value={asp.DPANN0046} options={categorylist3} optionValue="id" optionLabel='name' />

                                                                    {submitted && asp.DPANN0046 === null && (
                                                                        <small
                                                                            className="p-invalid"
                                                                            style={{
                                                                                color: "red",
                                                                            }}
                                                                        >

                                                                            Select Aspect
                                                                        </small>
                                                                    )}
                                                                </div>
                                                                <div className='col-12 grid m-0 '>
                                                                    <div className="p-field col-6 pe-0 pb-4">
                                                                        <label htmlFor="targetReduction" className='flex'> Unit <span className="mandatory"> *</span>    </label>
                                                                        <Dropdown className='mt-2 hide-dd-arrow' id="aspects" disabled onChange={(e) => { obj.data[index].aspects[index2].DPANN0047 = e.value; forceUpdate() }} value={asp.DPANN0047} options={unitlist3} optionValue="id" optionLabel='name' />


                                                                        {submitted && asp.DPANN0047 === null && (
                                                                            <small
                                                                                className="p-invalid"
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            >

                                                                                Enter  Unit
                                                                            </small>
                                                                        )}

                                                                    </div>
                                                                    <div className="p-field col-6 pe-0 pb-4">
                                                                        <label htmlFor="targetReduction" className='flex'> Boundary of Reduction Estimates <span className="mandatory"> *</span>    </label>
                                                                        <Dropdown className='mt-2 ' id="aspects" onChange={(e) => { obj.data[index].aspects[index2].DPANN0048 = e.value; forceUpdate() }} value={asp.DPANN0048} options={boundarylist} optionValue="id" optionLabel='name' />


                                                                        {submitted && asp.DPANN0048 === null && (
                                                                            <small
                                                                                className="p-invalid"
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            >

                                                                                Select Boundary of Reduction Estimates
                                                                            </small>
                                                                        )}

                                                                    </div>
                                                                    <div className="p-field  col-6 ">
                                                                        <Tooltip className="form-tt-1" target={".envinit2"} mouseTrack mouseTrackLeft={10} />
                                                                        <label htmlFor="baselineData" className='flex'>Potential Annual Reduction<span className="mandatory"> *</span>
                                                                            <span><i style={{
                                                                                marginTop: 2,
                                                                                marginLeft: 3
                                                                            }} className={`material-icons fs-14 envinit2`} data-pr-tooltip={`Please input as zero (0) if estimates are not available.
If available, please include a short description in the remarks of the calculation basis to estimate the reductions. E.g.- Engineering calculations based on generator load profile.`}
                                                                                data-pr-position="right"
                                                                                data-pr-at="right+5 top"
                                                                                data-pr-my="left center-2"> help</i></span>

                                                                        </label>
                                                                        <InputNumber className='mt-2' min={0} id="baselineData" value={asp.DPANN0049} onChange={(e) => { obj.data[index].aspects[index2].DPANN0049 = e.value; forceUpdate() }} />

                                                                        {submitted && parseFloat(asp.DPANN0049) < 0 && (
                                                                            <small
                                                                                className="p-invalid"
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            >

                                                                                Enter Potential Annual Reduction
                                                                            </small>
                                                                        )}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        )
                                                    })

                                                    }
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                )
                            })}







                            <div className="p-field col-12 ">
                                <label htmlFor="description" className='flex'>Remarks  </label>
                                <InputText className='mt-2' id="description" value={obj.remarks} onChange={(e) => setObject({ ...obj, remarks: e.target.value })} />


                            </div>

                            <div className="p-field  col-12 ">
                                <label htmlFor="attachment" className='flex' >Attachment        </label>
                                {obj.attachment && obj.attachment.length ?
                                    obj.attachment.map((i) => {
                                        return attachmentTemplate(i)
                                    })
                                    : <div {...getRootProps()} className='mt-2' style={dropzoneStyle}>
                                        <input {...getInputProps()} />
                                        <i className='pi pi-cloud-upload'></i>
                                        <p>Drag and drop or click to upload</p>
                                    </div>}
                            </div>



                        </div>
                    </Dialog>
                    <Dialog contentClassName='fw-5 font-lato' header={obj.id ? "Edit " + obj.initiativeTitle : "Add Social Initiative"} visible={dialog2} style={{ width: '70vw' }} footer={DialogFooter2} onHide={() => { setDialog2(false) }} modal>

                        <div className="p-fluid grid m-0 p-3">

                            <div className="p-field col-12  ">
                                <label htmlFor="title" >Initiative Title  <span className="mandatory"> *</span>    </label>
                                <InputText
                                    id="title"
                                    className='mt-2'
                                    value={obj.initiativeTitle}
                                    onChange={(e) => { updateObject("initiativeTitle", e.target.value) }}
                                    placeholder='Name'
                                />

                                {submitted && obj.initiativeTitle.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >

                                        Enter Initiative Title
                                    </small>
                                )}
                            </div>
                            <div className="p-field col-12  ">
                                <label htmlFor="title" >Additional Description of Initiative <span className="mandatory"> *</span> </label>

                                <InputTextarea value={obj.additionalDescription} className='mt-2' onChange={(e) => { updateObject("additionalDescription", e.target.value) }} style={{ width: '100%', height: 100, overflow: 'auto' }} />
                                {submitted && obj.additionalDescription.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >

                                        Enter Additional Description of Initiative
                                    </small>
                                )}
                            </div>
                            <div className="p-field col-12  ">
                                <Tooltip className="form-tt-1" target={".socinit1"} mouseTrack mouseTrackLeft={10} />
                                <label htmlFor="title" className='flex'>Location of Project <span className="mandatory"> *</span>
                                    <span><i style={{
                                        marginTop: 2,
                                        marginLeft: 3
                                    }} className={`material-icons fs-14 socinit1`} data-pr-tooltip={`Please indicate the location where the social impact initiative is being implemented. E.g. denpasar national forest`}
                                        data-pr-position="right"
                                        data-pr-at="right+5 top"
                                        data-pr-my="left center-2"> help</i></span>   </label>
                                <InputText
                                    id="title"
                                    className='mt-2'
                                    value={obj.locationOfProject}
                                    onChange={(e) => { updateObject("locationOfProject", e.target.value) }}
                                    placeholder='Name'
                                />
                                {submitted && obj.locationOfProject && obj.locationOfProject.trim().length === 0 && (
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >

                                        Enter Location Of Project
                                    </small>
                                )}
                            </div>
                            <div className='col-12 grid m-0 '>
                                <div className="p-field col-6 ps-0 pb-4">
                                    <label htmlFor="startDate" className='flex'> Planned Implementation Date <span className="mandatory"> *</span>    </label>
                                    <Calendar className='mt-2' id="implementationDate" value={obj.plannedImplementationDate} onChange={(e) => { updateObject("plannedImplementationDate", e.value) }} showIcon dateFormat='dd-MM-yy' />

                                    {submitted && obj.plannedImplementationDate === null && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >

                                            Select Implementation Date
                                        </small>
                                    )}
                                </div>

                                <div className="p-field col-6 ps-0 pb-4">
                                    <label htmlFor="status">Current Status <span className="mandatory"> *</span>  </label>
                                    <Dropdown id="status" className='mt-2' value={obj.currentStatus} optionValue='value' options={DPANN0027} onChange={(e) => { updateObject("currentStatus", e.value) }} placeholder="Select a Current Status" />

                                    {submitted && obj.currentStatus === null && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >

                                            Select Current Status
                                        </small>
                                    )}
                                </div>
                            </div>

                            <div className='col-12'>

                                <div className='col-12 grid m-0 '>

                                    <div className="p-field col-6 ps-0 pb-4 flex justify-content-end">
                                        <label className='text-underline cur-pointer fw-6 fs-14' onClick={() => { addNewAspects3() }}>Add Aspects </label>


                                    </div>
                                </div>

                                {obj.data.map((asp, index2) => {


                                    return (
                                        <div >
                                            <div className='col-12 grid m-0 ' style={{ background: 'lightgray' }} ><div className="text-three-dot col-11">Aspect #{(index2 + 1)}  </div> <div className='col-1'> <i className='pi pi-trash' style={{ color: 'red' }} onClick={() => { removeAspect2(index2) }} /> </div></div>
                                            <div className='col-12 grid m-0 '>
                                                <div className="p-field col-6 ">
                                                    <Tooltip className="form-tt-1" target={".socinit2"} mouseTrack mouseTrackLeft={10} />
                                                    <label htmlFor="startDate" className='flex'> Select Social Impact Measurement <span className="mandatory"> *</span>
                                                        <span><i style={{
                                                            marginTop: 2,
                                                            marginLeft: 3
                                                        }} className={`material-icons fs-14 socinit2`} data-pr-tooltip={` choose at least one measurement`}
                                                            data-pr-position="right"
                                                            data-pr-at="right+5 top"
                                                            data-pr-my="left center-2"> help</i></span>    </label>
                                                    <Dropdown className='mt-2' id="aspects" onChange={(e) => { obj.data[index2].DPANN0036 = e.value; forceUpdate() }} value={asp.DPANN0036} options={categorylist2} optionValue="id" optionLabel='name' />

                                                    {submitted && asp.DPANN0036 === null && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >

                                                            Select Social Impact Measurement
                                                        </small>
                                                    )}
                                                </div>

                                                <div className="p-field  col-6 ">
                                                    <Tooltip className="form-tt-1" target={".socinit3"} mouseTrack mouseTrackLeft={10} />
                                                    <label htmlFor="baselineData" className='flex'>Measureable Social Impact<span className="mandatory"> *</span>
                                                        <span><i style={{
                                                            marginTop: 2,
                                                            marginLeft: 3
                                                        }} className={`material-icons fs-14 socinit3`} data-pr-tooltip={`Please input as zero (0) if estimates are not available.`}
                                                            data-pr-position="right"
                                                            data-pr-at="right+5 top"
                                                            data-pr-my="left center-2"> help</i></span>

                                                    </label>
                                                    <InputNumber className='mt-2' min={0} id="baselineData" value={asp.DPANN0037} onChange={(e) => { obj.data[index2].DPANN0037 = e.value; forceUpdate() }} />

                                                    {submitted && parseFloat(asp.DPANN0037) < 0 && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >

                                                            Enter Measureable Social Impact
                                                        </small>
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })

                                }
                            </div>





                            <div className="p-field col-12 ">
                                <Tooltip className="form-tt-1" target={".socinit4"} mouseTrack mouseTrackLeft={10} />

                                <label htmlFor="description" className='flex'>Other Intangible Benefits  <span><i style={{
                                    marginTop: 2,
                                    marginLeft: 3
                                }} className={`material-icons fs-14 socinit4`} data-pr-tooltip={`Please describe other potential intangible benefits to the community from the initiative. For example, employee morale, brand reputation, satisfaction and retention.`}
                                    data-pr-position="right"
                                    data-pr-at="right+5 top"
                                    data-pr-my="left center-2"> help</i></span></label>
                                <InputText className='mt-2' id="description" value={obj.DPANN0038} onChange={(e) => setObject({ ...obj, DPANN0038: e.target.value })} />


                            </div>



                            <div className="p-field col-12 ">
                                <label htmlFor="description" className='flex'>Remarks  </label>
                                <InputText className='mt-2' id="description" value={obj.DPANN0039} onChange={(e) => setObject({ ...obj, DPANN0039: e.target.value })} />


                            </div>

                            <div className="p-field  col-12 ">
                                <label htmlFor="attachment" className='flex' >Attachment        </label>
                                {obj.attachment && obj.attachment.length ?
                                    obj.attachment.map((i) => {
                                        return attachmentTemplate(i)
                                    })
                                    : <div {...getRootProps()} className='mt-2' style={dropzoneStyle}>
                                        <input {...getInputProps()} />
                                        <i className='pi pi-cloud-upload'></i>
                                        <p>Drag and drop or click to upload</p>
                                    </div>}
                            </div>




                        </div>
                    </Dialog>
                </div>
            ) :
                <div className="col-12 card">You have no rights to access this page </div>
            }

        </div>
    );
};

export default STTInitiative;
