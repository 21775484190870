import React, { Component, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';

import { Buffer } from 'buffer';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import useForceUpdate from 'use-force-update';

window.Buffer = Buffer;
const Sidebar = (props) => {
    console.log(props)
    const [omenu, setOMenu] = useState({})
    const [menuitems, setMenuItems] = useState(props.menuitems)
    const loc = useLocation()
    const forceUpdate = useForceUpdate()
    useEffect(() => {

        onRouteChanged();
        // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
        const body = document.querySelector('body');
        document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

            el.addEventListener('mouseover', function () {
                if (body.classList.contains('sidebar-icon-only')) {
                    el.classList.add('hover-open');
                }
            });
            el.addEventListener('mouseout', function () {
                if (body.classList.contains('sidebar-icon-only')) {
                    el.classList.remove('hover-open');
                }
            });
        });
    }, [])
    const adjustSubMenuPosition = (element) => {

        const rect = element.getBoundingClientRect();
        console.log(rect)
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const windowWidth = window.innerWidth || document.documentElement.clientWidth;

        const isFullyVisible =
            rect.top >= 0 && rect.left >= 0 && rect.bottom <= windowHeight && rect.right <= windowWidth;

        const isPartiallyVisible =
            (rect.top >= 0 && rect.top <= windowHeight) || (rect.bottom >= 0 && rect.bottom <= windowHeight);
        console.log(isPartiallyVisible, isFullyVisible)
        if (isPartiallyVisible && !isFullyVisible) {
            element.style.top = +6 - rect.height + 'px'
        }
    };

    const isPathActive = (path) => {

        return loc.pathname.startsWith(path)
    }
    const toggleMenuState = (menuState) => {

        let loc = JSON.parse(JSON.stringify(omenu))

        Object.keys(loc).forEach((i) => {
            console.log(i)
            if (i === menuState) {
                console.log(loc[i])
                loc[i] = !loc[i]
            } else {
                loc[i] = false
            }
        })

        console.log(loc)
        setOMenu(loc)
        forceUpdate()
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props.location !== prevProps.location) {
    //         this.onRouteChanged();
    //     }
    // }

    const onRouteChanged = () => {
        document.querySelector('#sidebar').classList.remove('active');
        let loc = {}
        menuitems.forEach((i) => {
            if (i.label !== undefined) {
                loc[i.label] = false
            }
        })
        console.log(loc)
        setOMenu(loc)


    }
    const encodeUserEmail = (userEmail) => {
        const base64Encoded = Buffer.from(userEmail).toString('base64');
        const replacedChars = base64Encoded
            .replace(/\+/g, '-')
            .replace(/\//g, '_');
        const trimmed = replacedChars.replace(/=+$/, '');

        return trimmed;
    };
    const onHoverLink = (e) => {

        if (document.getElementsByClassName('nav-item hover-open') !== null && document.body.className !== '') {
            //    adjustSubMenuPosition(document.getElementsByClassName('nav-item hover-open')[0].childNodes[1])
        }


    }
    const logout = () => {
        props.logout()
    }
    return (
        <nav className="sidebar sidebar-offcanvas font-lato fs-16 fw-7" id="sidebar" >
            <div className='sidebar-action' style={{ left: document.body.className === '' ? 183 : 46.25 }}  >
                <div
                    onClick={(e) => { document.body.classList.toggle('sidebar-icon-only'); props.onToggleMenuClick(e) }}
                    style={{
                        cursor: 'pointer',

                        width: '30px',
                        height: '30px',
                        background: 'white',
                        alignItems: 'center',
                        borderRadius: '15px',
                        justifyContent: 'center',
                        display: 'flex'
                    }}>
                    <i className='material-icons' style={{ fontSize: 18, rotate: document.body.className === '' ? '180deg' : '360deg' }}>arrow_forward_ios</i>
                </div>
            </div>
            <ul className="nav">

                {menuitems.map((menu) => {
                    if (menu !== false && menu.label !== undefined && menu.items !== undefined) {

                        return (

                            <li onMouseMove={(e) => { onHoverLink(e) }} className={isPathActive(menu.to) ? 'nav-item active' : 'nav-item'}>
                                <div className={omenu[menu.label] ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState(menu.label)} data-toggle="collapse">
                                    {menu.icon === 'material-icons' ?
                                        <i className='material-icons menu-icon'>{menu.gicon}</i> :
                                        <i className={menu.icon + ' menu-icon'}></i>
                                    }
                                    <span className="menu-title">{menu.label}</span>

                                    <i className="material-icons menu-arrow">navigate_next</i>
                                </div>
                                <Collapse in={omenu[menu.label] === true}>
                                    <ul className="nav flex-column sub-menu">
                                        {menu.items.map((submenu) => {
                                            return (
                                                <li className="nav-item"> <Link className={isPathActive(submenu.to) ? 'nav-link active' : 'nav-link'} target={submenu.command !== undefined && '_blank'} to={submenu.to}>
                                                    {submenu.icon === 'material-icons' ?
                                                        <i className='material-icons menu-icon'>{submenu.gicon}</i> :
                                                        <i className={submenu.icon + ' menu-icon'}></i>
                                                    }
                                                    {submenu.label}</Link></li>
                                            )
                                        })

                                        }
                                    </ul>
                                </Collapse>
                            </li>
                        )
                    } else if (menu !== false && menu.label !== undefined && menu.items === undefined) {
                        if (menu.exit) {
                            return (
                                <li className={(isPathActive(menu.to) && menu.hover === undefined) ? 'nav-item active' : 'nav-item'}>
                                    <div className="nav-link" onClick={logout} >
                                        {menu.icon === 'material-icons' ?
                                            <i className='material-icons menu-icon'>{menu.gicon}</i> :
                                            <i className={menu.icon + ' menu-icon'}></i>
                                        }
                                        <span className="menu-title">{menu.label}</span>
                                    </div>
                                </li>
                            )
                        } else {
                            if(menu.mdi){
                                return (
                                    <li className={(isPathActive(menu.to) && menu.hover === undefined) ? 'nav-item active' : 'nav-item'}>
                                        <Link className="nav-link" to={menu.to} target={menu.command !== undefined && '_blank'} >
                                        <i className={'mdi mdi-ladybug ' + ' menu-icon'} style={{fontSize:28}}></i>
                                            <span className="menu-title">{menu.label}</span>
                                        </Link>
                                    </li>
                                )
                            }else{
                                return (
                                    <li className={(isPathActive(menu.to) && menu.hover === undefined) ? 'nav-item active' : 'nav-item'}>
                                        <Link className="nav-link" to={menu.to} target={menu.command !== undefined && '_blank'} >
                                            {menu.icon === 'material-icons' ?
                                                <i className='material-icons menu-icon'>{menu.gicon}</i> :
                                                <i className={menu.icon + ' menu-icon'}></i>
                                            }
                                            <span className="menu-title">{menu.label}</span>
                                        </Link>
                                    </li>
                                )
                            }
                          
                        }

                    } else if (menu !== false) {
                        return menu.items.map((menu_) => {
                            return (
                                <li className={isPathActive(menu_.to) ? 'nav-item active' : 'nav-item'}>
                                    <Link className="nav-link" to={menu_.to} target={menu_.command !== undefined && '_blank'}>
                                        {menu_.icon === 'material-icons' ?
                                            <i className='material-icons menu-icon'>{menu_.gicon}</i> :
                                            <i className={menu_.icon + ' menu-icon'}></i>
                                        }
                                        <span className="menu-title ">{menu_.label}</span>
                                    </Link>
                                </li>
                            )
                        })
                    }

                })}
                {/* {roles.roles.includes('all') && <>
                        <li className={isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/dashboard">
                                <i className="mdi mdi-home menu-icon"></i>
                                <span className="menu-title">Dashboard</span>
                            </Link>
                        </li>
                        <li className={isPathActive('/company') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/company">
                                <i className="mdi mdi-account-multiple-plus menu-icon"></i>
                                <span className="menu-title">Company</span>
                            </Link>
                        </li>
                        <li className={isPathActive('/services') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/services">
                                <i className="mdi  mdi-briefcase-check menu-icon"></i>
                                <span className="menu-title">Services</span>
                            </Link>
                        </li>
                        <li className={isPathActive('/hazards') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/hazards">
                                <i className="mdi mdi-alert-outline menu-icon"></i>
                                <span className="menu-title">Hazards</span>
                            </Link>
                        </li>

                    </>}
                    {roles !== '' && roles.applicationRoles.includes('admin') ? <>
                        <li className={isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/dashboard">
                                <i className="mdi mdi-home menu-icon"></i>
                                <span className="menu-title">Dashboard</span>
                            </Link>
                        </li>
                        <li className={isPathActive('/apps') ? 'nav-item active' : 'nav-item'}>
                            <div className={this.state.appsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('appsMenuOpen')} data-toggle="collapse">
                                <i className="mdi mdi-cube-outline menu-icon"></i>
                                <span className="menu-title">Curate</span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={this.state.appsMenuOpen}>
                                <ul className="nav flex-column sub-menu">
                                    <li className="nav-item"> <Link className={isPathActive('/apps/knowledge') ? 'nav-link active' : 'nav-link'} to="/apps/knowledge">Knowledge</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/apps/checklist') ? 'nav-link active' : 'nav-link'} to="/apps/checklist">Checklist</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/apps/document') ? 'nav-link active' : 'nav-link'} to="/apps/document">Document</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/apps/forms') ? 'nav-link active' : 'nav-link'} to="/apps/forms">Form</Link></li>
                                </ul>
                            </Collapse>
                        </li>
                        <li className={isPathActive('/users') ? 'nav-item active' : 'nav-item'}>
                            <div className={this.state.usersMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('usersMenuOpen')} data-toggle="collapse">
                                <i className="mdi  mdi-account menu-icon"></i>
                                <span className="menu-title">Users</span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={this.state.usersMenuOpen}>
                                <ul className="nav flex-column sub-menu">
                                    <li className="nav-item"> <Link className={isPathActive('/users/enduser') ? 'nav-link active' : 'nav-link'} to="/users/enduser">Users</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/users/entity') ? 'nav-link active' : 'nav-link'} to="/users/entity">Admin</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/users/curator') ? 'nav-link active' : 'nav-link'} to="/users/curator">Curator</Link></li>

                                </ul>
                            </Collapse>
                        </li>

                        <li className={isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item'}>
                            <div className={this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('basicUiMenuOpen')} data-toggle="collapse">
                                <i className="menu-icon mdi mdi-floor-plan"></i>
                                <span className="menu-title">Assignment</span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={this.state.basicUiMenuOpen}>
                                <ul className="nav flex-column sub-menu">
                                    <li className="nav-item"> <Link className={isPathActive('/basic-ui/knowledge') ? 'nav-link active' : 'nav-link'} to="/basic-ui/knowledge">Knowledge</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/basic-ui/checklist') ? 'nav-link active' : 'nav-link'} to="/basic-ui/checklist">Checklist</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/basic-ui/document') ? 'nav-link active' : 'nav-link'} to="/basic-ui/document">Document</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/basic-ui/form') ? 'nav-link active' : 'nav-link'} to="/basic-ui/form">Form</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/basic-ui/curator') ? 'nav-link active' : 'nav-link'} to="/basic-ui/curator"> To Curator</Link></li>
                                    

                                </ul>
                            </Collapse>
                        </li>
                        <li className={isPathActive('/groups') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/groups">
                                <i className="menu-icon mdi mdi-account-multiple"></i>
                                <span className="menu-title">Groups</span>

                            </Link>

                        </li>
                        <li className={isPathActive('/broadcast') ? 'nav-item active' : 'nav-item'}>
                            <div className={this.state.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('formElementsMenuOpen')} data-toggle="collapse">
                                <i className="menu-icon mdi mdi-bell-ring"></i>
                                <span className="menu-title">Broadcast</span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={this.state.formElementsMenuOpen}>
                                <ul className="nav flex-column sub-menu">
                                    <li className="nav-item"> <Link className={isPathActive('/broadcast/one-way') ? 'nav-link active' : 'nav-link'} to="/broadcast/one-way">One Way</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/broadcast/two-way') ? 'nav-link active' : 'nav-link'} to="/broadcast/two-way">Two Way</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/broadcast/template') ? 'nav-link active' : 'nav-link'} to="/broadcast/template">Template</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/broadcast/list') ? 'nav-link active' : 'nav-link'} to="/broadcast/list">Analytics</Link></li>
                                </ul>
                            </Collapse>
                        </li>
           
                        <li className={isPathActive('/insight') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/insight">
                                <i className="menu-icon mdi mdi-poll"></i>
                                <span className="menu-title">Insights</span>

                            </Link>

                        </li>
                        <li className={isPathActive('/reports') ? 'nav-item active' : 'nav-item'}>
                            <div className={this.state.reportElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('reportElementsMenuOpen')} data-toggle="collapse">
                                <i className="menu-icon mdi mdi-file-chart"></i>
                                <span className="menu-title">Reports</span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={this.state.reportElementsMenuOpen}>
                                <ul className="nav flex-column sub-menu">
                                    <li className="nav-item"> <Link className={isPathActive('/reports/knowledge') ? 'nav-link active' : 'nav-link'} to="/reports/knowledge">Knowledge</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/reports/checklists') ? 'nav-link active' : 'nav-link'} to="/reports/checklists">Checklists</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/reports/forms') ? 'nav-link active' : 'nav-link'} to="/reports/forms">Forms</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/reports/documents') ? 'nav-link active' : 'nav-link'} to="/reports/documents">Documents</Link></li>
                                </ul>
                            </Collapse>
                        </li>
                      
                        <li className={'nav-item'}>
                            <a className="nav-link" href={url} target={"_blank"} rel="noopener noreferrer">
                                <i className="menu-icon mdi mdi-arrow-down-drop-circle-outline"></i>
                                <span className="menu-title">H5P</span>

                            </a>

                        </li>
                    </> : roles !== '' && roles.applicationRoles.includes('entity') ? <>
                        <li className={isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/dashboard">
                                <i className="mdi mdi-home menu-icon"></i>
                                <span className="menu-title">Dashboard</span>
                            </Link>
                        </li>
                        <li className={isPathActive('/apps') ? 'nav-item active' : 'nav-item'}>
                            <div className={this.state.appsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('appsMenuOpen')} data-toggle="collapse">
                                <i className="mdi mdi-cube-outline menu-icon"></i>
                                <span className="menu-title">Curate</span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={this.state.appsMenuOpen}>
                                <ul className="nav flex-column sub-menu">
                                    <li className="nav-item"> <Link className={isPathActive('/apps/knowledge') ? 'nav-link active' : 'nav-link'} to="/apps/knowledge">Knowledge</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/apps/checklist') ? 'nav-link active' : 'nav-link'} to="/apps/checklist">Checklist</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/apps/document') ? 'nav-link active' : 'nav-link'} to="/apps/document">Document</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/apps/forms') ? 'nav-link active' : 'nav-link'} to="/apps/forms">Form</Link></li>
                                </ul>
                            </Collapse>
                        </li>
                        <li className={isPathActive('/users') ? 'nav-item active' : 'nav-item'}>
                            <div className={this.state.usersMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('usersMenuOpen')} data-toggle="collapse">
                                <i className="mdi  mdi-account menu-icon"></i>
                                <span className="menu-title">Users</span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={this.state.usersMenuOpen}>
                                <ul className="nav flex-column sub-menu">
                                    <li className="nav-item"> <Link className={isPathActive('/users/enduser') ? 'nav-link active' : 'nav-link'} to="/users/enduser">Users</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/users/curator') ? 'nav-link active' : 'nav-link'} to="/users/curator">Curator</Link></li>

                                </ul>
                            </Collapse>
                        </li>
                        <li className={isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item'}>
                            <div className={this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('basicUiMenuOpen')} data-toggle="collapse">
                                <i className="menu-icon mdi mdi-floor-plan"></i>
                                <span className="menu-title">Assignment</span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={this.state.basicUiMenuOpen}>
                                <ul className="nav flex-column sub-menu">
                                    <li className="nav-item"> <Link className={isPathActive('/basic-ui/knowledge') ? 'nav-link active' : 'nav-link'} to="/basic-ui/knowledge">Knowledge</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/basic-ui/curator') ? 'nav-link active' : 'nav-link'} to="/basic-ui/curator">Curator</Link></li>
                                  

                                </ul>
                            </Collapse>
                        </li>
                        <li className={isPathActive('/broadcast') ? 'nav-item active' : 'nav-item'}>
                            <div className={this.state.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('formElementsMenuOpen')} data-toggle="collapse">
                                <i className="menu-icon mdi mdi-bell-ring"></i>
                                <span className="menu-title">Broadcast</span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={this.state.formElementsMenuOpen}>
                                <ul className="nav flex-column sub-menu">
                                    <li className="nav-item"> <Link className={isPathActive('/broadcast/one-way') ? 'nav-link active' : 'nav-link'} to="/broadcast/one-way">One Way</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/broadcast/two-way') ? 'nav-link active' : 'nav-link'} to="/broadcast/two-way">Two Way</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/broadcast/template') ? 'nav-link active' : 'nav-link'} to="/broadcast/template">Template</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/broadcast/list') ? 'nav-link active' : 'nav-link'} to="/broadcast/list">Analytics</Link></li>
                                </ul>
                            </Collapse>
                        </li>
                        <li className={isPathActive('/insight') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/insight">
                                <i className="menu-icon mdi mdi-poll"></i>
                                <span className="menu-title">Insights</span>

                            </Link>

                        </li>
                        <li className={isPathActive('/reports') ? 'nav-item active' : 'nav-item'}>
                            <div className={this.state.reportElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('reportElementsMenuOpen')} data-toggle="collapse">
                                <i className="menu-icon mdi mdi-file-chart"></i>
                                <span className="menu-title">Reports</span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={this.state.reportElementsMenuOpen}>
                                <ul className="nav flex-column sub-menu">
                                    <li className="nav-item"> <Link className={isPathActive('/reports/knowledge') ? 'nav-link active' : 'nav-link'} to="/reports/knowledge">Knowledge</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/reports/checklists') ? 'nav-link active' : 'nav-link'} to="/reports/checklists">Checklists</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/reports/forms') ? 'nav-link active' : 'nav-link'} to="/reports/forms">Forms</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/reports/documents') ? 'nav-link active' : 'nav-link'} to="/reports/documents">Documents</Link></li>
                                </ul>
                            </Collapse>
                        </li>
                        
                        <li className={'nav-item'}>
                            <a className="nav-link" href={url} target={"_blank"} rel="noopener noreferrer">
                                <i className="menu-icon mdi mdi-arrow-down-drop-circle-outline"></i>
                                <span className="menu-title">H5P</span>

                            </a>

                        </li>
                    </> : roles !== '' && roles.applicationRoles.includes('curator') ? <>
                        <li className={isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/dashboard">
                                <i className="mdi mdi-home menu-icon"></i>
                                <span className="menu-title">Dashboard</span>
                            </Link>
                        </li>
                        <li className={isPathActive('/apps') ? 'nav-item active' : 'nav-item'}>
                            <div className={this.state.appsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('appsMenuOpen')} data-toggle="collapse">
                                <i className="mdi mdi-cube-outline menu-icon"></i>
                                <span className="menu-title">Curate</span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={this.state.appsMenuOpen}>
                                <ul className="nav flex-column sub-menu">
                                    <li className="nav-item"> <Link className={isPathActive('/apps/knowledge') ? 'nav-link active' : 'nav-link'} to="/apps/curatorknowledge">Knowledge</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/apps/checklist') ? 'nav-link active' : 'nav-link'} to="/apps/checklist">Checklist</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/apps/document') ? 'nav-link active' : 'nav-link'} to="/apps/document">Document</Link></li>
                                    <li className="nav-item"> <Link className={isPathActive('/apps/forms') ? 'nav-link active' : 'nav-link'} to="/apps/forms">Form</Link></li>
                                </ul>
                            </Collapse>
                        </li>
                        <li className={isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item'}>
                            <div className={this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('basicUiMenuOpen')} data-toggle="collapse">
                                <i className="menu-icon mdi mdi-floor-plan"></i>
                                <span className="menu-title">Assignment</span>
                                <i className="menu-arrow"></i>
                            </div>
                            <Collapse in={this.state.basicUiMenuOpen}>
                                <ul className="nav flex-column sub-menu">
                                    <li className="nav-item"> <Link className={isPathActive('/basic-ui/knowledge') ? 'nav-link active' : 'nav-link'} to="/basic-ui/knowledge">Knowledge</Link></li>
                                   
                                </ul>
                            </Collapse>
                        </li>
                        <li className={isPathActive('/insight') ? 'nav-item active' : 'nav-item'}>
                            <Link className="nav-link" to="/insight">
                                <i className="menu-icon mdi mdi-poll"></i>
                                <span className="menu-title">Insights</span>

                            </Link>

                        </li>
                        <li className={'nav-item'}>
                            <a className="nav-link" href={url} target={"_blank"} rel="noopener noreferrer">
                                <i className="menu-icon mdi mdi-arrow-down-drop-circle-outline"></i>
                                <span className="menu-title">H5P</span>

                            </a>

                        </li>

                    </> : ''} */}




            </ul>
        </nav>
    );






}



export default Sidebar;