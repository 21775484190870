import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FileUpload } from 'primereact/fileupload';
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import $ from "jquery";
import { Tag } from 'primereact/tag';
import { ProgressBar } from 'primereact/progressbar';
import { API } from "../components/constants/api_url";
import { ContextMenu } from 'primereact/contextmenu';
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { Editor } from "primereact/editor";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { InputTextarea } from "primereact/inputtextarea";
import { BGSQ14, BGSQ15, BGSQ21, BGSQ24, BP1EQ2, BP4EQ2, BP7EQ2, BP7LQ1, BP8LQ1, BP8LQ2, BP8LQ4, BP8LQ5, BP8LQ6, BP7EQ1B, BP8EQ1, BP9LQ1, BP2LQ2, BP2LQ1, BP6LQ6, BP6EQ12, BP6EQ11, BP6EQ10 } from "./hardcodedRF";
import APIServices from "../service/APIService";

window.jQuery = $;
window.$ = $;
const hardcodedrf = ['85', '89', '102', '104', '110', '111', '112', '113', '114', '115', '116', '117', '118', '121', '133', '134', '139', '140', '148', '149', '150', '151']

const RFSubmittedPreview = () => {
    const selector = useSelector((state) => state.user.userdetail);
    const [data, setData] = useState([])
    const forceUpdate = useForceUpdate();
    const { id } = useParams();
    const navigate = useHistory()
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);
    const [show, setShow] = useState(false)
    const params = useLocation()
    console.log(params.state)
    useEffect(async () => {

        APIServices.get(API.RF_Edit(id)).then((res) => {
            if (params.state.oldData.length === 0) {
                res.data.reject = 0

                setData(res.data)
            } else {
                console.log('oldData', params.state.oldData)
                setShow(params.state.oldData.type === 0 ? true : false)
                if (params.state.oldData.type === 0) {
                    if (hardcodedrf.includes(id)) {
                        res.data.data1 = params.state.oldData.response
                    } else {
                        res.data.data1 = migrateResponse(res.data.data1, params.state.oldData.response)
                    }

                    res.data.reject = params.state.oldData.reject
                    setData(res.data)


                } else {
                    setData(res.data)
                    res.data.data2 = params.state.oldData.data2
                }



            }

        })


    }, []);
    useEffect(() => {
        console.log(data)
    }, [data])
    const checkHardcoded = () => {
        console.log(id)
        if (hardcodedrf.includes(id)) {
            return true
        } else {
            return false
        }

    }
    const migrateResponse = (formData, oldData) => {

        formData.forEach((i) => {
            let index = oldData.findIndex((j) => { return getType(j.type) === i.type && i.name === j.name })
            if (index !== -1) {
                if (i.type !== 'checkbox-group' && i.type !== 'radio-group' && i.type !== 'select') {

                    i.value = oldData[index].value
                } else {

                    i.values.forEach((k, l) => {
                        k.selected = oldData[index].value.includes(l)
                    })
                }
            }
        })

        return formData
    }
    const getType = (item) => {
        if (item === 1) {
            return 'text'
        } else if (item === 2) {
            return 'textarea'
        } else if (item === 3) {
            return 'select'
        } else if (item === 4) {
            return 'checkbox-group'
        } else if (item === 5) {
            return 'number'
        } else if (item === 6) {
            return 'radio-group'
        } else if (item === 7) {
            return 'file'
        } else if (item === 'paragraph') {
            return 'paragraph'
        } else if (item === 9) {
            return 'date'
        }
    }
    const onCheckBoxSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = !items.selected
            }
        })
        forceUpdate()
    }
    const onRadioButtonSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        forceUpdate()
    }
    const onDateSelected = (item, val) => {
        item.value = val;
        forceUpdate()
    }
    const onNumberChange = (item, val) => {
        item.value = val;
        forceUpdate()
    }

    const onChangeDropwdown = (item, val) => {
        item.value = val;
        console.log(val)
        forceUpdate()
    }
    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };
    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e) => {
        console.log(e)
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);

    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Drag and Drop Image Here
                </span>
            </div>
        );
    };
    const handleFileUpload = (e, item) => {
        let ext = e.target.files[0].name.substr(e.target.files[0].name.lastIndexOf('.'))
        let allowedext = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.pdf', '.PDF']
        if (allowedext.includes(ext)) {
            let formData = new FormData()
            formData.append('file', e.target.files[0])
            APIServices.post(API.FilesUpload, formData, {
                headers: {
                    'content-type': 'multipart/form-data'

                }
            }).then((res) => {
                res.data.files[0].extension = ext
                if (item.value === undefined) {
                    item['value'] = [res.data.files[0]]
                } else {
                    if (item.multiple) {
                        item['value'].push(res.data.files[0])
                    } else {
                        item['value'] = [res.data.files[0]]
                    }

                }
                forceUpdate()

            })
        } else {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "invalid file format, supported format JPEG,PNG & PDF only",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    }
    const removeImage = (index, findex) => {

        data.data1[index].value.splice(findex, 1)
        forceUpdate()

    }
    const resetFiles = (item, index) => {

        item.value = []
        forceUpdate()

    }
    const renderItems = (item, index) => {


        if (item.type === 'checkbox-group') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ flexDirection: 'column', marginBottom: 15, padding: 10 }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    {item.values.map((cb, cbind) => {
                        return (
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <Checkbox disabled inputId={"cb" + cbind} name={cb.label} value={cb.value} onChange={(e) => { onCheckBoxSelected(item, cbind) }} checked={cb.selected} />
                                <label htmlFor={"cb" + cbind} className="ml-2">{cb.label}</label>
                            </div>
                        )
                    })

                    }


                </div>
            )
        } else if (item.type === 'date') {

            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-12'> {item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <Calendar disabled style={{ margin: 10 }} value={item.value} onChange={(e) => { onDateSelected(item, e.value) }} />
                </div>
            )
        } else if (item.type === 'number') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <InputText disabled keyfilter="num" style={{ margin: 10 }} value={item.value} onChange={(e) => { onNumberChange(item, e.target.value) }} />
                </div>
            )
        } else if (item.type === 'paragraph') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>

                    <label style={{ margin: 10 }}>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')}</label>

                </div>
            )
        } else if (item.type === 'radio-group') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    {item.values.map((cb, cbind) => {
                        return (
                            <div className="flex align-items-center" style={{ margin: 10 }}>
                                <RadioButton disabled inputId={"cb" + cbind} name={cb.label} value={cb.value} onChange={(e) => onRadioButtonSelected(item, cbind)} checked={cb.selected === true} />

                                <label htmlFor={"cb" + cbind} className="ml-2">{cb.label}</label>
                            </div>
                        )
                    })

                    }


                </div>
            )
        } else if (item.type === 'select') {
            item.value = item.values.filter((i) => { return i.selected }).length === 0 ? null : item.values.filter((i) => { return i.selected })[0].label

            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ flexDirection: 'column', marginBottom: 15, padding: 10 }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>


                    <div className="flex align-items-center" style={{ margin: 10 }}>

                        <Dropdown disabled options={item.values} style={{ width: '25%' }} optionLabel='label' optionValue="label" value={item.value} onChange={(e) => { onChangeDropwdown(item, e.value) }} />
                    </div>

                </div>
            )
        } else if (item.type === 'text') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <InputText disabled style={{ margin: 10 }} value={item.value} onChange={(e) => { onNumberChange(item, e.target.value) }} />
                </div>
            )
        } else if (item.type === 'textarea') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <Editor disabled value={item.value} style={{ width: '100%', padding: 10, height: 'auto' }} onTextChange={(e) => onNumberChange(item, e.htmlValue)} />

                </div>
            )
        } else if (item.type === 'file') {
            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;','&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <div className="col-12" >

                    </div>
                </div>
            )
        }

    }
    const getBGColor = (clr) => {

        if (data.reject === 1 && clr === data.reject) {
            return 'red'
        } else if (data.reject === 2 && clr === data.reject) {
            return 'indianred'
        } else if (data.reject === 3 && clr === data.reject) {
            return 'darkorange'
        } else if (data.reject === 4 && clr === data.reject) {
            return 'mediumseagreen'
        } else if (data.reject === 5 && clr === data.reject) {
            return 'green'
        } else {
            return 'white'
        }
    }
    const getColor = (clr) => {

        if (data.reject === 1 && clr === data.reject) {
            return 'white'
        } else if (data.reject === 2 && clr === data.reject) {
            return 'white'
        } else if (data.reject === 3 && clr === data.reject) {
            return 'white'
        } else if (data.reject === 4 && clr === data.reject) {
            return 'white'
        } else if (data.reject === 5 && clr === data.reject) {
            return 'white'
        } else { return 'black' }
    }
    return (
        <div className="grid">
            <div className="col-12">
                {(selector.id !== undefined && data.length !== 0) ?
                    <div className="card">

                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                            <h4>{data.title}</h4>
                            <label style={{ color: 'red' }}> (preview only)</label>
                        </div>
                        <div className="col-12 grid">
                            <div className="col-8" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>    <div className="col-1" style={{ fontWeight: 'bold', width: 'auto' }}>Aspects : </div> <div className="col-11" style={{ display: 'flex' }}>{params.state.data.title}   <Tooltip className="tag-tooltip" target={".tags"} position={'top'} autoHide={true}> {params.state.data.overallTags.map((i, j) => {
                                        if (i.length !== 0) {
                                            return (
                                                <>
                                                    <label style={{ color: 'black', display: 'flex' }}> {
                                                        j === 0 ? 'Must Have' : j === 1 ? 'Progressive' : 'Advanced'

                                                    }
                                                    </label>
                                                    {
                                                        i.map((tag, k) => {

                                                            return (
                                                                <label style={{ color: 'green' }}>{tag}{k !== i.length - 1 && ','}</label>
                                                            )

                                                        })
                                                    }
                                                    <div style={{ marginBottom: 10 }} />
                                                </>
                                            )
                                        }
                                    })} </Tooltip>
                                        <div style={{ alignItems: 'center' }} ><i className={"material-icons tags"} style={{ fontSize: 14, cursor: 'pointer' }}>info</i>  </div>

                                    </div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>    <div className="col-2" style={{ fontWeight: 'bold', width: 'auto' }}>Requirement : </div> <div className="col-10">  {params.state.data.data1[0].title}</div> </div>

                                </div>

                            </div>
                            <div className="col-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>


                                    <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>    <span style={{ fontWeight: 'bold' }}>Category :&nbsp;</span> {params.state.data.cat_title}   </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>        <span style={{ fontWeight: 'bold' }}>Topic :&nbsp;</span> {params.state.data.top_title}  </div>
                                    {params.state.oldData.length !== 0 && <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>          <span style={{ fontWeight: 'bold' }}>Last Updated :&nbsp;</span> {moment(params.state.oldData.created_on).local().format('DD MMM YYYY, hh:mm A')}  </div>}

                                    <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>          <span style={{ fontWeight: 'bold' }}>Version :&nbsp;</span> {moment(data.updated).local().format('DD MMM YYYY, hh:mm A')}  </div>
                                </div>

                            </div>
                        </div>
                        {data.comments.trim().length !== 0 &&
                            <div className="p-card" style={{ padding: 10, marginBottom: 20 }}>
                                <span style={{ fontWeight: 'bold' }}>Note :&nbsp;</span> {data.comments}
                            </div>}
                        {show ?
                            <>
                                {(data.data1.length !== 0 && !checkHardcoded()) ?
                                    <div>
                                        <div>
                                            {data.data1.map((item, index) => {

                                                return renderItems(item, index)
                                            })

                                            }
                                        </div>
                                        <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                            <div style={{ marginBottom: 30 }}>
                                                Self-assessment on level of implementation
                                            </div>
                                            <div className="grid">
                                                <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                            </div>
                                            {/* <div className="col-5">
                                    <Slider value={data.reject} onChange={(e) => { data.reject = e.value; forceUpdate() }} style={{width:'100%'}} min={0} max={4} step={1} />
                                    </div> */}

                                        </div>



                                    </div>
                                    : id === '85' ?
                                        <div>
                                            <BGSQ14 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                            <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                <div style={{ marginBottom: 30 }}>
                                                    Self-assessment on level of implementation
                                                </div>
                                                <div className="grid">
                                                    <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                    <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                    <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                    <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                    <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                </div>


                                            </div>

                                        </div>
                                        : id === '89' ?
                                            <div>
                                                <BGSQ15 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                    <div style={{ marginBottom: 30 }}>
                                                        Self-assessment on level of implementation
                                                    </div>
                                                    <div className="grid">
                                                        <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                        <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                        <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                        <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                        <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                    </div>


                                                </div>

                                            </div> : id === '102' ?
                                                <div>
                                                    <BGSQ21 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                    <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                        <div style={{ marginBottom: 30 }}>
                                                            Self-assessment on level of implementation
                                                        </div>
                                                        <div className="grid">
                                                            <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                            <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                            <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                            <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                            <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                        </div>


                                                    </div>

                                                </div> : id === '104' ?

                                                    <div>
                                                        <BP1EQ2 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                        <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                            <div style={{ marginBottom: 30 }}>
                                                                Self-assessment on level of implementation
                                                            </div>
                                                            <div className="grid">
                                                                <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                            </div>


                                                        </div>

                                                    </div> : id === '110' ? <div>
                                                        <BGSQ24 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                        <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                            <div style={{ marginBottom: 30 }}>
                                                                Self-assessment on level of implementation
                                                            </div>
                                                            <div className="grid">
                                                                <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                            </div>


                                                        </div>

                                                    </div> : id === '111' ? <div>
                                                        <BP4EQ2 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                        <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                            <div style={{ marginBottom: 30 }}>
                                                                Self-assessment on level of implementation
                                                            </div>
                                                            <div className="grid">
                                                                <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                            </div>


                                                        </div>

                                                    </div> : id === '112' ? <div>
                                                        <BP7EQ2 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                        <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                            <div style={{ marginBottom: 30 }}>
                                                                Self-assessment on level of implementation
                                                            </div>
                                                            <div className="grid">
                                                                <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                            </div>


                                                        </div>

                                                    </div> : id === '113' ? <div>
                                                        <BP7LQ1 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                        <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                            <div style={{ marginBottom: 30 }}>
                                                                Self-assessment on level of implementation
                                                            </div>
                                                            <div className="grid">
                                                                <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                            </div>


                                                        </div>

                                                    </div> : id === '114' ? <div>
                                                        <BP8LQ1 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                        <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                            <div style={{ marginBottom: 30 }}>
                                                                Self-assessment on level of implementation
                                                            </div>
                                                            <div className="grid">
                                                                <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                            </div>


                                                        </div>

                                                    </div> : id === '115' ? <div>
                                                        <BP8LQ2 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                        <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                            <div style={{ marginBottom: 30 }}>
                                                                Self-assessment on level of implementation
                                                            </div>
                                                            <div className="grid">
                                                                <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                            </div>


                                                        </div>

                                                    </div> : id === '116' ? <div>
                                                        <BP8LQ4 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                        <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                            <div style={{ marginBottom: 30 }}>
                                                                Self-assessment on level of implementation
                                                            </div>
                                                            <div className="grid">
                                                                <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                            </div>


                                                        </div>

                                                    </div> : id === '117' ? <div>
                                                        <BP8LQ5 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                        <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                            <div style={{ marginBottom: 30 }}>
                                                                Self-assessment on level of implementation
                                                            </div>
                                                            <div className="grid">
                                                                <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                            </div>


                                                        </div>

                                                    </div> : id === '118' ? <div>
                                                        <BP8LQ6 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                        <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                            <div style={{ marginBottom: 30 }}>
                                                                Self-assessment on level of implementation
                                                            </div>
                                                            <div className="grid">
                                                                <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                            </div>


                                                        </div>

                                                    </div> : id === '121' ? <div>
                                                        <BP7EQ1B data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                        <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                            <div style={{ marginBottom: 30 }}>
                                                                Self-assessment on level of implementation
                                                            </div>
                                                            <div className="grid">
                                                                <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                            </div>


                                                        </div>

                                                    </div> : id === '133' ? <div>
                                                        <BP8EQ1 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                        <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                            <div style={{ marginBottom: 30 }}>
                                                                Self-assessment on level of implementation
                                                            </div>
                                                            <div className="grid">
                                                                <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                            </div>


                                                        </div>

                                                    </div> : id === '134' ? <div>
                                                        <BP9LQ1 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                        <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                            <div style={{ marginBottom: 30 }}>
                                                                Self-assessment on level of implementation
                                                            </div>
                                                            <div className="grid">
                                                                <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                            </div>


                                                        </div>

                                                    </div> : id === '139' ? <div>
                                                        <BP2LQ1 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                        <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                            <div style={{ marginBottom: 30 }}>
                                                                Self-assessment on level of implementation
                                                            </div>
                                                            <div className="grid">
                                                                <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                            </div>


                                                        </div>

                                                    </div> : id === '140' ? <div>
                                                        <BP2LQ2 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                        <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                            <div style={{ marginBottom: 30 }}>
                                                                Self-assessment on level of implementation
                                                            </div>
                                                            <div className="grid">
                                                                <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                            </div>


                                                        </div>

                                                    </div> : id === '148' ? <div>
                                                        <BP6EQ10 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                        <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                            <div style={{ marginBottom: 30 }}>
                                                                Self-assessment on level of implementation
                                                            </div>
                                                            <div className="grid">
                                                                <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                                <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                            </div>


                                                        </div>

                                                    </div>
                                                        : id === '149' ? <div>
                                                            <BP6EQ11 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                            <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                                <div style={{ marginBottom: 30 }}>
                                                                    Self-assessment on level of implementation
                                                                </div>
                                                                <div className="grid">
                                                                    <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                                    <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                                    <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                                    <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                                    <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                                </div>


                                                            </div>

                                                        </div>
                                                            : id === '150' ? <div>
                                                                <BP6EQ12 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                                    <div style={{ marginBottom: 30 }}>
                                                                        Self-assessment on level of implementation
                                                                    </div>
                                                                    <div className="grid">
                                                                        <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                                        <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                                        <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                                        <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                                        <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                                    </div>


                                                                </div>

                                                            </div>
                                                                : id === '151' && <div>
                                                                    <BP6LQ6 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                    <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                                        <div style={{ marginBottom: 30 }}>
                                                                            Self-assessment on level of implementation
                                                                        </div>
                                                                        <div className="grid">
                                                                            <label className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                                            <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                                            <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                                            <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                                            <label className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                                                        </div>


                                                                    </div>

                                                                </div>

                                }

                            </>
                            :
                            <div>
                                <label>justification</label>
                                <InputTextarea disabled style={{ margin: 10, width: '100%' }} autoResize={true} value={data.data2} onChange={(e) => { data.data2 = e.target.value; forceUpdate() }} />

                            </div>}
                        <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                            <Button label='Go Back' onClick={() => { navigate.goBack() }}></Button>
                        </div>
                    </div>
                    :
                    <div className="col-12 card">Form not found</div>
                    // <div className="col-12 card">You have no rights to access this page </div>

                }
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(RFSubmittedPreview, comparisonFn);
