import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { motion } from "framer-motion";
import useForceUpdate from "use-force-update";
import LazyView from "../../../components/LazyView";
import { DateTime } from "luxon";
import moment from "moment";
import { API } from "../../../components/constants/api_url";
import APIServices from "../../../service/APIService";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";

let activeRowData = {}
const QualitativeHistoric = () => {

    const { id } = useParams();
    const navigate = useHistory()
    const [searchstr, setSearchStr] = useState('')
    const [data, setData] = useState([])
    const [rawrf, setRawRF] = useState([])
    const [filterdcf, setFilterDCF] = useState([])
    const [done, setDone] = useState(false)
    const userList = useSelector(state => state.userlist.userList)
    const [ass, setAss] = useState([])
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quartely', id: 3 }, { name: 'Annually', id: 4 }, { name: 'Bi-Annually', id: 5 }, { name: 'Undefined', id: 6 }]
    const [overallmetric, setOverallMetric] = useState([])
    const [list, setList] = useState({metric:[],category:[],topic:[]})
    const [update, setUpdate] = useState(false)
    const [sitelist, setSiteList] = useState([])
    const login_data = useSelector((state) => state.user.userdetail)
    const client_info = useSelector((state) => state.userlist.admindetail)
    const [rfass, setRFAss] = useState([])
    const [present, setPresent] = useState([])
    const [past, setPast] = useState([])
    const [pastbk, setPastBK] = useState([])
    const [historydata, setHistoryData] = useState([])
    const [historydialog, setHistoryDialog] = useState(false)
    const [selectedsite, setSelectedSite] = useState(null)
    const [selected, setSelected] = useState({ category: [], topic: [], framework: [], metric: [] })

    const [future, setFuture] = useState([])
    const [dcflist, setDcfList] = useState([])

    const [sitelistall, setSiteListAll] = useState([])
    const [dcfassign, setDCFAssign] = useState([])
    const [submitteddcf, setSubmittedDCF] = useState([])
    const [pendingap, setPendingAP] = useState([])
    const [response, setResponse] = useState([])
    const [rfresponse, setRFResponse] = useState([])

    const forceUpdate = useForceUpdate()

    useEffect(() => {

        if (Object.keys(client_info).length !== 0 && done === false) {

            setDone(true)
            console.log('its working')
            let uriString = {
                "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

            }
            let uriString3 = {
                "include": [{ "relation": "newDataPoints" }]

            }
            let uriString2 = {
                "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]


            }

            APIServices.get(API.AssignDCFUser_UP(login_data.information.cid)).then((res) => {
                setAss(res.data)
            })
            let dcf_list = [], dcf_submitted = [], locloc = []
            let site_url = API.LocationOne_UP(login_data.information.cid) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;
            const promise1 = APIServices.get(API.DCF)
            const promise2 = APIServices.get(API.DCF_Submit_UP(login_data.information.cid))
            const promise3 = APIServices.get(API.LocationThree)
            let promise4 = APIServices.get(API.RF)
            let promise5 = APIServices.get(API.RF_Submit_UP(login_data.information.cid))
            let promise6 = APIServices.get(API.Report_Name_Twos)
            let promise7 = APIServices.get(API.QL_Listing_Filter_UP(login_data.information.cid))

            let Overall = API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;

            let url = API.Metric + `?filter=${encodeURIComponent(JSON.stringify(uriString3))}`;
            let promise8 = APIServices.get(Overall)
            let promise9 = APIServices.get(API.RF_User_UP(login_data.information.cid))
            let promise10 = APIServices.get(API.AssignDCFClient_UP(login_data.information.cid))
            Promise.all([promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8, promise9, promise10]).then(function (values) {

                setRawRF(values[3].data)
                setRFResponse(values[4].data)
                let locs = list

                setRFAss(values[8].data)
                locs.framework = values[5].data.filter((i) => { return client_info.information.report.includes(i.id) })
                setList(locs)
                setResponse(values[7].data)
                let categoryList = [], metricList = [], topicList = [], filter_dcf = []
                let required_rf = [], loc = list
                values[7].data.forEach((cat) => {
                    if (cat.newTopics) {
                        cat.newTopics.forEach((topic) => {
                            if (topic.newMetrics) {
                                topic.newMetrics.forEach((metric) => {
                                    if (Array.isArray(metric.data1) && metric.data1[0] !== undefined && metric.data1[0].type === 1 && values[3].data.map(i => i.id).includes(metric.data1[0].rf)) {
                                        if (values[8].data.findIndex(i => i.rfid === metric.data1[0].rf) !== -1 && required_rf.findIndex(j => j.data1[0].rf === metric.data1[0].rf) === -1) {
                                            required_rf.push({ cat_id: cat.id, top_id: topic.id, overallTags: [metric.data1[0].tags1, metric.data1[0].tags2, metric.data1[0].tags3], cat_title: cat.title, top_title: topic.title, title: metric.title, ...metric })
                                        }

                                    }
                                })
                            }
                        }
                        )
                    }
                })
                console.log(required_rf)
                if (required_rf.length !== 0) {
                    loc.metric = required_rf
                }

                if (values[9].data.length !== 0) {
                    metricList.forEach((j) => {
                        if (values[9].data[0].metric_ids.includes(j.id)) {
                            if (j.newDataPoints) {
                                j.newDataPoints.forEach((k) => {
                                    if (Array.isArray(k.data1) && k.data1[0].datasource && typeof k.data1[0].datasource === 'number') {
                                        let dcfindex = values[0].data.findIndex((m) => { return m.id === k.data1[0].datasource })
                                        let dcfindex2 = filter_dcf.findIndex((m) => { return m.id === k.data1[0].datasource })

                                        if (dcfindex !== -1 && dcfindex2 === -1) {
                                            filter_dcf.push(values[0].data[dcfindex])
                                        }
                                    }
                                })
                            }
                        }

                    })
                    if (values[9].data[0].cf_ids !== null && values[9].data[0].cf_ids.length !== 0) {
                        values[9].data[0].cf_ids.forEach((id) => {
                            let dcfindex = values[0].data.findIndex((m) => { return m.id === id })
                            if (dcfindex !== -1) {
                                filter_dcf.push(values[0].data[dcfindex])
                            }
                        })
                    }

                }
                setFilterDCF(filter_dcf)
                setList(loc)
                setOverallMetric(JSON.parse(JSON.stringify(metricList)))





                dcf_list = values[0].data; setDcfList(values[0].data)

                dcf_submitted = values[1].data;
                locloc = values[2].data
                setSubmittedDCF((prev) => ([...prev, ...values[1].data]))
                forceUpdate()

                APIServices.get(API.AssignDCFUser_UP(login_data.information.cid)).then((res) => {
                    setDCFAssign(JSON.parse(JSON.stringify(res.data)))
                    let filter = [], locationFilter = [0], rawData = [], pending = []
                    let show_ = { entry: res.data.filter((i) => { return i.type === 0 && i.user_id === login_data.id }).length === 0 ? false : true, approver: res.data.filter((i) => { return i.type === 0 && i.approver_id === login_data.id }).length === 0 ? false : true }


                    res.data.forEach((item) => {

                        if (item.type === 0 && item.approver_id === login_data.id && filter_dcf.findIndex(i => { return i.id === item.dcfId }) !== -1) {

                            if (dcf_list.filter((k) => { return k.id === item.dcfId }).length !== 0 && locloc.findIndex((k) => { return k.id === item.site[0] }) !== -1) {
                                if (dcf_submitted.filter((k) => { return k.dcf === item.dcfId && k.site === item.site[0] && (k.type === 1 || k.type === 2 || k.type === 3 || k.reject === 1) }).length !== 0) {
                                    if (pending.findIndex((k) => { return k.id === dcf_submitted.filter((k) => { return k.dcf === item.dcfId && k.site === item.site[0] && (k.type === 1 || k.type === 2 || k.type === 3 || k.reject === 1) })[0].id }) === -1) {
                                        pending.push(...dcf_submitted.filter((k) => { return k.dcf === item.dcfId && k.site === item.site[0] && (k.type === 1 || k.type === 2 || k.type === 3 || k.reject === 1) }))
                                    }
                                    if (locationFilter.findIndex((k) => { return k === item.site[0] }) === -1) {
                                        locationFilter.push(item.site[0])
                                    }
                                }
                            }
                        }

                        if (item.type === 0 && item.user_id === login_data.id && filter_dcf.findIndex(i => { return i.id === item.dcfId }) !== -1) {
                            console.log(item.user_id)
                            if (dcf_list.filter((k) => { return k.id === item.dcfId }).length !== 0 && locloc.findIndex((k) => { return k.id === item.site[0] }) !== -1) {

                                item.dcf_ = dcf_list.filter((k) => { return k.id === item.dcfId })[0]
                                item.frequency_ = frequency_list.filter((k) => { return k.id === item.frequency })[0]


                                filter.push(item)
                                if (locationFilter.findIndex((k) => { return k === item.site[0] }) === -1) {
                                    locationFilter.push(item.site[0])
                                }
                                console.log(DateTime.fromISO(item.start_date, { zone: 'utc' }), DateTime.local(), 'dcf')
                                let st_date = DateTime.fromISO(item.start_date, { zone: 'utc' }).toLocal(), ed_date = DateTime.utc().toLocal()
                                if (typeof item.end_date === 'string') {
                                    ed_date = DateTime.fromISO(item.end_date, { zone: 'utc' }).toLocal()
                                }


                                // getMonthsLuxon(st_date, ed_date, item.frequency, item, dcf_submitted)

                                rawData.push(item)
                            }

                        }
                    })
                    console.log(pending)

                    setPendingAP(pending)
                    setData(rawData)
                    APIServices.get(site_url).then((rest) => {
                        let site_list = [{ name: 'All', id: 0 }]
                        let site_list_all = []
                        rest.data.forEach((country) => {
                            if (country.locationTwos !== undefined) {
                                country.locationTwos.forEach((city) => {
                                    if (city.locationThrees !== undefined) {
                                        city.locationThrees.forEach((site) => {
                                            filter.forEach((item) => { if (site.id === item.site[0]) { item.site = site } })
                                            site_list.push({ name: site.name + ' (' + city.name + ')', id: site.id, country: { id: country.id, name: country.name }, city: { id: city.id, name: city.name } })
                                            site_list_all.push({ name: site.name + ' (' + city.name + ')', id: site.id, country: { id: country.id, name: country.name }, city: { id: city.id, name: city.name } })

                                        })
                                    }
                                })
                            }
                        })

                        setSiteList(site_list.filter((k) => { return locationFilter.includes(k.id) }))
                        setSelectedSite(parseFloat(id))
                    })

                })

            })
        }
    }, [client_info])
  
    const titleTemplate = (rowData) => {
        console.log(rowData)
        return (
            < >
                <Tooltip className="tag-tooltip" target={".tags" + rowData.id} position={'top'} autoHide={true}> {rowData.overallTags !== undefined && rowData.overallTags.map((i, j) => {
                    if (i.length !== 0) {
                        return (
                            <>
                                <label style={{ color: 'black', display: 'flex' }}> {
                                    j === 0 ? 'Must Have' : j === 1 ? 'Progressive' : 'Advanced'

                                }
                                </label>
                                {
                                    i.map((tag, k) => {

                                        return (
                                            <label style={{ color: 'green' }}>{tag}{k !== i.length - 1 && ','}</label>
                                        )

                                    })
                                }
                                <div style={{ marginBottom: 10 }} />
                            </>
                        )
                    }
                })} </Tooltip>
                <div style={{ alignItems: 'center' }} >{rowData.title} <i className={"material-icons " + "tags" + rowData.id} style={{ fontSize: 14, cursor: 'pointer' }}>info</i>  </div>

            </>
        )
    }
    const renderResponseTable = (id, category, topic, frameworks, resdata, listdata, frameList) => {

        let loc = selected, topicList = [], framework = [], categoryList = []
        let loclist = list
        loc['id'] = id;
        loc.category = category
        loc.topic = topic
        loc.framework = frameworks
        resdata.forEach((cat) => {
            if (cat.newTopics !== undefined) {
                categoryList.push(cat)
                if (loc.category.includes(cat.id)) {
                    cat.newTopics.forEach((topic) => {
                        topic.newMetrics !== undefined && topicList.push({ name: topic.title, title: topic.title + "(" + cat.title + ")", id: topic.id })
                        if (topic.newMetrics !== undefined && loc.topic.includes(topic.id)) {

                            topic.newMetrics.forEach((metric) => {
                                if (Array.isArray(metric.data1) && metric.data1.length !== 0) {
                                    if (Object.keys(metric.data1[0]).length > 2) {
                                        if (metric.data1[0].type === 1 && metric.data1[0].rf !== null) {
                                            console.log(metric.id, 640)
                                            // let index = framework.findIndex((k) => { return k.cat_id === cat.id && k.top_id === topic.id })
                                            // if (index !== -1) {
                                            //     framework[index].metric.push(metric)
                                            // } else {
                                            let tag = []
                                            let tags = JSON.parse(JSON.stringify(loc.framework)).map((fw) => { return listdata.framework.filter((k) => { return k.id === fw })[0].title.trim().toLowerCase() })
                                            let frame = JSON.parse(JSON.stringify(loc.framework)).map((fw) => { return listdata.framework.filter((k) => { return k.id === fw })[0] })


                                            let one = metric.data1[0].tags1.some((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())))
                                            let two = metric.data1[0].tags2.some((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())))
                                            let three = metric.data1[0].tags3.some((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())))
                                            const matchedTag1 = metric.data1[0].tags1.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                                            const matchedTag2 = metric.data1[0].tags2.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));
                                            const matchedTag3 = metric.data1[0].tags3.filter((str) => tags.some((fruit) => str.toLowerCase().includes(fruit.toLowerCase())));

                                            frame.forEach((t) => {
                                                matchedTag1.forEach((i) => {

                                                    if (i.trim().toLowerCase().includes(t.title.trim().toLowerCase())) {
                                                        !tag.includes(t.id) && tag.push(t.id)
                                                    }


                                                })
                                                matchedTag2.forEach((i) => {

                                                    if (i.trim().toLowerCase().includes(t.title.trim().toLowerCase())) {
                                                        !tag.includes(t.id) && tag.push(t.id)
                                                    }


                                                })
                                                matchedTag3.forEach((i) => {

                                                    if (i.trim().toLowerCase().includes(t.title.trim().toLowerCase())) {
                                                        !tag.includes(t.id) && tag.push(t.id)
                                                    }


                                                })
                                            })
                                            console.log(matchedTag1, matchedTag2, matchedTag3, tag)
                                            if (one || two || three) {
                                                framework.push({ tags: tag, overallTags: [metric.data1[0].tags1, metric.data1[0].tags2, metric.data1[0].tags3], cat_id: cat.id, top_id: topic.id, cat_title: cat.title, top_title: topic.title, ...metric })
                                            }
                                            // }

                                        }
                                    }
                                }

                            })
                        }
                    })
                }
            }


        })
        loclist.metric = framework
        loclist.category = categoryList
        loclist.topic = topicList
        loc.category = categoryList.filter((i) => { return loc.category.includes(i.id) }).map((k) => { return k.id })
        loc['topic'] = topicList.filter((i) => { return loc.topic.includes(i.id) }).map((k) => { return k.id })
        loc.framework = frameList.filter((i) => { return loc.framework.includes(i.id) }).map((k) => { return k.id })
        console.log(loc)
        setSelected(loc)

        setList(loclist)
        forceUpdate()
    }
    const lastResponse = (rowData) => {

        let text = 'Not Responded'
        let data = rowData.data1[0]

        let index = rfresponse.findLastIndex((i) => { return i.rfid === data.rf && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId })
        if (index !== -1) {
            text = moment.utc(rfresponse[index].created_on).format('DD MMMM YYYY')

        }
        return (
            <>
                {text}
            </>
        )
    }
    const rrTemplate = (rowData) => {
        let data = rowData.data1[0], oldData = [], id = 0, show = true

        let index = rfresponse.findLastIndex((i) => { return i.rfid === data.rf && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId })
        if (index !== -1) {
            oldData = rfresponse[index]

        }

        return (
            < >
                <a style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => { navigate.push({ pathname: '/rf_input_entry/' + rowData.data1[0].rf, state: { data: rowData, oldData } }) }}>       {rowData.data1[0].title} </a>

            </>
        )
    }
    const checkResponsibility = (rfid) => {

        return rfass.findIndex((k) => { return k.reporter_ids !== null &&  k.reporter_ids.includes(login_data.id)  && k.rfid === rfid }) === -1 ? false : true
    }
    const historyTemplate = (rowData) => {
        let text = true
        let data = rowData.data1[0]
        let mergeData = []
        console.log(rowData)
        let index = rfresponse.findLastIndex((i) => { return i.rfid === data.rf && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId })
        if (index !== -1) {
            text = false
            mergeData = rfresponse.filter((i) => { return i.rfid === data.rf && rowData.top_id === i.topicId && rowData.id === i.indicatorId && rowData.cat_id === i.categoryId })
            mergeData.forEach((i) => {
                i.top_title = rowData.top_title
                i.cat_title = rowData.cat_title
            })
        }
        return (
            <>
                {text ?
                    <span>NA</span> :
                    <a onClick={() => { setHistoryData(mergeData); activeRowData = rowData; setHistoryDialog(true) }}>View History</a>
                }

            </>
        )
    }
    return (
        <div className="bg-smoke font-lato" >
            <div className="col-12">
                <div className="p-3" id='divsize'>
                    <div className="sticky-div">
                        <div className="m-1 fw-7 fs-20 flex justify-content-center mt-2">
                            Qualitative Submissions  ({list.metric.filter((i) => { return i.data1 !== null && checkResponsibility(i.data1[0].rf, i) }).length})
                        </div>
                  
                    </div>

                    {selectedsite !== null ? <div >
                        <div >

                                <DataTable className="user-data-table-height fw-4 fs-16" value={list.metric.filter((i) => { return i.data1 !== null && checkResponsibility(i.data1[0].rf, i) })}
                                    paginator
                                    rows={20}
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    scrollable
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                                    // globalFilterFields={["month", "location", "city", "country"]}
                                    // globalFilter={filter}
                                    emptyMessage="No records found."
                                    // header={header}
                                    responsiveLayout="scroll"
                                >
                                    <Column field='cat_title' header='Category' />
                                    <Column field='top_title' header='Topic' />
                                    <Column field='title' body={titleTemplate} header='Aspect' />
                                    <Column body={rrTemplate} header='Requirement' />

                                    <Column body={historyTemplate} header='history' />
                                    <Column body={lastResponse} header='Last Response Date' />

                                </DataTable>

                                <Dialog
                                    visible={historydialog}
                                    style={{
                                        width: "30%",
                                    }}
                                    header={"Response History"}
                                    modal
                                    className="p-fluid"

                                    onHide={() => { setHistoryDialog(false) }}
                                >
                                    <div>
                                        {historydata.sort((a, b) => { return moment(b.created_on).toDate() - moment(a.created_on).toDate() }).map((i) => {
                                            return (
                                                <div style={{ flexDirection: 'column', alignItems: 'center', display: 'flex', padding: 5, borderRadius: 10, margin: 5, boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px' }}>

                                                    <text style={{ color: 'black', borderRadius: 10, padding: 5, fontStyle: 'italic' }} onClick={() => { navigate.push({ pathname: '/rf_submission_preview/' + i.rfid, state: { data: activeRowData, oldData: i } }) }}>{moment(i.created_on).local().format('DD MMM YYYY, hh:mm A')} by <span style={{ color: 'green' }}>{i.submitted_by === login_data.id ? 'you' : i.submitted_by === client_info.id ? 'Admin' : !userList.findIndex((j) => { return j.id === i.submitted_by }) === -1 ? 'User Deleted' : userList.find((j) => { return j.id === i.submitted_by }).information.empname}</span> </text>

                                                </div>
                                            )
                                        })

                                        }
                                    </div>
                                </Dialog>
                            </div>
                       


                    </div>
                        :
                        <label style={{
                            justifyContent: 'center',
                            display: 'flex',
                            marginTop: '50px'
                        }}>
                            {selectedsite === null ? <i className="pi pi-spin pi-spinner" style={{ fontSize: 50 }}></i> :
                                'No Qualitative Reponse found'
                            }
                        </label>}
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(QualitativeHistoric, comparisonFn);
