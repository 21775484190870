
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";

import { API } from "../../components/constants/api_url";
import { DateTime } from "luxon";
const initialState = {
    // profile:{id:'12',name:'Venkat',mobno:'0000000'}
    userdetail: {},
    fyStartMonth: { fymonth: 1 },
    admindetail: {
        "id": 94,
        "company": null,
        "information": {
            "access": {
                "navigos": true,
                "materiality": false
            },
            "currency": "SGD",
            "startdate": "2019-12-31T18:30:00.000Z",
            "companyname": "ST Telemedia GDC",
            "companycontact": "",
            "contactpersonmail": "",
            "website": "http://www.sttelemedia.com/",
            "contactperson": "Aheli",
            "contactpersonno": "",
            "companyaddress": "1 Temasek Avenue, #33-01 Millenia Tower, Singapore 039192",
            "config": [],
            "companylogo": "https://api.acuizen.com/docs/1701744066944STTESG.png",
            "report": [
                2
            ],
            "enterpriseid": "STT",
            "escalation_mail_ids": [],
            "user_remainder_mail_date": 15,
            "user_escalation_mail_date": 20,
            "others_escalation_mail_date": 25,
            "reviewer_remainder_mail_date": 15,
            "reviewer_escalation_mail_date": 20,
            "approver_remainder_mail_date": 15,
            "approver_escalation_mail_date": 20,
            "empname": "Enterprise Admin",
            "cid": 94
        },
        "role": "clientadmin",
        "active": true,
        "userId": "ad24c5b0-0060-4233-b4d1-db1d7bf6ed58",
        "clientId": 94,
        "access": {
            "navigos": true,
            "materiality": false
        },
        "fyStartMonth": null,
        "tierLabel": null,
        "email": "shreyasi@eisqr.com",
        "admin": {
            "id": 94,
            "company": null,
            "information": {
                "access": {
                    "navigos": true,
                    "materiality": false
                },
                "currency": "SGD",
                "startdate": "2019-12-31T18:30:00.000Z",
                "companyname": "ST Telemedia GDC",
                "companycontact": "",
                "contactpersonmail": "",
                "website": "http://www.sttelemedia.com/",
                "contactperson": "Aheli",
                "contactpersonno": "",
                "companyaddress": "1 Temasek Avenue, #33-01 Millenia Tower, Singapore 039192",
                "config": [],
                "companylogo": "https://api.acuizen.com/docs/1701744066944STTESG.png",
                "report": [
                    2
                ],
                "enterpriseid": "STT",
                "escalation_mail_ids": [],
                "user_remainder_mail_date": 15,
                "user_escalation_mail_date": 20,
                "others_escalation_mail_date": 25,
                "reviewer_remainder_mail_date": 15,
                "reviewer_escalation_mail_date": 20,
                "approver_remainder_mail_date": 15,
                "approver_escalation_mail_date": 20,
                "empname": "Enterprise Admin"
            },
            "role": "clientadmin",
            "active": true,
            "userId": "ad24c5b0-0060-4233-b4d1-db1d7bf6ed58",
            "clientId": null,
            "access": {
                "navigos": true,
                "materiality": false
            },
            "fyStartMonth": null,
            "tierLabel": null
        }
    },
    allRoles: [],
    config: [],
    tierLabel: []
}
export const fetchApi = createAsyncThunk('api/fetchApi', async ({ token, id_token }) => {
    return axios.get(API.GetMeNew, {
        headers: {
            "Authorization": "Bearer " + token,
            "x-id-token": id_token
        }
    }).then((res) => res.data)
})


const setData = (state, action) => {

    if (action.payload.role === 'clientuser' || action.payload.role === 'clientsupplier' || action.payload.role === 'clientadmin') {
        if (action.payload.information.blocked === undefined || !action.payload.information.blocked) {
            let data = action.payload
            if (data.role === 'clientadmin') {
                data.information['cid'] = data.id
                data.clientId = data.id

                state.admindetail = { ...data }
                state.tierLabel = data.tierLabel ? data.tierLabel : ['Country', 'City', 'Site']


            } else {
                let accept_roles = [1, 3, 4]
                let roles = data.roles_data.flatMap(i => i.roles).some(i => accept_roles.includes(i))
                if (roles) {
                    let client_data = data.admin ? data.admin : { tierLabel: ['Country', 'City', 'Site'] }
                    state.tierLabel = client_data.tierLabel ? client_data.tierLabel : ['Country', 'City', 'Site']
                    state.allRoles = Array.from(new Set(data.roles_data.flatMap(i => i.roles)))

                    state.admindetail = data.admin ? data.admin : null
                } else {
                    localStorage.removeItem("token");
                    Swal.fire({
                        icon: 'warning',
                        title: 'Alert',
                        text: 'Seems you are not have admin access, contact admin for futher details',
                        returnInputValueOnDeny: () => {
                            console.log('deny')
                        }
                    })
                }
            }
            state.userdetail = data;

        } else {
            localStorage.removeItem("token");
            Swal.fire({
                icon: 'error',
                title: 'Blocked',
                text: 'You have been blocked from platform, contact admin for futher details',
                returnInputValueOnDeny: () => {
                    console.log('deny')
                }
            })

        }
    } else {
        localStorage.removeItem("token");
        Swal.fire({
            icon: 'error',
            title: 'Access Denied',
            text: 'You have no access to this platform, contact admin for futher details',
            returnInputValueOnDeny: () => {
                console.log('deny')
            }
        })
    }


}

const userProfile = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoggedUserDetail: (state, action) => { state.userdetail = action.payload },
        resetLoggedUserDetail: (state) => {
            localStorage.clear();
            sessionStorage.clear(); state.userdetail = {}
        },

    },
    extraReducers: (builder) => {

        builder.addCase(fetchApi.fulfilled, (state, action) => { setData(state, action) })

    }



})

export default userProfile.reducer
export const { setLoggedUserDetail, resetLoggedUserDetail } = userProfile.actions
