import { DateTime } from "luxon";
import moment from "moment";

const importData = async (dcfId, items, rawsitelist, stdlist, login_data, refineddata, quantitativesubmission) => {
    let promises = [], rejected = [], local2 = [], errorArray = []
    if (dcfId === 304) {
        let promise = new Promise(async (res, rej) => {
            try {

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                if (!items['Category of Fuel Used'] || !items['Category of Fuel Used'].toString().trim().length) {
                    errorArray.push('Category of Fuel Used Field is Required')
                }

                if (!items['Fuel Used'] || !items['Fuel Used'].toString().trim().length) {
                    errorArray.push('Fuel Used Field is Required')
                }

                if (!items['Unit of Measurement'] || !items['Unit of Measurement'].toString().trim().length) {
                    errorArray.push('Unit of Measurement Field is Required')
                }
                if ((!items['Quantity of Fuel Consumed'] && items['Quantity of Fuel Consumed'] !== 0) || !items['Quantity of Fuel Consumed'].toString().trim().length) {
                    errorArray.push('Quantity of Fuel Consumed Field is Required')
                } else if (items['Quantity of Fuel Consumed'].toString().trim().length) {
                    console.log(items['Quantity of Fuel Consumed'], parseFloat(items['Quantity of Fuel Consumed'].toString()).toString().trim().length, items['Quantity of Fuel Consumed'].toString().trim().length)
                    if (items['Quantity of Fuel Consumed'] !== null &&
                        items['Quantity of Fuel Consumed'] !== undefined && typeof items['Quantity of Fuel Consumed'] !== 'number' &&
                        parseFloat(items['Quantity of Fuel Consumed'].toString()) >= 0 && (parseFloat(items['Quantity of Fuel Consumed'].toString()).toString().trim().length === items['Quantity of Fuel Consumed'].toString().trim().length)
                    ) {
                        errorArray.push('Quantity of Fuel Consumed Field Accepts Only valid Number Input as Either Rounded or Fraction value (Dont use , or other notation)')
                    } else if (!parseFloat(items['Quantity of Fuel Consumed'].toString().trim()) && parseFloat(items['Quantity of Fuel Consumed'].toString().trim()) !== 0) {
                        errorArray.push('Quantity of Fuel Consumed Field Requires Either Rounded or Fraction value')
                    }
                }
                if (items['Entity'] && items['Entity'].toString().trim().length && items['Entity Level'] && items['Entity Level'].toString().trim().length && items['Reporting Period'] && items['Reporting Period'].toString().trim().length && validateDateString(items['Reporting Period']) && items['Fuel Used'] && items['Fuel Used'].toString().trim().length && items['Unit of Measurement'] && items['Unit of Measurement'].toString().trim().length && items['Category of Fuel Used'] && items['Category of Fuel Used'].toString().trim().length) {
                    let level = items['Entity Level'];

                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);
                    const { found, assObj } = await getAssignment(items['Reporting Period'], obj, errorArray, refineddata).then((assObj) => { return assObj })
                    console.log(found, 'found')
                    if (found) {
                        let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                            (i) => i.id === assObj[0].standard
                        );
                        if (stdindex !== -1) {
                            if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 1);
                                let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;

                                const { result, id } = findMatchingIds(
                                    category,
                                    items['Category of Fuel Used'].toString(),
                                    items['Fuel Used'].toString(),
                                    items['Unit of Measurement'].toString()
                                );
                                if (!result) {
                                    errorArray.push('Mismatch in Combination Category of Fuel Used & Fuel Used & Unit of Measurement')

                                }

                            }
                        } else {
                            errorArray.push('Inform Admin to check selected Standard for this Enity on this Form ')

                        }
                    } else if (!found && assObj[0].id) {
                        let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                        let refObjData = assObj.filter(i => i.refobj)
                        let refObj = refObjData[0].refobj
                        let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                            (i) => i.id === refObj.standard
                        );
                        if (stdindex !== -1) {
                            if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 1);
                                let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;

                                const { result, id } = findMatchingIds(
                                    category,
                                    items['Category of Fuel Used'].toString(),
                                    items['Fuel Used'].toString(),
                                    items['Unit of Measurement'].toString()
                                );
                                if (!result) {
                                    errorArray.push('Mismatch in Combination Category of Fuel Used & Fuel Used & Unit of Measurement')

                                }

                            }
                        } else {
                            errorArray.push('Inform Admin to check selected Standard for this Enity on this Form ')

                        }




                    }
                }

                let local = {};
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;
                            newObj['standard'] = assObj[0].standard;
                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (newObj['standard']) {
                                    let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                                        (i) => i.id === newObj['standard']
                                    );

                                    if (stdindex !== -1) {
                                        if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                            let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 1);
                                            let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;

                                            if (cat_index !== -1) {
                                                let index = -1;
                                                if (items['Category of Fuel Used']) {
                                                    index = category.findIndex(
                                                        (i) => i.title.trim().toLowerCase() === items['Category of Fuel Used'].toString().trim().toLowerCase()
                                                    );
                                                }
                                                if (
                                                    items['Category of Fuel Used'] &&
                                                    items['Category of Fuel Used'].toString().trim().length !== 0 &&
                                                    items['Fuel Used'] !== null &&
                                                    items['Fuel Used'].trim().length !== 0 &&
                                                    items['Unit of Measurement'] &&
                                                    items['Unit of Measurement'].toString().trim().length !== 0 &&
                                                    (index !== -1) &&
                                                    items['Quantity of Fuel Consumed'] !== null &&
                                                    items['Quantity of Fuel Consumed'] !== undefined &&
                                                    parseFloat(items['Quantity of Fuel Consumed'].toString()) >= 0 && (parseFloat(items['Quantity of Fuel Consumed'].toString()).toString().trim().length === items['Quantity of Fuel Consumed'].toString().trim().length)

                                                ) {
                                                    const { result, id } = findMatchingIds(
                                                        category,
                                                        items['Category of Fuel Used'].toString(),
                                                        items['Fuel Used'].toString(),
                                                        items['Unit of Measurement'].toString()
                                                    );
                                                    if (result) {
                                                        local["DPA0130"] = id[0];
                                                        local["DPA0131"] = id[1];
                                                        local["DPA0132"] = id[2];
                                                        local["id"] = getID();
                                                        local["attachment"] = null;
                                                        local["DPA0336"] = parseFloat(items['Quantity of Fuel Consumed'].toString());
                                                        local["DPA0133"] = "";
                                                        local["DPA0134"] = "";
                                                        local['DPA0131B'] = 2
                                                        local["remarks"] = items['Remarks']
                                                        local['created_on'] = DateTime.utc()
                                                        local['created_by'] = login_data.id
                                                        if (local['remarks']) {


                                                            local['remarks_created_on'] = DateTime.utc()
                                                            local['remarks_created_by'] = login_data.id
                                                        }

                                                        newObj['response'] = [local];
                                                        newObj['excel_data'] = [items]
                                                        newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                                        local2.push(newObj);
                                                        res(newObj); // Resolve this promise with the newObj
                                                    } else {
                                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                        res(rejected)

                                                    }
                                                } else {

                                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                    res(rejected)
                                                }
                                            } else {
                                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                res(rejected) // Resolve even if not matching
                                            }
                                        } else {
                                            rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                            res(rejected) // Resolve even if not matching
                                        }
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected) // Resolve even if not matching
                                    }
                                }
                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Category of Fuel Used'] && !items['Fuel Used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else {
                            console.log(assObj)
                            if (!found && assObj[0].id) {
                                let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                                let refObjData = assObj.filter(i => i.refobj)
                                if (oldIndex && refObjData.length !== 0) {
                                    let refObj = refObjData[0].refobj
                                    console.log(refObjData[0])
                                    newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                    newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                    newObj['rp'] = items['Reporting Period'];
                                    newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                    newObj['form_type'] = 1
                                    newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                    newObj['logs'] = [];
                                    newObj['submitted_by'] = login_data.id;
                                    newObj['submitted_on'] = dt;
                                    newObj['last_modified_on'] = dt;
                                    newObj['reporter_modified_on'] = dt;
                                    newObj['reporter_modified_by'] = login_data.id;
                                    newObj['last_modified_by'] = login_data.id;
                                    newObj['entityUserAssId'] = refObj.id;
                                    newObj['entityAssId'] = refObj.entityAssId;
                                    newObj['standard'] = refObj.standard;
                                    newObj['dcfId'] = refineddata[0].dcfId
                                    if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {

                                        if (newObj['standard']) {
                                            let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                                                (i) => i.id === newObj['standard']
                                            );

                                            if (stdindex !== -1) {
                                                console.log('1')
                                                if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                                    let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 1);
                                                    let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;

                                                    if (cat_index !== -1) {
                                                        console.log('2')
                                                        let index = -1;
                                                        if (items['Category of Fuel Used']) {
                                                            index = category.findIndex(
                                                                (i) => i.title.trim().toLowerCase() === items['Category of Fuel Used'].toString().trim().toLowerCase()
                                                            );
                                                        }
                                                        if (
                                                            items['Category of Fuel Used'] &&
                                                            items['Category of Fuel Used'].toString().trim().length !== 0 &&
                                                            items['Fuel Used'] &&
                                                            items['Fuel Used'].trim().length !== 0 &&
                                                            items['Unit of Measurement'] &&
                                                            items['Unit of Measurement'].toString().trim().length !== 0 &&
                                                            (index !== -1) &&
                                                            items['Quantity of Fuel Consumed'] !== null &&
                                                            items['Quantity of Fuel Consumed'] !== undefined &&
                                                            parseFloat(items['Quantity of Fuel Consumed'].toString()) >= 0 && (parseFloat(items['Quantity of Fuel Consumed'].toString()).toString().trim().length === items['Quantity of Fuel Consumed'].toString().trim().length)
                                                        ) {
                                                            console.log('3')
                                                            const { result, id } = findMatchingIds(
                                                                category,
                                                                items['Category of Fuel Used'].toString(),
                                                                items['Fuel Used'].toString(),
                                                                items['Unit of Measurement'].toString()
                                                            );
                                                            if (result) {

                                                                local["DPA0130"] = id[0];
                                                                local["DPA0131"] = id[1];
                                                                local["DPA0132"] = id[2];
                                                                local["id"] = getID();
                                                                local["remarks"] = items['Remarks']
                                                                local["attachment"] = null;
                                                                local["DPA0336"] = parseFloat(items['Quantity of Fuel Consumed'].toString());
                                                                local["DPA0133"] = "";
                                                                local["DPA0134"] = "";
                                                                local['DPA0131B'] = 2
                                                                local['created_on'] = DateTime.utc()
                                                                local['created_by'] = login_data.id
                                                                if (local['remarks']) {


                                                                    local['remarks_created_on'] = DateTime.utc()
                                                                    local['remarks_created_by'] = login_data.id
                                                                }
                                                                newObj['id'] = oldIndex.id
                                                                newObj['response'] = [local];
                                                                newObj['excel_data'] = [items]
                                                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                                                local2.push(newObj);
                                                                res(newObj); // Resolve this promise with the newObj
                                                            } else {

                                                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                                res(rejected)
                                                            }
                                                        } else {

                                                            rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                            res(rejected)
                                                        }
                                                    } else {
                                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                        res(rejected) // Resolve even if not matching
                                                    }
                                                } else {
                                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                    res(rejected) // Resolve even if not matching
                                                }
                                            } else {
                                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                res(rejected) // Resolve even if not matching
                                            }
                                        }
                                    } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Category of Fuel Used'] && !items['Fuel Used'])) {
                                        newObj['edit'] = 1
                                        newObj['id'] = oldIndex.id
                                        newObj['response'] = []
                                        newObj['excel_data'] = [items]
                                        newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                        res(newObj);

                                        local2.push(newObj);
                                    } else {

                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                    res(rejected)
                                }
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }

                        }
                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise



    } else if (dcfId === 305) {
        let promise = new Promise(async (res, rej) => {
            try {

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                if (!items['Type of Gas Refilled'] || !items['Type of Gas Refilled'].toString().trim().length) {
                    errorArray.push('Type of Gas Refilled Field is Required')
                }

                if (!items['Type of AC/ Chiller / Freezer used'] || !items['Type of AC/ Chiller / Freezer used'].toString().trim().length) {
                    errorArray.push('Type of AC/ Chiller / Freezer used Field is Required')
                }


                if ((!items['Gas Refilled (kg)'] && items['Gas Refilled (kg)'] !== 0) || !items['Gas Refilled (kg)'].toString().trim().length) {
                    errorArray.push('Gas Refilled (kg) Field is Required')
                } else if (items['Gas Refilled (kg)'].toString().trim().length) {
                    console.log(items['Gas Refilled (kg)'], parseFloat(items['Gas Refilled (kg)'].toString()).toString().trim().length, items['Gas Refilled (kg)'].toString().trim().length)
                    if (items['Gas Refilled (kg)'] !== null &&
                        items['Gas Refilled (kg)'] !== undefined &&
                        parseFloat(items['Gas Refilled (kg)'].toString()) >= 0 && (parseFloat(items['Gas Refilled (kg)'].toString()).toString().trim().length === items['Gas Refilled (kg)'].toString().trim().length)
                    ) {
                        errorArray.push('Gas Refilled (kg) Field Accepts Only valid Number Input as Either Rounded or Fraction value (Dont use , or other notation)')
                    } else if (!parseFloat(items['Gas Refilled (kg)'].toString().trim()) && parseFloat(items['Gas Refilled (kg)'].toString().trim()) !== 0) {
                        errorArray.push('Gas Refilled (kg) Field Requires Either Rounded or Fraction value')
                    }
                }
                if (items['Entity'] && items['Entity'].toString().trim().length && items['Entity Level'] && items['Entity Level'].toString().trim().length && items['Reporting Period'] && items['Reporting Period'].toString().trim().length && validateDateString(items['Reporting Period']) && items['Type of AC/ Chiller / Freezer used'] && items['Type of AC/ Chiller / Freezer used'].toString().trim().length && items['Type of Gas Refilled'] && items['Type of Gas Refilled'].toString().trim().length) {
                    let level = items['Entity Level'];

                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);
                    const { found, assObj } = await getAssignment(items['Reporting Period'], obj, errorArray, refineddata).then((assObj) => { return assObj })
                    console.log(found, 'found')
                    if (found) {
                        let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                            (i) => i.id === assObj[0].standard
                        );
                        if (stdindex !== -1) {
                            if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 5);
                                let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;

                                const { result, id } = findMatchingIds(
                                    category,
                                    items['Type of Gas Refilled'].toString()
                                );
                                if (!result) {
                                    errorArray.push('Type of Gas Refilled is not found')

                                }

                            }
                        } else {
                            errorArray.push('Inform Admin to check selected Standard for this Enity on this Form ')

                        }
                    } else if (!found && assObj[0].id) {
                        let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                        let refObjData = assObj.filter(i => i.refobj)
                        let refObj = refObjData[0].refobj
                        let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                            (i) => i.id === refObj.standard
                        );
                        if (stdindex !== -1) {
                            if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 5);
                                let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;

                                const { result, id } = findMatchingIds(
                                    category,
                                    items['Type of Gas Refilled'].toString()

                                );
                                if (!result) {
                                    errorArray.push('Type of Gas Refilled is not found')

                                }

                            }
                        } else {
                            errorArray.push('Inform Admin to check selected Standard for this Enity on this Form ')

                        }




                    }
                }

                let local = {};
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;
                            newObj['standard'] = assObj[0].standard;
                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (newObj['standard']) {
                                    let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                                        (i) => i.id === newObj['standard']
                                    );

                                    if (stdindex !== -1) {
                                        if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                            let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 5);
                                            let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;

                                            if (cat_index !== -1) {
                                                let index = -1;
                                                if (items['Type of Gas Refilled']) {
                                                    index = category.findIndex(
                                                        (i) => i.title.trim().toLowerCase() === items['Type of Gas Refilled'].toString().trim().toLowerCase()
                                                    );
                                                }
                                                if (
                                                    items['Type of Gas Refilled'] &&
                                                    items['Type of Gas Refilled'].toString().trim().length !== 0 &&
                                                    items['Type of AC/ Chiller / Freezer used'] !== null &&
                                                    items['Type of AC/ Chiller / Freezer used'].trim().length !== 0 &&

                                                    (index !== -1) &&
                                                    items['Gas Refilled (kg)'] !== null &&
                                                    items['Gas Refilled (kg)'] !== undefined &&
                                                    parseFloat(items['Gas Refilled (kg)'].toString()) >= 0 && (parseFloat(items['Gas Refilled (kg)'].toString()).toString().trim().length === items['Gas Refilled (kg)'].toString().trim().length)

                                                ) {
                                                    const { result, id } = findMatchingIds(
                                                        category,
                                                        items['Type of Gas Refilled'].toString()
                                                    );
                                                    if (result) {
                                                        local["DPA0136"] = id[0];
                                                        local["DPA0138"] = parseFloat(items['Gas Refilled (kg)'].toString())
                                                        if (items['Type of AC/Chiller/Freezer used'] && items['Type of AC/Chiller/Freezer used'].toString().trim().length) {
                                                            local["DPA0135"] = items['Type of AC/Chiller/Freezer used']
                                                        } else {
                                                            local["DPA0135"] = ''
                                                        }
                                                        local["attachment"] = null;
                                                        local["id"] = getID();
                                                        local["remarks"] = items['Remarks']

                                                        if (local['remarks']) {


                                                            local['remarks_created_on'] = DateTime.utc()
                                                            local['remarks_created_by'] = login_data.id
                                                        }

                                                        newObj['response'] = [local];
                                                        newObj['excel_data'] = [items]
                                                        newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                                        local2.push(newObj);
                                                        res(newObj); // Resolve this promise with the newObj
                                                    } else {
                                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                        res(rejected)

                                                    }
                                                } else {

                                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                    res(rejected)
                                                }
                                            } else {
                                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                res(rejected) // Resolve even if not matching
                                            }
                                        } else {
                                            rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                            res(rejected) // Resolve even if not matching
                                        }
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected) // Resolve even if not matching
                                    }
                                }
                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else {
                            console.log(assObj)
                            if (!found && assObj[0].id) {
                                let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                                let refObjData = assObj.filter(i => i.refobj)
                                if (oldIndex && refObjData.length !== 0) {
                                    let refObj = refObjData[0].refobj
                                    console.log(refObjData[0])
                                    newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                    newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                    newObj['rp'] = items['Reporting Period'];
                                    newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                    newObj['form_type'] = 1
                                    newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                    newObj['logs'] = [];
                                    newObj['submitted_by'] = login_data.id;
                                    newObj['submitted_on'] = dt;
                                    newObj['last_modified_on'] = dt;
                                    newObj['reporter_modified_on'] = dt;
                                    newObj['reporter_modified_by'] = login_data.id;
                                    newObj['last_modified_by'] = login_data.id;
                                    newObj['entityUserAssId'] = refObj.id;
                                    newObj['entityAssId'] = refObj.entityAssId;
                                    newObj['standard'] = refObj.standard;
                                    newObj['dcfId'] = refineddata[0].dcfId
                                    if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {

                                        if (newObj['standard']) {
                                            let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                                                (i) => i.id === newObj['standard']
                                            );

                                            if (stdindex !== -1) {
                                                console.log('1')
                                                if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                                    let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 5);
                                                    let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;

                                                    if (cat_index !== -1) {
                                                        console.log('2')
                                                        let index = -1;
                                                        if (items['Type of Gas Refilled']) {
                                                            index = category.findIndex(
                                                                (i) => i.title.trim().toLowerCase() === items['Type of Gas Refilled'].toString().trim().toLowerCase()
                                                            );
                                                        }
                                                        if (
                                                            items['Type of Gas Refilled'] &&
                                                            items['Type of Gas Refilled'].toString().trim().length !== 0 &&
                                                            items['Type of AC/ Chiller / Freezer used'] &&
                                                            items['Type of AC/ Chiller / Freezer used'].trim().length !== 0 &&

                                                            (index !== -1) &&
                                                            items['Gas Refilled (kg)'] !== null &&
                                                            items['Gas Refilled (kg)'] !== undefined &&
                                                            parseFloat(items['Gas Refilled (kg)'].toString()) >= 0 && (parseFloat(items['Gas Refilled (kg)'].toString()).toString().trim().length === items['Gas Refilled (kg)'].toString().trim().length)
                                                        ) {
                                                            console.log('3')
                                                            const { result, id } = findMatchingIds(
                                                                category,
                                                                items['Type of Gas Refilled'].toString()
                                                            );
                                                            if (result) {
                                                                local["id"] = getID();
                                                                local["DPA0136"] = id[0];
                                                                local["DPA0138"] = parseFloat(items['Gas Refilled (kg)'].toString())
                                                                if (items['Type of AC/Chiller/Freezer used'] && items['Type of AC/Chiller/Freezer used'].toString().trim().length) {
                                                                    local["DPA0135"] = items['Type of AC/Chiller/Freezer used']
                                                                } else {
                                                                    local["DPA0135"] = ''
                                                                }


                                                                local["remarks"] = items['Remarks']

                                                                local["attachment"] = null;

                                                                if (local['remarks']) {


                                                                    local['remarks_created_on'] = DateTime.utc()
                                                                    local['remarks_created_by'] = login_data.id
                                                                }
                                                                newObj['id'] = oldIndex.id
                                                                newObj['response'] = [local];
                                                                newObj['excel_data'] = [items]
                                                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                                                local2.push(newObj);
                                                                res(newObj); // Resolve this promise with the newObj
                                                            } else {

                                                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                                res(rejected)
                                                            }
                                                        } else {

                                                            rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                            res(rejected)
                                                        }
                                                    } else {
                                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                        res(rejected) // Resolve even if not matching
                                                    }
                                                } else {
                                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                    res(rejected) // Resolve even if not matching
                                                }
                                            } else {
                                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                res(rejected) // Resolve even if not matching
                                            }
                                        }
                                    } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                        newObj['edit'] = 1
                                        newObj['id'] = oldIndex.id
                                        newObj['response'] = []
                                        newObj['excel_data'] = [items]
                                        newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                        res(newObj);

                                        local2.push(newObj);
                                    } else {

                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                    res(rejected)
                                }
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }

                        }
                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise



    } else if (dcfId === 296) {
        let promise = new Promise(async (res, rej) => {
            try {

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                if (typeof items['Senior Management-Male'] !== 'number') {
                    errorArray.push('Senior Management-Male Field is Required')
                }
                if (typeof items['Senior Management-Female'] !== 'number') {
                    errorArray.push('Senior Management-Female Field is Required')
                }
                if (typeof items['Middle Management-Male'] !== 'number') {
                    errorArray.push('Middle Management-Male Field is Required')
                }
                if (typeof items['Middle Management-Female'] !== 'number') {
                    errorArray.push('Middle Management-Female Field is Required')
                }
                if (typeof items['Non-management-Male'] !== 'number') {
                    errorArray.push('Non-management-Male Field is Required')
                }
                if (typeof items['Non-management-Female'] !== 'number') {
                    errorArray.push('Non-management-Female Field is Required')
                }



                let local = {};
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;

                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (typeof items['Senior Management-Male'] === 'number' && typeof items['Senior Management-Female'] === 'number' && typeof items['Middle Management-Male'] === 'number'
                                    && typeof items['Middle Management-Female'] === 'number' && typeof items['Non-management-Male'] === 'number' && typeof items['Non-management-Female'] === 'number'
                                ) {
                                    local['DPR0138'] = items['Senior Management-Male'] ? items['Senior Management-Male'] : 0
                                    local['DPR0139'] = items['Senior Management-Female'] ? items['Senior Management-Female'] : 0
                                    local['DPR0140'] = items['Middle Management-Male'] ? items['Middle Management-Male'] : 0
                                    local['DPR0141'] = items['Middle Management-Female'] ? items['Middle Management-Female'] : 0
                                    local['DPKN074'] = items['Non-management-Male'] ? items['Non-management-Male'] : 0
                                    local['DPKN075'] = items['Non-management-Female'] ? items['Non-management-Female'] : 0
                                    local2.push(newObj);
                                    newObj['response'] = [local];
                                    newObj['excel_data'] = [items]
                                    res(newObj);
                                } else {
                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else if (!found && assObj[0].id) {
                            let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                            let refObjData = assObj.filter(i => i.refobj)
                            if (oldIndex && refObjData.length !== 0) {
                                let refObj = refObjData[0].refobj
                                console.log(refObjData[0])
                                newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                newObj['rp'] = items['Reporting Period'];
                                newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                newObj['form_type'] = 1
                                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                newObj['logs'] = [];
                                newObj['submitted_by'] = login_data.id;
                                newObj['submitted_on'] = dt;
                                newObj['last_modified_on'] = dt;
                                newObj['reporter_modified_on'] = dt;
                                newObj['reporter_modified_by'] = login_data.id;
                                newObj['last_modified_by'] = login_data.id;
                                newObj['entityUserAssId'] = refObj.id;
                                newObj['entityAssId'] = refObj.entityAssId;
                                newObj['standard'] = refObj.standard;
                                newObj['dcfId'] = refineddata[0].dcfId
                                if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                    if (typeof items['Senior Management-Male'] === 'number' && typeof items['Senior Management-Female'] === 'number' && typeof items['Middle Management-Male'] === 'number'
                                        && typeof items['Middle Management-Female'] === 'number' && typeof items['Non-management-Male'] === 'number' && typeof items['Non-management-Female'] === 'number'
                                    ) {
                                        local['DPR0138'] = items['Senior Management-Male'] ? items['Senior Management-Male'] : 0
                                        local['DPR0139'] = items['Senior Management-Female'] ? items['Senior Management-Female'] : 0
                                        local['DPR0140'] = items['Middle Management-Male'] ? items['Middle Management-Male'] : 0
                                        local['DPR0141'] = items['Middle Management-Female'] ? items['Middle Management-Female'] : 0
                                        local['DPKN074'] = items['Non-management-Male'] ? items['Non-management-Male'] : 0
                                        local['DPKN075'] = items['Non-management-Female'] ? items['Non-management-Female'] : 0
                                        newObj['id'] = oldIndex.id

                                        local2.push(newObj);
                                        newObj['response'] = [local];
                                        newObj['excel_data'] = [items]

                                        res(newObj);
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                    newObj['edit'] = 1
                                    newObj['id'] = oldIndex.id
                                    newObj['response'] = []
                                    newObj['excel_data'] = [items]
                                    newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                    res(newObj);

                                    local2.push(newObj);
                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }
                        } else {

                            rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                            res(rejected)
                        }

                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise



    } else if (dcfId === 284) {

        let promise = new Promise(async (res, rej) => {
            try {

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                let count = 0
                if (typeof items['New Hires Employees who are < 30 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('New Hires Employees who are < 30 years old (Male) Field is Required')
                }
                if (typeof items['New Hires Employees who are < 30 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('New Hires Employees who are < 30 years old (Female) Field is Required')
                }
                if (typeof items['New Hires Employees who are 30-50 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('New Hires Employees who are 30-50 years old (Female) Field is Required')
                }
                if (typeof items['New Hires Employees who are 30-50 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('New Hires Employees who are 30-50 years old (Male) Field is Required')
                }
                if (typeof items['New Hires Employees who are >50 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('New Hires Employees who are >50 years old (Male) Field is Required')
                }
                if (typeof items['New Hires Employees who are >50 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('New Hires Employees who are >50 years old (Female) Field is Required')
                }
                if (typeof items['Employee Turnover Employees who are < 30 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('Employee Turnover Employees who are < 30 years old (Male) Field is Required')
                }
                if (typeof items['Employee Turnover Employees who are < 30 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('Employee Turnover Employees who are < 30 years old (Female) Field is Required')
                }
                if (typeof items['Employee Turnover Employees who are 30-50 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('Employee Turnover Employees who are 30-50 years old (Female) Field is Required')
                }
                if (typeof items['Employee Turnover Employees who are 30-50 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('Employee Turnover Employees who are 30-50 years old (Male) Field is Required')
                }
                if (typeof items['Employee Turnover Employees who are >50 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('Employee Turnover Employees who are >50 years old (Male) Field is Required')
                }
                if (typeof items['Employee Turnover Employees who are >50 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('Employee Turnover Employees who are >50 years old (Female) Field is Required')
                }


                let local = {};
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;

                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (count === 0) {
                                    local['DPAN641'] = items['New Hires Employees who are < 30 years old (Male)'] ? items['New Hires Employees who are < 30 years old (Male)'] : 0
                                    local['DPAN642'] = items['New Hires Employees who are 30-50 years old (Male)'] ? items['New Hires Employees who are 30-50 years old (Male)'] : 0
                                    local['DPAN643'] = items['New Hires Employees who are >50 years old (Male)'] ? items['New Hires Employees who are >50 years old (Male)'] : 0
                                    local['DPAN644'] = items['New Hires Employees who are < 30 years old (Female)'] ? items['New Hires Employees who are < 30 years old (Female)'] : 0
                                    local['DPAN645'] = items['New Hires Employees who are 30-50 years old (Female)'] ? items['New Hires Employees who are 30-50 years old (Female)'] : 0
                                    local['DPAN646'] = items['New Hires Employees who are >50 years old (Female)'] ? items['New Hires Employees who are >50 years old (Female)'] : 0


                                    local['DPAN672'] = items['Employee Turnover Employees who are < 30 years old (Male)'] ? items['Employee Turnover Employees who are < 30 years old (Male)'] : 0
                                    local['DPAN673'] = items['Employee Turnover Employees who are 30-50 years old (Male)'] ? items['Employee Turnover Employees who are 30-50 years old (Male)'] : 0
                                    local['DPAN674'] = items['Employee Turnover Employees who are >50 years old (Male)'] ? items['Employee Turnover Employees who are >50 years old (Male)'] : 0
                                    local['DPAN675'] = items['Employee Turnover Employees who are < 30 years old (Female)'] ? items['Employee Turnover Employees who are < 30 years old (Female)'] : 0
                                    local['DPAN676'] = items['Employee Turnover Employees who are 30-50 years old (Female)'] ? items['Employee Turnover Employees who are 30-50 years old (Female)'] : 0
                                    local['DPAN677'] = items['Employee Turnover Employees who are >50 years old (Female)'] ? items['Employee Turnover Employees who are >50 years old (Female)'] : 0


                                    local2.push(newObj);
                                    newObj['response'] = [local];
                                    newObj['excel_data'] = [items]

                                    res(newObj);
                                } else {
                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else if (!found && assObj[0].id) {
                            let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                            let refObjData = assObj.filter(i => i.refobj)
                            if (oldIndex && refObjData.length !== 0) {
                                let refObj = refObjData[0].refobj
                                console.log(refObjData[0])
                                newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                newObj['rp'] = items['Reporting Period'];
                                newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                newObj['form_type'] = 1
                                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                newObj['logs'] = [];
                                newObj['submitted_by'] = login_data.id;
                                newObj['submitted_on'] = dt;
                                newObj['last_modified_on'] = dt;
                                newObj['reporter_modified_on'] = dt;
                                newObj['reporter_modified_by'] = login_data.id;
                                newObj['last_modified_by'] = login_data.id;
                                newObj['entityUserAssId'] = refObj.id;
                                newObj['entityAssId'] = refObj.entityAssId;
                                newObj['standard'] = refObj.standard;
                                newObj['dcfId'] = refineddata[0].dcfId
                                if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                    if (count === 0) {
                                        local['DPAN641'] = items['New Hires Employees who are < 30 years old (Male)'] ? items['New Hires Employees who are < 30 years old (Male)'] : 0
                                        local['DPAN642'] = items['New Hires Employees who are 30-50 years old (Male)'] ? items['New Hires Employees who are 30-50 years old (Male)'] : 0
                                        local['DPAN643'] = items['New Hires Employees who are >50 years old (Male)'] ? items['New Hires Employees who are >50 years old (Male)'] : 0
                                        local['DPAN644'] = items['New Hires Employees who are < 30 years old (Female)'] ? items['New Hires Employees who are < 30 years old (Female)'] : 0
                                        local['DPAN645'] = items['New Hires Employees who are 30-50 years old (Female)'] ? items['New Hires Employees who are 30-50 years old (Female)'] : 0
                                        local['DPAN646'] = items['New Hires Employees who are >50 years old (Female)'] ? items['New Hires Employees who are >50 years old (Female)'] : 0


                                        local['DPAN672'] = items['Employee Turnover Employees who are < 30 years old (Male)'] ? items['Employee Turnover Employees who are < 30 years old (Male)'] : 0
                                        local['DPAN673'] = items['Employee Turnover Employees who are 30-50 years old (Male)'] ? items['Employee Turnover Employees who are 30-50 years old (Male)'] : 0
                                        local['DPAN674'] = items['Employee Turnover Employees who are >50 years old (Male)'] ? items['Employee Turnover Employees who are >50 years old (Male)'] : 0
                                        local['DPAN675'] = items['Employee Turnover Employees who are < 30 years old (Female)'] ? items['Employee Turnover Employees who are < 30 years old (Female)'] : 0
                                        local['DPAN676'] = items['Employee Turnover Employees who are 30-50 years old (Female)'] ? items['Employee Turnover Employees who are 30-50 years old (Female)'] : 0
                                        local['DPAN677'] = items['Employee Turnover Employees who are >50 years old (Female)'] ? items['Employee Turnover Employees who are >50 years old (Female)'] : 0


                                        newObj['id'] = oldIndex.id

                                        local2.push(newObj);
                                        newObj['response'] = [local];
                                        newObj['excel_data'] = [items]

                                        res(newObj);
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                    newObj['edit'] = 1
                                    newObj['id'] = oldIndex.id
                                    newObj['response'] = []
                                    newObj['excel_data'] = [items]
                                    newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                    res(newObj);

                                    local2.push(newObj);
                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }
                        } else {

                            rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                            res(rejected)
                        }

                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise
    } else if (dcfId === 285) {

        const DPAN0048Options = [
            { name: "Self Generated Water", id: 6 },

            { name: "Third-Party Water", id: 5 },
        ];
        const DPAN1158Options = [
            { name: "Groundwater Wells Operated", id: 1 },

            { name: "Effluent/ Sewage Treatment Recycle", id: 2 },
            { name: 'Rainwater Harvesting', id: 3 },
            { name: 'Others', id: 99 }
        ];
        const DPAN1200Options = [
            { name: "Surface water", id: 1 },
            { name: "Ground Water", id: 2 },
            { name: "Sea Water", id: 3 },
            { name: 'Imported Water from Industrial District', id: 8 },
            { name: 'Unknown', id: 9 }
        ];
        const DPAN0050Options = [
            { name: "(≤1,000 mg/L Total Dissolved Solids)", id: 1 },
            { name: "(>1,000 mg/L Total Dissolved Solids)", id: 2 },
        ];
        let promise = new Promise(async (res, rej) => {
            try {

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                let count = 0
                if (typeof items['Quantity of Water Generated/Third-Party Withdrawal (m3)'] !== 'number') {
                    count++
                    if (!items['Quantity of Water Generated/Third-Party Withdrawal (m3)']) {
                        errorArray.push('Quantity of Water Generated/Third-Party Withdrawal (m3) Field is Required')
                    } else {
                        errorArray.push('Quantity of Water Generated/Third-Party Withdrawal (m3) Field Should Be Number Format')
                    }

                }
                if (!items['Method'] || !items['Method'].toString().length) {
                    count++
                    errorArray.push('Method Field is Required')

                }
                if (!items['Water Source'] || !items['Water Source'].toString().length) {
                    count++
                    errorArray.push('Water Source Field is Required')

                } else if (items['Water Source'] && !DPAN0048Options.find(i => i.name.toLowerCase().trim() === items['Water Source'].toString().toLowerCase().trim())) {
                    count++
                    errorArray.push('Water Source Field Has Invalid Value')
                } else if (items['Water Source'] && DPAN0048Options.find(i => i.name.toLowerCase().trim() === items['Water Source'].toString().toLowerCase().trim())) {
                    if (DPAN0048Options.find(i => i.name.toLowerCase().trim() === items['Water Source'].toString().toLowerCase().trim()).id === 6) {
                        if (items['Method'] && !DPAN1158Options.find(i => i.name.toLowerCase().trim() === items['Method'].toString().toLowerCase().trim())) {
                            errorArray.push('Method Field Has Invalid Value')
                            count++

                        }
                    } else {
                        if (items['Method'] && !DPAN1200Options.find(i => i.name.toLowerCase().trim() === items['Method'].toString().toLowerCase().trim())) {
                            errorArray.push('Method Field Has Invalid Value')
                            count++
                        }

                    }
                }




                let local = {};
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;

                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (count === 0) {


                                    let index1 = DPAN0048Options.findIndex(i => i.name.toLowerCase().trim() === items['Water Source'].toString().toLowerCase().trim())

                                    if (index1 !== -1) {
                                        let water_id = DPAN0048Options[index1].id
                                        if (water_id === 6) {
                                            let index2 = DPAN1158Options.findIndex(i => i.name.toLowerCase().trim() === items['Method'].toString().toLowerCase().trim())
                                            if (index2 !== -1) {
                                                if (items['Water Used in Cooling System (m3)'] && parseFloat(items['Water Used in Cooling System (m3)'].toString()) >= 0) {
                                                    local['DPAN1160'] = parseFloat(items['Water Used in Cooling System (m3)'].toString())
                                                } else {
                                                    local['DPAN1160'] = 0
                                                }
                                                local['id'] = getID()
                                                local['DPAN1200'] = null
                                                local['DPAN0050'] = null
                                                local['DPAN1161'] = 0
                                                local['DPAN1162'] = 0
                                                local['attachment'] = null

                                                local['DPAN0048'] = water_id
                                                local['DPAN1158'] = DPAN1158Options[index2].id
                                                local['DPAN1159'] = parseFloat(items['Quantity of Water Generated/Third-Party Withdrawal (m3)'].toString())


                                            }
                                        } else {

                                            let index2 = DPAN1200Options.findIndex(i => i.name.toLowerCase().trim() === items['Method'].toString().toLowerCase().trim())
                                            if (index2 !== -1) {
                                                if (items['Water Used in Cooling System (m3)'] && parseFloat(items['Water Used in Cooling System (m3)'].toString()) >= 0) {
                                                    local['DPAN1162'] = parseFloat(items['Water Used in Cooling System (m3)'].toString())
                                                } else {
                                                    local['DPAN1162'] = 0
                                                }

                                                if (items['Total Dissolved Solids of Water Withdrawal'] && items['Total Dissolved Solids of Water Withdrawal'].toString().trim().length !== 0) {
                                                    let index2 = DPAN0050Options.findIndex(i => i.name.toLowerCase().trim() === items['Total Dissolved Solids of Water Withdrawal'].toString().toLowerCase().trim())
                                                    if (index2 !== -1) {
                                                        local["DPAN0050"] = DPAN0050Options[index2].id
                                                    } else {
                                                        local["DPAN0050"] = null
                                                    }


                                                } else {
                                                    local["DPAN0050"] = null
                                                }
                                                local['id'] = getID()
                                                local['DPAN1158'] = null
                                                local['DPAN1160'] = 0
                                                local['DPAN1159'] = 0
                                                local['attachment'] = null

                                                local['DPAN0048'] = water_id
                                                local['DPAN1200'] = DPAN1200Options[index2].id
                                                local['DPAN1161'] = parseFloat(items['Quantity of Water Generated/Third-Party Withdrawal (m3)'].toString())


                                            }


                                        }


                                    }





                                    newObj['response'] = [local];
                                    newObj['excel_data'] = [items]
                                    local2.push(newObj);
                                    res(newObj);
                                } else {
                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else if (!found && assObj[0].id) {
                            let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                            let refObjData = assObj.filter(i => i.refobj)
                            if (oldIndex && refObjData.length !== 0) {
                                let refObj = refObjData[0].refobj
                                console.log(refObjData[0])
                                newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                newObj['rp'] = items['Reporting Period'];
                                newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                newObj['form_type'] = 1
                                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                newObj['logs'] = [];
                                newObj['submitted_by'] = login_data.id;
                                newObj['submitted_on'] = dt;
                                newObj['last_modified_on'] = dt;
                                newObj['reporter_modified_on'] = dt;
                                newObj['reporter_modified_by'] = login_data.id;
                                newObj['last_modified_by'] = login_data.id;
                                newObj['entityUserAssId'] = refObj.id;
                                newObj['entityAssId'] = refObj.entityAssId;
                                newObj['standard'] = refObj.standard;
                                newObj['dcfId'] = refineddata[0].dcfId
                                if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                    if (count === 0) {
                                        let index1 = DPAN0048Options.findIndex(i => i.name.toLowerCase().trim() === items['Water Source'].toString().toLowerCase().trim())

                                        if (index1 !== -1) {
                                            let water_id = DPAN0048Options[index1].id
                                            if (water_id === 6) {
                                                let index2 = DPAN1158Options.findIndex(i => i.name.toLowerCase().trim() === items['Method'].toString().toLowerCase().trim())
                                                if (index2 !== -1) {
                                                    if (items['Water Used in Cooling System (m3)'] && parseFloat(items['Water Used in Cooling System (m3)'].toString()) >= 0) {
                                                        local['DPAN1160'] = parseFloat(items['Water Used in Cooling System (m3)'].toString())
                                                    } else {
                                                        local['DPAN1160'] = 0
                                                    }
                                                    local['id'] = getID()
                                                    local['DPAN1200'] = null
                                                    local['DPAN0050'] = null
                                                    local['DPAN1161'] = 0
                                                    local['DPAN1162'] = 0
                                                    local['attachment'] = null

                                                    local['DPAN0048'] = water_id
                                                    local['DPAN1158'] = DPAN1158Options[index2].id
                                                    local['DPAN1159'] = parseFloat(items['Quantity of Water Generated/Third-Party Withdrawal (m3)'].toString())


                                                }
                                            } else {

                                                let index2 = DPAN1200Options.findIndex(i => i.name.toLowerCase().trim() === items['Method'].toString().toLowerCase().trim())
                                                if (index2 !== -1) {
                                                    if (items['Water Used in Cooling System (m3)'] && parseFloat(items['Water Used in Cooling System (m3)'].toString()) >= 0) {
                                                        local['DPAN1162'] = parseFloat(items['Water Used in Cooling System (m3)'].toString())
                                                    } else {
                                                        local['DPAN1162'] = 0
                                                    }

                                                    if (items['Total Dissolved Solids of Water Withdrawal'] && items['Total Dissolved Solids of Water Withdrawal'].toString().trim().length !== 0) {
                                                        let index2 = DPAN0050Options.findIndex(i => i.name.toLowerCase().trim() === items['Total Dissolved Solids of Water Withdrawal'].toString().toLowerCase().trim())
                                                        if (index2 !== -1) {
                                                            local["DPAN0050"] = DPAN0050Options[index2].id
                                                        } else {
                                                            local["DPAN0050"] = null
                                                        }


                                                    } else {
                                                        local["DPAN0050"] = null
                                                    }
                                                    local['id'] = getID()
                                                    local['DPAN1158'] = null
                                                    local['DPAN1160'] = 0
                                                    local['DPAN1159'] = 0
                                                    local['attachment'] = null

                                                    local['DPAN0048'] = water_id
                                                    local['DPAN1200'] = DPAN1200Options[index2].id
                                                    local['DPAN1161'] = parseFloat(items['Quantity of Water Generated/Third-Party Withdrawal (m3)'].toString())


                                                }


                                            }


                                        }

                                        newObj['id'] = oldIndex.id


                                        newObj['response'] = [local];
                                        newObj['excel_data'] = [items]
                                        local2.push(newObj);
                                        res(newObj);
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                    newObj['edit'] = 1
                                    newObj['id'] = oldIndex.id
                                    newObj['response'] = []
                                    newObj['excel_data'] = [items]
                                    newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                    res(newObj);

                                    local2.push(newObj);
                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }
                        } else {

                            rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                            res(rejected)
                        }

                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise
    } else if (dcfId === 264) {


        let promise = new Promise(async (res, rej) => {
            try {

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                let count = 0
                if (typeof items['Grid Electricity Consumed by Customer IT Equipment ( kWh )'] !== 'number') {
                    count++
                    if (!items['Grid Electricity Consumed by Customer IT Equipment ( kWh )']) {
                        errorArray.push('Grid Electricity Consumed by Customer IT Equipment ( kWh ) Field is Required')
                    } else {
                        errorArray.push('Grid Electricity Consumed by Customer IT Equipment ( kWh ) Field Should Be Number Format')
                    }

                }
                if (typeof items['Green Electricity Consumed by Customer IT Equipment ( kWh ) '] !== 'number') {
                    count++
                    if (!items['Green Electricity Consumed by Customer IT Equipment ( kWh ) ']) {
                        errorArray.push('Green Electricity Consumed by Customer IT Equipment ( kWh )  Field is Required')
                    } else {
                        errorArray.push('Green Electricity Consumed by Customer IT Equipment ( kWh )  Field Should Be Number Format')
                    }

                }




                let local = [
                    {
                        "type": "number",
                        "required": true,
                        "label": "<span fontscheme=\"2\" style=\"color: rgb(0, 0, 0); font-family: Calibri; font-size: 11pt; white-space-collapse: preserve;\">Grid Electricity Consumed by Customer IT Equipment  ( kWh )</span>",
                        "description": "Please enter value as '0' if not applicable.",
                        "className": "form-control",
                        "name": "DPAN454",
                        "value": null
                    },
                    {
                        "type": "number",
                        "required": true,
                        "label": "<span fontscheme=\"2\" style=\"color: rgb(0, 0, 0); font-family: Calibri; font-size: 11pt; white-space-collapse: preserve;\">Green Electricity Consumed by Customer IT Equipment ( kWh )</span>",
                        "description": "Please enter value as '0' if not applicable.",
                        "className": "form-control",
                        "name": "DPAN1103",
                        "value": null
                    },
                    {
                        "type": "file",
                        "required": false,
                        "label": "<span fontscheme=\"2\" style=\"color: rgb(0, 0, 0); font-family: Calibri; font-size: 11pt; white-space-collapse: preserve;\">Add </span><span fontscheme=\"2\" style=\"color: rgb(0, 0, 0); font-family: Calibri; font-size: 11pt; white-space-collapse: preserve;\">Supporting </span><span fontscheme=\"2\" style=\"color: rgb(0, 0, 0); font-family: Calibri; font-size: 11pt; white-space-collapse: preserve;\">Document </span>",
                        "description": "Please attach any evidence or records to support the data inputted. E.g. BMS screenshots or PDU tickets etc.",
                        "className": "form-control",
                        "name": "DPAN1104",
                        "multiple": true,
                        "value": null
                    }
                ];
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;

                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (count === 0) {

                                    local[0].value = items['Grid Electricity Consumed by Customer IT Equipment ( kWh )']
                                    local[1].value = items['Green Electricity Consumed by Customer IT Equipment ( kWh ) ']
                                    local2.push(newObj);
                                    newObj['response'] = local;
                                    newObj['excel_data'] = [items]
                                    res(newObj);
                                } else {
                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else if (!found && assObj[0].id) {
                            let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                            let refObjData = assObj.filter(i => i.refobj)
                            if (oldIndex && refObjData.length !== 0) {
                                let refObj = refObjData[0].refobj
                                console.log(refObjData[0])
                                newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                newObj['rp'] = items['Reporting Period'];
                                newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                newObj['form_type'] = 1
                                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                newObj['logs'] = [];
                                newObj['submitted_by'] = login_data.id;
                                newObj['submitted_on'] = dt;
                                newObj['last_modified_on'] = dt;
                                newObj['reporter_modified_on'] = dt;
                                newObj['reporter_modified_by'] = login_data.id;
                                newObj['last_modified_by'] = login_data.id;
                                newObj['entityUserAssId'] = refObj.id;
                                newObj['entityAssId'] = refObj.entityAssId;
                                newObj['standard'] = refObj.standard;
                                newObj['dcfId'] = refineddata[0].dcfId
                                if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                    if (count === 0) {

                                        local[0].value = items['Grid Electricity Consumed by Customer IT Equipment ( kWh )']
                                        local[1].value = items['Green Electricity Consumed by Customer IT Equipment ( kWh ) ']

                                        newObj['response'] = local;
                                        newObj['excel_data'] = [items]

                                        newObj['id'] = oldIndex.id


                                        newObj['response'] = [local];
                                        newObj['excel_data'] = [items]
                                        local2.push(newObj);
                                        res(newObj);
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                    newObj['edit'] = 1
                                    newObj['id'] = oldIndex.id
                                    newObj['response'] = []
                                    newObj['excel_data'] = [items]
                                    newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                    res(newObj);

                                    local2.push(newObj);
                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }
                        } else {

                            rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                            res(rejected)
                        }

                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise
    } else if (dcfId === 287) {
        let promise = new Promise(async (res, rej) => {
            try {

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                let count = 0
                if (!items['Type of Electricity Used'] || !items['Type of Electricity Used'].toString().trim().length) {
                    count++;
                    errorArray.push('Type of Electricity Used Field is Required')
                }
                if (items['Type of Electricity Used'] && items['Type of Electricity Used'].toString().trim().length && items['Type of Electricity Used'].toString().trim().toLowerCase() === 'renewable') {
                    if (!items['Renewable Instrument'] || !items['Renewable Instrument'].toString().trim().length) {
                        count++;
                        errorArray.push('Renewable Instrument Field is Required')
                    }
                    if (!items['Renewable Type'] || !items['Renewable Type'].toString().trim().length) {
                        count++;
                        errorArray.push('Renewable Type Field is Required')
                    }
                }

                if ((!items['Quantity of Energy Used (kWh)'] && items['Quantity of Energy Used (kWh)'] !== 0) || !items['Quantity of Energy Used (kWh)'].toString().trim().length) {
                    count++;
                    errorArray.push('Quantity of Energy Used (kWh) Field is Required')
                } else if (items['Quantity of Energy Used (kWh)'] && items['Quantity of Energy Used (kWh)'].toString().trim().length) {

                    if (items['Quantity of Energy Used (kWh)'] !== null &&
                        items['Quantity of Energy Used (kWh)'] !== undefined && typeof items['Quantity of Energy Used (kWh)'] !== 'number' &&
                        parseFloat(items['Quantity of Energy Used (kWh)'].toString()) >= 0 && (parseFloat(items['Quantity of Energy Used (kWh)'].toString()).toString().trim().length === items['Quantity of Energy Used (kWh)'].toString().trim().length)
                    ) {
                        count++;
                        errorArray.push('Quantity of Energy Used (kWh) Field Accepts Only valid Number Input as Either Rounded or Fraction value (Dont use , or other notation)')
                    } else if (!parseFloat(items['Quantity of Energy Used (kWh)'].toString().trim()) && parseFloat(items['Quantity of Energy Used (kWh)'].toString().trim()) !== 0) {
                        count++;
                        errorArray.push('Quantity of Energy Used (kWh) Field Requires Either Rounded or Fraction value')
                    }
                }
                if (items['Entity'] && items['Entity'].toString().trim().length && items['Entity Level'] && items['Entity Level'].toString().trim().length && items['Reporting Period'] && items['Reporting Period'].toString().trim().length && validateDateString(items['Reporting Period']) && count === 0) {
                    let level = items['Entity Level'];

                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);
                    const { found, assObj } = await getAssignment(items['Reporting Period'], obj, errorArray, refineddata).then((assObj) => { return assObj })
                    console.log(found, 'found')
                    if (found) {
                        let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                            (i) => i.id === 27
                        );
                        if (stdindex !== -1) {
                            if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 49);

                                if (cat_index !== -1) {
                                    let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;
                                    if (items['Type of Electricity Used'].toString().trim().toLowerCase() === 'renewable') {
                                        const { result, id } = findMatchingIds(
                                            category,
                                            items['Type of Electricity Used'].toString(),
                                            items['Renewable Instrument'].toString(),
                                            items['Renewable Type'].toString()
                                        );
                                        console.log(items, JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index])
                                        if (!result) {
                                            errorArray.push('Mismatch in Combination Type of Electricity Used, Renewable Instrument & Renewable Type')
                                            count++
                                        } else if (result) {
                                            if (id[1] === 542) {
                                                if (!items['Remarks for Renewable Instrument'] || !items['Remarks for Renewable Instrument'].toString().trim().length) {
                                                    count++;
                                                    errorArray.push('Remarks for Renewable Instrument Field is Required')
                                                }
                                            }
                                            if (id[2] === 1405 || id[2] === 1413 || id[2] === 1429 || id[2] === 1428) {
                                                if (!items['Remarks for Renewable Type'] || !items['Remarks for Renewable Type'].toString().trim().length) {
                                                    count++;
                                                    errorArray.push('Remarks for Renewable Type Field is Required')
                                                }
                                            }
                                        }
                                    } else {
                                        const { result, id } = findMatchingIds(
                                            category,
                                            items['Type of Electricity Used'].toString()
                                        );
                                        if (!result) {
                                            count++
                                            errorArray.push('Mismatch in Type of Electricity Used')

                                        }
                                    }

                                } else {
                                    errorArray.push('Missing Configuration in Electricity EF')

                                }


                            }
                        } else {
                            errorArray.push('Inform Admin to check selected Standard for this Enity on this Form ')

                        }
                    } else if (!found && assObj[0].id) {
                        let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                        let refObjData = assObj.filter(i => i.refobj)
                        let refObj = refObjData[0].refobj
                        let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                            (i) => i.id === 27
                        );
                        if (stdindex !== -1) {
                            if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 49);
                                let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;

                                if (cat_index !== -1) {
                                    let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;
                                    if (items['Type of Electricity Used'].toString().trim().toLowerCase() === 'renewable') {
                                        const { result, id } = findMatchingIds(
                                            category,
                                            items['Type of Electricity Used'].toString(),
                                            items['Renewable Instrument'].toString(),
                                            items['Renewable Type'].toString()
                                        );
                                        if (!result) {
                                            errorArray.push('Mismatch in Combination Type of Electricity Used, Renewable Instrument & Renewable Type')
                                            count++
                                        } else if (result) {
                                            if (id[1] === 542) {
                                                if (!items['Remarks for Renewable Instrument'] || !items['Remarks for Renewable Instrument'].toString().trim().length) {
                                                    count++;
                                                    errorArray.push('Remarks for Renewable Instrument Field is Required')
                                                }
                                            }
                                            if (id[2] === 1405 || id[2] === 1413 || id[2] === 1429 || id[2] === 1428) {
                                                if (!items['Remarks for Renewable Type'] || !items['Remarks for Renewable Type'].toString().trim().length) {
                                                    count++;
                                                    errorArray.push('Remarks for Renewable Type Field is Required')
                                                }
                                            }
                                        }
                                    } else {
                                        const { result, id } = findMatchingIds(
                                            category,
                                            items['Type of Electricity Used'].toString()
                                        );
                                        if (!result) {
                                            errorArray.push('Mismatch in Type of Electricity Used')
                                            count++
                                        }
                                    }

                                } else {
                                    errorArray.push('Missing Configuration in Electricity EF')

                                }

                            }
                        } else {
                            errorArray.push('Inform Admin to check selected Standard for this Enity on this Form ')

                        }




                    }
                }

                let local = {};
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;
                            newObj['standard'] = 27
                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (newObj['standard']) {
                                    let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                                        (i) => i.id === 27
                                    );

                                    if (stdindex !== -1) {
                                        if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                            let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 49);
                                            let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;

                                            if (cat_index !== -1) {

                                                if (
                                                    count === 0
                                                ) {

                                                    if (items['Type of Electricity Used'].toString().trim().toLowerCase() === 'renewable') {
                                                        const { result, id } = findMatchingIds(
                                                            category,
                                                            items['Type of Electricity Used'].toString(),
                                                            items['Renewable Instrument'].toString(),
                                                            items['Renewable Type'].toString()
                                                        );
                                                        if (result) {
                                                            local['DPAN095'] = id[0]
                                                            local['DPAN095A'] = id[1]
                                                            local['DPAN095B'] = id[2]
                                                            local['DPAN098B'] = parseFloat(items['Quantity of Energy Used (kWh)'].toString())
                                                            if (id[1] === 542) {
                                                                local['DPAN095A1'] = items['Remarks for Renewable Instrument']
                                                            }
                                                            if (id[2] === 1405 || id[2] === 1413 || id[2] === 1429 || id[2] === 1428) {
                                                                local['DPAN095B1'] = items['Remarks for Renewable Type']
                                                            }
                                                            if (items['Name of Energy Provider']) {
                                                                local['DPAN097'] = items['Name of Energy Provider']
                                                            }

                                                            local['id'] = getID()
                                                            newObj['response'] = [local];
                                                            newObj['excel_data'] = [items]
                                                            newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                                            local2.push(newObj);
                                                            res(newObj); // Resolve this promise with the newObj
                                                        } else {
                                                            rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                            res(rejected)

                                                        }
                                                    } else {
                                                        const { result, id } = findMatchingIds(
                                                            category,
                                                            items['Type of Electricity Used'].toString()
                                                        );
                                                        if (result) {
                                                            local['DPAN095'] = id[0]
                                                            local['DPAN098A'] = parseFloat(items['Quantity of Energy Used (kWh)'].toString())
                                                            local['DPAN095A'] = null
                                                            local['DPAN095B'] = null
                                                            local['DPAN098B'] = 0
                                                            local['DPAN097'] = ''
                                                            local['DPAN095A1'] = ''
                                                            local['DPAN095A1'] = ''
                                                            local['id'] = getID()
                                                            newObj['response'] = [local];
                                                            newObj['excel_data'] = [items]
                                                            newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                                            local2.push(newObj);
                                                            res(newObj); // Resolve this promise with the newObj
                                                        } else {
                                                            rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                            res(rejected)

                                                        }
                                                    }




                                                } else {

                                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                    res(rejected)
                                                }
                                            } else {
                                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                res(rejected) // Resolve even if not matching
                                            }
                                        } else {
                                            rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                            res(rejected) // Resolve even if not matching
                                        }
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected) // Resolve even if not matching
                                    }
                                }
                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Category of Fuel Used'] && !items['Fuel Used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else {
                            console.log(assObj)
                            if (!found && assObj[0].id) {
                                let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                                let refObjData = assObj.filter(i => i.refobj)
                                if (oldIndex && refObjData.length !== 0) {
                                    let refObj = refObjData[0].refobj
                                    console.log(refObjData[0])
                                    newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                    newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                    newObj['rp'] = items['Reporting Period'];
                                    newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                    newObj['form_type'] = 1
                                    newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                    newObj['logs'] = [];
                                    newObj['submitted_by'] = login_data.id;
                                    newObj['submitted_on'] = dt;
                                    newObj['last_modified_on'] = dt;
                                    newObj['reporter_modified_on'] = dt;
                                    newObj['reporter_modified_by'] = login_data.id;
                                    newObj['last_modified_by'] = login_data.id;
                                    newObj['entityUserAssId'] = refObj.id;
                                    newObj['entityAssId'] = refObj.entityAssId;
                                    newObj['standard'] = 27
                                    newObj['dcfId'] = refineddata[0].dcfId
                                    if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {

                                        if (newObj['standard']) {
                                            let stdindex = JSON.parse(JSON.stringify(stdlist)).findIndex(
                                                (i) => i.id === 27
                                            );

                                            if (stdindex !== -1) {
                                                console.log('1')
                                                if (JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories !== undefined) {
                                                    let cat_index = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories.findIndex((i) => i.id === 49);
                                                    let category = JSON.parse(JSON.stringify(stdlist))[stdindex].newEfCategories[cat_index].newEfSubcategory1s;

                                                    if (cat_index !== -1) {
                                                        console.log('2')

                                                        if (
                                                            count === 0) {
                                                            if (items['Type of Electricity Used'].toString().trim().toLowerCase() === 'renewable') {
                                                                const { result, id } = findMatchingIds(
                                                                    category,
                                                                    items['Type of Electricity Used'].toString(),
                                                                    items['Renewable Instrument'].toString(),
                                                                    items['Renewable Type'].toString()
                                                                );
                                                                if (result) {
                                                                    local['DPAN095'] = id[0]
                                                                    local['DPAN095A'] = id[1]
                                                                    local['DPAN095B'] = id[2]
                                                                    local['DPAN098B'] = parseFloat(items['Quantity of Energy Used (kWh)'].toString())
                                                                    if (id[1] === 542) {
                                                                        local['DPAN095A1'] = items['Remarks for Renewable Instrument']
                                                                    }
                                                                    if (id[2] === 1405 || id[2] === 1413 || id[2] === 1429 || id[2] === 1428) {
                                                                        local['DPAN095B1'] = items['Remarks for Renewable Type']
                                                                    }
                                                                    if (items['Name of Energy Provider']) {
                                                                        local['DPAN097'] = items['Name of Energy Provider']
                                                                    }

                                                                    local['id'] = getID()
                                                                    newObj['id'] = oldIndex.id
                                                                    newObj['response'] = [local];
                                                                    newObj['excel_data'] = [items]
                                                                    newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                                                    local2.push(newObj);
                                                                    res(newObj);
                                                                } else {
                                                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                                    res(rejected)

                                                                }
                                                            } else {
                                                                const { result, id } = findMatchingIds(
                                                                    category,
                                                                    items['Type of Electricity Used'].toString()
                                                                );
                                                                if (result) {
                                                                    local['DPAN095'] = id[0]
                                                                    local['DPAN098A'] = parseFloat(items['Quantity of Energy Used (kWh)'].toString())
                                                                    local['DPAN095A'] = null
                                                                    local['DPAN095B'] = null
                                                                    local['DPAN098B'] = 0
                                                                    local['DPAN097'] = ''
                                                                    local['DPAN095A1'] = ''
                                                                    local['DPAN095A1'] = ''
                                                                    local['id'] = getID()
                                                                    newObj['id'] = oldIndex.id
                                                                    newObj['response'] = [local];
                                                                    newObj['excel_data'] = [items]
                                                                    newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                                                    local2.push(newObj);
                                                                    res(newObj);
                                                                } else {
                                                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                                    res(rejected)

                                                                }
                                                            }

                                                        } else {

                                                            rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                            res(rejected)
                                                        }
                                                    } else {
                                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                        res(rejected) // Resolve even if not matching
                                                    }
                                                } else {
                                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                    res(rejected) // Resolve even if not matching
                                                }
                                            } else {
                                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                                res(rejected) // Resolve even if not matching
                                            }
                                        }
                                    } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Category of Fuel Used'] && !items['Fuel Used'])) {
                                        newObj['edit'] = 1
                                        newObj['id'] = oldIndex.id
                                        newObj['response'] = []
                                        newObj['excel_data'] = [items]
                                        newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                        res(newObj);

                                        local2.push(newObj);
                                    } else {

                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                    res(rejected)
                                }
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }

                        }
                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise



    } else if (dcfId === 307) {

        const DPAH500Options = [
            { name: "Paper - all types", id: 1 },
            { name: "Plastic - all types", id: 2 },
            { name: "Metals - all types", id: 3 },
            { name: "Glass - all types", id: 11 },
            { name: "Wood - all types", id: 13 },
            { name: "Electrical items - WEEE - (ex. printers)", id: 4 },
            { name: "Electrical items - Batteries", id: 5 },
            { name: "General Waste - Mixed Commecial and industrial waste", id: 6 },
            { name: "General Waste - Organic: mixed food and garden waste", id: 7 },
            { name: "General Waste - Organic: garden waste", id: 8 },
            { name: "General Waste - Organic: food and drink waste", id: 9 },
            { name: "General Waste - Household residual waste", id: 10 },
            { name: "Others - Please include in remarks", id: 12 },
        ];
        const DPAH501Options = [
            { name: 'kg', id: 1 },
            { name: 'litre', id: 2 },
            { name: 'number', id: 3 }
        ]
        const DPAH504Options = [
            { name: 'Incineration (w/o Energy Recovery)', id: 1 },
            { name: 'Incineration (with Energy Recovery)', id: 2 },
            { name: 'Landfill', id: 3 },
            { name: 'Recycle', id: 4 },
            { name: 'Composting', id: 5 },
            { name: 'Waste Recovery', id: 6 },
            { name: 'Unknown', id: 7 },
            { name: 'Others - Please include in remarks', id: 8 }
        ]
        let promise = new Promise(async (res, rej) => {
            try {

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                let count = 0
                if (typeof items['Amount'] !== 'number') {
                    count++
                    if (!items['Amount']) {
                        errorArray.push('Amount Field is Required')
                    } else {
                        errorArray.push('Amount Field Should Be Number Format')
                    }

                }
                if (typeof items['Average Density'] !== 'number') {
                    count++
                    if (!items['Average Density']) {
                        errorArray.push('Average Density Field is Required')
                    } else {
                        errorArray.push('Average Density Field Should Be Number Format')
                    }

                }

                if (!items['Disposal Method'] || !items['Disposal Method'].toString().length) {
                    count++
                    errorArray.push('Disposal Method Field is Required')

                } else if (items['Disposal Method'] && !DPAH504Options.find(i => i.name.toLowerCase().trim() === items['Disposal Method'].toString().toLowerCase().trim())) {
                    count++
                    errorArray.push('Disposal Method Field Has Invalid Value')
                } else if (items['Disposal Method'] && DPAH504Options.find(i => i.name.toLowerCase().trim() === items['Disposal Method'].toString().toLowerCase().trim())) {
                    if (DPAH504Options.find(i => i.name.toLowerCase().trim() === items['Disposal Method'].toString().toLowerCase().trim()).id === 8) {
                        if (!items['Remarks for Other Disposal Method'] || !items['Remarks for Other Disposal Method'].toString().length) {
                            errorArray.push('Remarks for Other Disposal Method Field Has Invalid Value')
                            count++

                        }
                    }
                }
                if (!items['Waste Category'] || !items['Waste Category'].toString().length) {
                    count++
                    errorArray.push('Waste Category Field is Required')

                } else if (items['Waste Category'] && !DPAH500Options.find(i => i.name.toLowerCase().trim() === items['Waste Category'].toString().toLowerCase().trim())) {
                    count++
                    errorArray.push('Waste Category Field Has Invalid Value')
                } else if (items['Waste Category'] && DPAH500Options.find(i => i.name.toLowerCase().trim() === items['Waste Category'].toString().toLowerCase().trim())) {
                    if (DPAH500Options.find(i => i.name.toLowerCase().trim() === items['Waste Category'].toString().toLowerCase().trim()).id === 12) {
                        if (!items['Remarks for Others Hazardous Waste Category'] || !items['Remarks for Others Hazardous Waste Category'].toString().length) {
                            errorArray.push('Remarks for Others Hazardous Waste Category Field Has Invalid Value')
                            count++

                        }
                    }
                }

                if (!items['Unit'] || !items['Unit'].toString().length) {
                    count++
                    errorArray.push('Unit Field is Required')

                } else if (items['Unit'] && !DPAH501Options.find(i => i.name.toLowerCase().trim() === items['Unit'].toString().toLowerCase().trim())) {
                    count++
                    errorArray.push('Unit Field Has Invalid Value')
                }


                let local = {};
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;

                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (count === 0) {

                                    local['DPAH500'] = DPAH500Options.find(i => i.name.toLowerCase().trim() === items['Waste Category'].toString().toLowerCase().trim()).id
                                    if (local['DPAH500'] === 12) {
                                        local['DPAH500B'] = items['Remarks for Others Hazardous Waste Category'].toString()
                                    } else {
                                        local['DPAH500B'] = ''
                                    }

                                    local['DPAH501'] = DPAH501Options.find(i => i.name.toLowerCase().trim() === items['Unit'].toString().toLowerCase().trim()).id
                                    local['DPAH502'] = parseFloat(items['Amount'])
                                    local['DPAH503'] = parseFloat(items['Average Density'])
                                    local['DPAH504'] = DPAH504Options.find(i => i.name.toLowerCase().trim() === items['Disposal Method'].toString().toLowerCase().trim()).id
                                    if (local['DPAH504'] === 8) {
                                        local['DPAH504B'] = items['Remarks for Other Disposal Method'].toString()
                                    } else {
                                        local['DPAH504B'] = ''
                                    }
                                    local['DPAH505'] = items['Remarks'] ? items['Remarks'].toString() : ''
                                    local['attachment'] = null
                                    local['id'] = getID()




                                    local2.push(newObj);
                                    newObj['response'] = [local];
                                    newObj['excel_data'] = [items]
                                    res(newObj);
                                } else {
                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else if (!found && assObj[0].id) {
                            let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                            let refObjData = assObj.filter(i => i.refobj)
                            if (oldIndex && refObjData.length !== 0) {
                                let refObj = refObjData[0].refobj
                                console.log(refObjData[0])
                                newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                newObj['rp'] = items['Reporting Period'];
                                newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                newObj['form_type'] = 1
                                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                newObj['logs'] = [];
                                newObj['submitted_by'] = login_data.id;
                                newObj['submitted_on'] = dt;
                                newObj['last_modified_on'] = dt;
                                newObj['reporter_modified_on'] = dt;
                                newObj['reporter_modified_by'] = login_data.id;
                                newObj['last_modified_by'] = login_data.id;
                                newObj['entityUserAssId'] = refObj.id;
                                newObj['entityAssId'] = refObj.entityAssId;
                                newObj['standard'] = refObj.standard;
                                newObj['dcfId'] = refineddata[0].dcfId
                                if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                    if (count === 0) {



                                        local['DPAH500'] = DPAH500Options.find(i => i.name.toLowerCase().trim() === items['Waste Category'].toString().toLowerCase().trim()).id
                                        if (local['DPAH500'] === 12) {
                                            local['DPAH500B'] = items['Remarks for Others Hazardous Waste Category'].toString()
                                        } else {
                                            local['DPAH500B'] = ''
                                        }

                                        local['DPAH501'] = DPAH501Options.find(i => i.name.toLowerCase().trim() === items['Unit'].toString().toLowerCase().trim()).id
                                        local['DPAH502'] = parseFloat(items['Amount'])
                                        local['DPAH503'] = parseFloat(items['Average Density'])
                                        local['DPAH504'] = DPAH504Options.find(i => i.name.toLowerCase().trim() === items['Disposal Method'].toString().toLowerCase().trim()).id
                                        if (local['DPAH504'] === 8) {
                                            local['DPAH504B'] = items['Remarks for Other Disposal Method'].toString()
                                        } else {
                                            local['DPAH504B'] = ''
                                        }
                                        local['DPAH505'] = items['Remarks'] ? items['Remarks'].toString() : ''
                                        local['attachment'] = null
                                        local['id'] = getID()




                                        local2.push(newObj);
                                        newObj['response'] = [local];
                                        newObj['excel_data'] = [items]
                                        res(newObj);

                                        newObj['id'] = oldIndex.id
                                        local2.push(newObj);

                                        newObj['response'] = [local];
                                        newObj['excel_data'] = [items]
                                        res(newObj);
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                    newObj['edit'] = 1
                                    newObj['id'] = oldIndex.id
                                    newObj['response'] = []
                                    newObj['excel_data'] = [items]
                                    newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                    res(newObj);

                                    local2.push(newObj);
                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }
                        } else {

                            rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                            res(rejected)
                        }

                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise
    } else if (dcfId === 286) {

        const DPAN0052Options = [
            { name: "Surface Water", id: 1 },
            { name: "Ground Water", id: 2 },
            { name: "Sea Water", id: 3 },
            { name: "Third-Party Water (E.g.- Industrial Sewer)", id: 5 },
            { name: "Water Reuse", id: 6 },
            { name: "Water Recycled", id: 7 },
        ];
        const DPAN0054Options = [
            { name: "(≤1,000 mg/L Total Dissolved Solids)", id: 1 },
            { name: "(>1,000 mg/L Total Dissolved Solids)", id: 2 },
        ];
        let promise = new Promise(async (res, rej) => {
            try {

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                let count = 0
                if (typeof items['Quantity of Water Discharge (m3)'] !== 'number') {
                    count++
                    if (!items['Quantity of Water Discharge (m3)']) {
                        errorArray.push('Quantity of Water Discharge (m3) Field is Required')
                    } else {
                        errorArray.push('Quantity of Water Discharge (m3) Field Should Be Number Format')
                    }

                }

                if (!items['Destination of Water Discharge'] || !items['Destination of Water Discharge'].toString().length) {
                    count++
                    errorArray.push('Destination of Water Discharge Field is Required')

                } else if (items['Destination of Water Discharge'] && !DPAN0052Options.find(i => i.name.toLowerCase().trim() === items['Destination of Water Discharge'].toString().toLowerCase().trim())) {
                    count++
                    errorArray.push('Destination of Water Discharge Field Has Invalid Value')
                }




                let local = {};
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;

                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (count === 0) {


                                    local['id'] = getID()
                                    local['DPAN0052'] = DPAN0052Options.find(i => i.name.toLowerCase().trim() === items['Destination of Water Discharge'].toString().toLowerCase().trim()).id
                                    local['DPAN0053'] = parseFloat(items['Quantity of Water Discharge (m3)'])

                                    if (items['Total Dissolved Solids of the Destination of Disposal'] && items['Total Dissolved Solids of the Destination of Disposal'].toString().trim().length !== 0) {
                                        let index2 = DPAN0054Options.findIndex(i => i.name.toLowerCase().trim() === items['Total Dissolved Solids of the Destination of Disposal'].toString().toLowerCase().trim())
                                        if (index2 !== -1) {
                                            local["DPAN0054"] = DPAN0054Options[index2].id
                                        } else {
                                            local["DPAN0054"] = null
                                        }


                                    } else {
                                        local["DPAN0054"] = null
                                    }




                                    local2.push(newObj);
                                    newObj['response'] = [local];
                                    newObj['excel_data'] = [items]
                                    res(newObj);
                                } else {
                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else if (!found && assObj[0].id) {
                            let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                            let refObjData = assObj.filter(i => i.refobj)
                            if (oldIndex && refObjData.length !== 0) {
                                let refObj = refObjData[0].refobj
                                console.log(refObjData[0])
                                newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                newObj['rp'] = items['Reporting Period'];
                                newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                newObj['form_type'] = 1
                                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                newObj['logs'] = [];
                                newObj['submitted_by'] = login_data.id;
                                newObj['submitted_on'] = dt;
                                newObj['last_modified_on'] = dt;
                                newObj['reporter_modified_on'] = dt;
                                newObj['reporter_modified_by'] = login_data.id;
                                newObj['last_modified_by'] = login_data.id;
                                newObj['entityUserAssId'] = refObj.id;
                                newObj['entityAssId'] = refObj.entityAssId;
                                newObj['standard'] = refObj.standard;
                                newObj['dcfId'] = refineddata[0].dcfId
                                if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                    if (count === 0) {

                                        local['id'] = getID()
                                        local['DPAN0052'] = DPAN0052Options.find(i => i.name.toLowerCase().trim() === items['Destination of Water Discharge'].toString().toLowerCase().trim()).id
                                        local['DPAN0053'] = parseFloat(items['Quantity of Water Discharge (m3)'])

                                        if (items['Total Dissolved Solids of the Destination of Disposal'] && items['Total Dissolved Solids of the Destination of Disposal'].toString().trim().length !== 0) {
                                            let index2 = DPAN0054Options.findIndex(i => i.name.toLowerCase().trim() === items['Total Dissolved Solids of the Destination of Disposal'].toString().toLowerCase().trim())
                                            if (index2 !== -1) {
                                                local["DPAN0054"] = DPAN0054Options[index2].id
                                            } else {
                                                local["DPAN0054"] = null
                                            }


                                        } else {
                                            local["DPAN0054"] = null
                                        }



                                        newObj['id'] = oldIndex.id


                                        newObj['response'] = [local];
                                        newObj['excel_data'] = [items]
                                        local2.push(newObj);
                                        res(newObj);
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                    newObj['edit'] = 1
                                    newObj['id'] = oldIndex.id
                                    newObj['response'] = []
                                    newObj['excel_data'] = [items]
                                    newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                    res(newObj);

                                    local2.push(newObj);
                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }
                        } else {

                            rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                            res(rejected)
                        }

                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise
    } else if (dcfId === 297) {
        const DPAH0013Options = [
            { name: 'Used Engine Oil', id: 1 },
            { name: 'Used Lubricating Oil', id: 2 },
            { name: 'Used Oil Filters', id: 3 },
            { name: 'Used Coolant', id: 4 },
            { name: 'Contaminated PPE', id: 5 },
            { name: 'Others - Please include in remarks', id: 6 }
        ]
        const DPAH0014Options = [
            { name: 'kg', id: 1 },
            { name: 'litre', id: 2 },
            { name: 'number', id: 3 }
        ]
        const DPAH0017Options = [
            { name: 'Incineration (w/o Energy Recovery)', id: 1 },
            { name: 'Incineration (with Energy Recovery)', id: 2 },
            { name: 'Landfill', id: 3 },
            { name: 'Recycle', id: 4 },
            { name: 'Composting', id: 5 },
            { name: 'Waste Recovery', id: 6 },
            { name: 'Unknown', id: 7 },
            { name: 'Others - Please include in remarks', id: 8 }
        ]
        let promise = new Promise(async (res, rej) => {
            try {

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                let count = 0
                if (typeof items['Amount'] !== 'number') {
                    count++
                    if (!items['Amount']) {
                        errorArray.push('Amount Field is Required')
                    } else {
                        errorArray.push('Amount Field Should Be Number Format')
                    }

                }
                if (typeof items['Average Density'] !== 'number') {
                    count++
                    if (!items['Average Density']) {
                        errorArray.push('Average Density Field is Required')
                    } else {
                        errorArray.push('Average Density Field Should Be Number Format')
                    }

                }

                if (!items['Disposal Method'] || !items['Disposal Method'].toString().length) {
                    count++
                    errorArray.push('Disposal Method Field is Required')

                } else if (items['Disposal Method'] && !DPAH0017Options.find(i => i.name.toLowerCase().trim() === items['Disposal Method'].toString().toLowerCase().trim())) {
                    count++
                    errorArray.push('Disposal Method Field Has Invalid Value')
                } else if (items['Disposal Method'] && DPAH0017Options.find(i => i.name.toLowerCase().trim() === items['Disposal Method'].toString().toLowerCase().trim())) {
                    if (DPAH0017Options.find(i => i.name.toLowerCase().trim() === items['Disposal Method'].toString().toLowerCase().trim()).id === 8) {
                        if (!items['Remarks for Other Disposal Method'] || !items['Remarks for Other Disposal Method'].toString().length) {
                            errorArray.push('Remarks for Other Disposal Method Field Has Invalid Value')
                            count++

                        }
                    }
                }
                if (!items['Hazardous Waste Category'] || !items['Hazardous Waste Category'].toString().length) {
                    count++
                    errorArray.push('Waste Category Field is Required')

                } else if (items['Hazardous Waste Category'] && !DPAH0013Options.find(i => i.name.toLowerCase().trim() === items['Hazardous Waste Category'].toString().toLowerCase().trim())) {
                    count++
                    errorArray.push('Hazardous Waste Category Field Has Invalid Value')
                } else if (items['Hazardous Waste Category'] && DPAH0013Options.find(i => i.name.toLowerCase().trim() === items['Hazardous Waste Category'].toString().toLowerCase().trim())) {
                    if (DPAH0013Options.find(i => i.name.toLowerCase().trim() === items['Hazardous Waste Category'].toString().toLowerCase().trim()).id === 6) {
                        if (!items['Remarks for Others Hazardous Waste Category'] || !items['Remarks for Others Hazardous Waste Category'].toString().length) {
                            errorArray.push('Remarks for Others Hazardous Waste Category Field Has Invalid Value')
                            count++

                        }
                    }
                }

                if (!items['Unit'] || !items['Unit'].toString().length) {
                    count++
                    errorArray.push('Unit Field is Required')

                } else if (items['Unit'] && !DPAH0014Options.find(i => i.name.toLowerCase().trim() === items['Unit'].toString().toLowerCase().trim())) {
                    count++
                    errorArray.push('Unit Field Has Invalid Value')
                }


                let local = {};
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;

                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (count === 0) {



                                    local['DPAH0013'] = DPAH0013Options.find(i => i.name.toLowerCase().trim() === items['Hazardous Waste Category'].toString().toLowerCase().trim()).id
                                    if (local['DPAH0013'] === 6) {
                                        local['DPAH0013B'] = items['Remarks for Others Hazardous Waste Category'].toString()
                                    } else {
                                        local['DPAH0013B'] = ''
                                    }

                                    local['DPAH0014'] = DPAH0014Options.find(i => i.name.toLowerCase().trim() === items['Unit'].toString().toLowerCase().trim()).id
                                    local['DPAH0015'] = parseFloat(items['Amount'])
                                    local['DPAH0016'] = parseFloat(items['Average Density'])
                                    local['DPAH0017'] = DPAH0017Options.find(i => i.name.toLowerCase().trim() === items['Disposal Method'].toString().toLowerCase().trim()).id
                                    if (local['DPAH0017'] === 8) {
                                        local['DPAH0017B'] = items['Remarks for Other Disposal Method'].toString()
                                    } else {
                                        local['DPAH0017B'] = ''
                                    }
                                    local['DPAH0018'] = items['Remarks'] ? items['Remarks'].toString() : ''
                                    local['attachment'] = null
                                    local['id'] = getID()




                                    local2.push(newObj);
                                    newObj['response'] = [local];
                                    newObj['excel_data'] = [items]
                                    res(newObj);
                                } else {
                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else if (!found && assObj[0].id) {
                            let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                            let refObjData = assObj.filter(i => i.refobj)
                            if (oldIndex && refObjData.length !== 0) {
                                let refObj = refObjData[0].refobj
                                console.log(refObjData[0])
                                newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                newObj['rp'] = items['Reporting Period'];
                                newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                newObj['form_type'] = 1
                                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                newObj['logs'] = [];
                                newObj['submitted_by'] = login_data.id;
                                newObj['submitted_on'] = dt;
                                newObj['last_modified_on'] = dt;
                                newObj['reporter_modified_on'] = dt;
                                newObj['reporter_modified_by'] = login_data.id;
                                newObj['last_modified_by'] = login_data.id;
                                newObj['entityUserAssId'] = refObj.id;
                                newObj['entityAssId'] = refObj.entityAssId;
                                newObj['standard'] = refObj.standard;
                                newObj['dcfId'] = refineddata[0].dcfId
                                if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                    if (count === 0) {


                                        local['DPAH0013'] = DPAH0013Options.find(i => i.name.toLowerCase().trim() === items['Hazardous Waste Category'].toString().toLowerCase().trim()).id
                                        if (local['DPAH0013'] === 6) {
                                            local['DPAH0013B'] = items['Remarks for Others Hazardous Waste Category'].toString()
                                        } else {
                                            local['DPAH0013B'] = ''
                                        }

                                        local['DPAH0014'] = DPAH0014Options.find(i => i.name.toLowerCase().trim() === items['Unit'].toString().toLowerCase().trim()).id
                                        local['DPAH0015'] = parseFloat(items['Amount'])
                                        local['DPAH0016'] = parseFloat(items['Average Density'])
                                        local['DPAH0017'] = DPAH0017Options.find(i => i.name.toLowerCase().trim() === items['Disposal Method'].toString().toLowerCase().trim()).id
                                        if (local['DPAH0017'] === 8) {
                                            local['DPAH0017B'] = items['Remarks for Other Disposal Method'].toString()
                                        } else {
                                            local['DPAH0017B'] = ''
                                        }
                                        local['DPAH0018'] = items['Remarks'] ? items['Remarks'].toString() : ''
                                        local['attachment'] = null
                                        local['id'] = getID()






                                        newObj['id'] = oldIndex.id


                                        newObj['response'] = [local];
                                        newObj['excel_data'] = [items]
                                        local2.push(newObj);
                                        res(newObj);
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                    newObj['edit'] = 1
                                    newObj['id'] = oldIndex.id
                                    newObj['response'] = []
                                    newObj['excel_data'] = [items]
                                    newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                    res(newObj);

                                    local2.push(newObj);
                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }
                        } else {

                            rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                            res(rejected)
                        }

                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise
    } else if (dcfId === 301) {


        let promise = new Promise(async (res, rej) => {
            try {
                let count = 0

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    count++
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    count++
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    count++
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    count++
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        count++
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                if (typeof items['Kilometers Traveled – Standard Taxi/ Sedan'] !== 'number') {
                    count++
                    errorArray.push('Kilometers Traveled – Standard Taxi/ Sedan Field is Required')
                }
                if (typeof items['Kilometers Traveled – Hybrid Vehicle'] !== 'number') {
                    count++
                    errorArray.push('Kilometers Traveled – Hybrid Vehicle Field is Required')
                }
                if (typeof items['Kilometers Traveled – Executive Taxi'] !== 'number') {
                    count++
                    errorArray.push('Kilometers Traveled – Executive Taxi Field is Required')
                }
                if (typeof items['Kilometers Traveled – Dual Purpose 4 x 4 (SUV)'] !== 'number') {
                    count++
                    errorArray.push('Kilometers Traveled – Dual Purpose 4 x 4 (SUV) Field is Required')
                }
                if (typeof items['Kilometers Traveled – Electric Vehicle (EV)'] !== 'number') {
                    count++
                    errorArray.push('Kilometers Traveled – Electric Vehicle (EV) Field is Required')
                }
                if (typeof items['Kilometers Traveled – Van/ Coach'] !== 'number') {
                    count++
                    errorArray.push('Kilometers Traveled – Van/ Coach Field is Required')
                }



                let local = {};
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;

                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (count === 0) {


                                    local['DPAN1314'] = items['Kilometers Traveled – Standard Taxi/ Sedan']
                                    local['DPAN1315'] = items['Kilometers Traveled – Hybrid Vehicle']
                                    local['DPAN1316'] = items['Kilometers Traveled – Executive Taxi']
                                    local['DPAN1317'] = items['Kilometers Traveled – Dual Purpose 4 x 4 (SUV)']
                                    local['DPAN1318'] = items['Kilometers Traveled – Electric Vehicle (EV)']
                                    local['DPAN1319'] = items['Kilometers Traveled – Van/ Coach']
                                    local2.push(newObj);
                                    newObj['response'] = [local];
                                    newObj['excel_data'] = [items]
                                    res(newObj);
                                } else {
                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else if (!found && assObj[0].id) {
                            let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                            let refObjData = assObj.filter(i => i.refobj)
                            if (oldIndex && refObjData.length !== 0) {
                                let refObj = refObjData[0].refobj
                                console.log(refObjData[0])
                                newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                newObj['rp'] = items['Reporting Period'];
                                newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                newObj['form_type'] = 1
                                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                newObj['logs'] = [];
                                newObj['submitted_by'] = login_data.id;
                                newObj['submitted_on'] = dt;
                                newObj['last_modified_on'] = dt;
                                newObj['reporter_modified_on'] = dt;
                                newObj['reporter_modified_by'] = login_data.id;
                                newObj['last_modified_by'] = login_data.id;
                                newObj['entityUserAssId'] = refObj.id;
                                newObj['entityAssId'] = refObj.entityAssId;
                                newObj['standard'] = refObj.standard;
                                newObj['dcfId'] = refineddata[0].dcfId
                                if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                    if (count === 0) {
                                        local['DPAN1314'] = items['Kilometers Traveled – Standard Taxi/ Sedan']
                                        local['DPAN1315'] = items['Kilometers Traveled – Hybrid Vehicle']
                                        local['DPAN1316'] = items['Kilometers Traveled – Executive Taxi']
                                        local['DPAN1317'] = items['Kilometers Traveled – Dual Purpose 4 x 4 (SUV)']
                                        local['DPAN1318'] = items['Kilometers Traveled – Electric Vehicle (EV)']
                                        local['DPAN1319'] = items['Kilometers Traveled – Van/ Coach']
                                        newObj['id'] = oldIndex.id

                                        local2.push(newObj);
                                        newObj['response'] = [local];
                                        newObj['excel_data'] = [items]

                                        res(newObj);
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                    newObj['edit'] = 1
                                    newObj['id'] = oldIndex.id
                                    newObj['response'] = []
                                    newObj['excel_data'] = [items]
                                    newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                    res(newObj);

                                    local2.push(newObj);
                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }
                        } else {

                            rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                            res(rejected)
                        }

                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise



    } else if (dcfId === 302) {


        let promise = new Promise(async (res, rej) => {
            try {
                let count = 0

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    count++
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    count++
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    count++
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    count++
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        count++
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                if (typeof items['Kilometers Traveled by Intercity Rail'] !== 'number') {
                    count++
                    errorArray.push('Kilometers Traveled by Intercity Rail Field is Required')
                }
                if (typeof items['Kilometers Traveled by Commuter Rail'] !== 'number') {
                    count++
                    errorArray.push('Kilometers Traveled by Commuter Rail Field is Required')
                }

                if (typeof items['Kilometers Traveled by Transit Rail'] !== 'number') {
                    count++
                    errorArray.push('Kilometers Traveled by Transit Rail Field is Required')
                }




                let local = {};
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;

                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (count === 0) {

                                 
                                    local['DPAN1313'] = 1
                                    local['DPAN1306'] = items['Kilometers Traveled by Intercity Rail']
                                    local['DPAN1307'] = items['Kilometers Traveled by Commuter Rail']
                                    local['DPAN1308'] = items['Kilometers Traveled by Transit Rail']
                                    local['legs'] = []
                                    local2.push(newObj);
                                    newObj['response'] = [local];
                                    newObj['excel_data'] = [items]
                                    res(newObj);
                                } else {
                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else if (!found && assObj[0].id) {
                            let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                            let refObjData = assObj.filter(i => i.refobj)
                            if (oldIndex && refObjData.length !== 0) {
                                let refObj = refObjData[0].refobj
                                console.log(refObjData[0])
                                newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                newObj['rp'] = items['Reporting Period'];
                                newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                newObj['form_type'] = 1
                                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                newObj['logs'] = [];
                                newObj['submitted_by'] = login_data.id;
                                newObj['submitted_on'] = dt;
                                newObj['last_modified_on'] = dt;
                                newObj['reporter_modified_on'] = dt;
                                newObj['reporter_modified_by'] = login_data.id;
                                newObj['last_modified_by'] = login_data.id;
                                newObj['entityUserAssId'] = refObj.id;
                                newObj['entityAssId'] = refObj.entityAssId;
                                newObj['standard'] = refObj.standard;
                                newObj['dcfId'] = refineddata[0].dcfId
                                if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                    if (count === 0) {
                                       
                                        local['DPAN1313'] = 1
                                        local['DPAN1306'] = items['Kilometers Traveled by Intercity Rail']
                                        local['DPAN1307'] = items['Kilometers Traveled by Commuter Rail']
                                        local['DPAN1308'] = items['Kilometers Traveled by Transit Rail']
                                        local['legs'] = []
                                        newObj['id'] = oldIndex.id

                                        local2.push(newObj);
                                        newObj['response'] = [local];
                                        newObj['excel_data'] = [items]

                                        res(newObj);
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                    newObj['edit'] = 1
                                    newObj['id'] = oldIndex.id
                                    newObj['response'] = []
                                    newObj['excel_data'] = [items]
                                    newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                    res(newObj);

                                    local2.push(newObj);
                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }
                        } else {

                            rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                            res(rejected)
                        }

                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise



    } else if (dcfId === 300) {


        let promise = new Promise(async (res, rej) => {
            try {
                let count = 0

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    count++
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    count++
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    count++
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    count++
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        count++
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                if (typeof items['Kilometers Traveled – Business/ First Class'] !== 'number') {
                    count++
                    errorArray.push('Kilometers Traveled – Business/ First Class Field is Required')
                }
                if (typeof items['Kilometers Traveled – Premium Economy Class'] !== 'number') {
                    count++
                    errorArray.push('Kilometers Traveled – Premium Economy Class Field is Required')
                }

                if (typeof items['Kilometers Traveled – Economy Class'] !== 'number') {
                    count++
                    errorArray.push('Kilometers Traveled – Economy Class Field is Required')
                }




                let local = {};
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;

                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (count === 0) {

                                 
                                    local['DPAN1304'] = 1
                                    local['DPAN1300'] = items['Kilometers Traveled – Business/ First Class']
                                    local['DPAN1301'] = items['Kilometers Traveled – Premium Economy Class']
                                    local['DPAN1302'] = items['Kilometers Traveled – Economy Class']
                                    local['legs'] = []
                                    local2.push(newObj);
                                    newObj['response'] = [local];
                                    newObj['excel_data'] = [items]
                                    res(newObj);
                                } else {
                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else if (!found && assObj[0].id) {
                            let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                            let refObjData = assObj.filter(i => i.refobj)
                            if (oldIndex && refObjData.length !== 0) {
                                let refObj = refObjData[0].refobj
                                console.log(refObjData[0])
                                newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                newObj['rp'] = items['Reporting Period'];
                                newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                newObj['form_type'] = 1
                                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                newObj['logs'] = [];
                                newObj['submitted_by'] = login_data.id;
                                newObj['submitted_on'] = dt;
                                newObj['last_modified_on'] = dt;
                                newObj['reporter_modified_on'] = dt;
                                newObj['reporter_modified_by'] = login_data.id;
                                newObj['last_modified_by'] = login_data.id;
                                newObj['entityUserAssId'] = refObj.id;
                                newObj['entityAssId'] = refObj.entityAssId;
                                newObj['standard'] = refObj.standard;
                                newObj['dcfId'] = refineddata[0].dcfId
                                if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                    if (count === 0) {
                                       
                                        local['DPAN1304'] = 1
                                        local['DPAN1300'] = items['Kilometers Traveled – Business/ First Class']
                                        local['DPAN1301'] = items['Kilometers Traveled – Premium Economy Class']
                                        local['DPAN1302'] = items['Kilometers Traveled – Economy Class']
                                        local['legs'] = []
                                        newObj['id'] = oldIndex.id

                                        local2.push(newObj);
                                        newObj['response'] = [local];
                                        newObj['excel_data'] = [items]

                                        res(newObj);
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                    newObj['edit'] = 1
                                    newObj['id'] = oldIndex.id
                                    newObj['response'] = []
                                    newObj['excel_data'] = [items]
                                    newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                    res(newObj);

                                    local2.push(newObj);
                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }
                        } else {

                            rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                            res(rejected)
                        }

                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise


    } else if (dcfId === 275){
        let promise = new Promise(async (res, rej) => {
            try {

                if (!items['Entity'] || !items['Entity'].toString().trim().length) {
                    errorArray.push('Entity Field is Required')
                }
                if (!items['Entity Level'] || !items['Entity Level'].toString().trim().length) {
                    errorArray.push('Entity Level Field is Required')
                }
                if (!items['Null Report(Yes/No)'] || !items['Null Report(Yes/No)'].toString().trim().length) {
                    errorArray.push('Null Report(Yes/No) Field is Required')
                }
                if (!items['Reporting Period'] || !items['Reporting Period'].toString().trim().length) {
                    errorArray.push('Reporting Period Field is Required')
                } else if (items['Reporting Period'].toString().trim().length) {
                    if (!validateDateString(items['Reporting Period'])) {
                        errorArray.push('Reporting Period Field Format is Incorrect')
                    }
                }
                console.log(items)
                let count = 0
                if (typeof items['Permanent Employees (Male)'] !== 'number') {
                    count++
                    errorArray.push('Permanent Employees (Male) Field is Required')
                }
                if (typeof items['Permanent Employees (Female)'] !== 'number') {
                    count++
                    errorArray.push('Permanent Employees (Female) Field is Required')
                }
                if (typeof items['Temporary Employees (Male)'] !== 'number') {
                    count++
                    errorArray.push('Temporary Employees (Male) Field is Required')
                }
                if (typeof items['Temporary Employees (Female)'] !== 'number') {
                    count++
                    errorArray.push('Temporary Employees (Female) Field is Required')
                }

                if (typeof items['Full-time Employees (Male)'] !== 'number') {
                    count++
                    errorArray.push('Full-time Employees (Male) Field is Required')
                }
                if (typeof items['Full-time Employees (Female)'] !== 'number') {
                    count++
                    errorArray.push('Full-time Employees (Female) Field is Required')
                }
                if (typeof items['Part-time Employees (Male)'] !== 'number') {
                    count++
                    errorArray.push('Part-time Employees (Male) Field is Required')
                }
                if (typeof items['Part-time Employees (Female)'] !== 'number') {
                    count++
                    errorArray.push('Part-time Employees (Female) Field is Required')
                }
                if (typeof items['Non-guaranteed Hours Employees (Male)'] !== 'number') {
                    count++
                    errorArray.push('Non-guaranteed Hours Employees (Male) Field is Required')
                }
                if (typeof items['Non-guaranteed Hours Employees (Female)'] !== 'number') {
                    count++
                    errorArray.push('Non-guaranteed Hours Employees (Female) Field is Required')
                }

                if (typeof items['Employees who are < 30 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('Employees who are < 30 years old (Male) Field is Required')
                }
                if (typeof items['Employees who are < 30 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('Employees who are < 30 years old (Female) Field is Required')
                }
                

                if (typeof items['Employees who are 30-50 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('Employees who are 30-50 years old (Male) Field is Required')
                }
                if (typeof items['Employees who are 30-50 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('Employees who are 30-50 years old (Female) Field is Required')
                }
              
                if (typeof items['Employees who are >50 years old (Male)'] !== 'number') {
                    count++
                    errorArray.push('Employees who are >50 years old (Male) Field is Required')
                }
                if (typeof items['Employees who are >50 years old (Female)'] !== 'number') {
                    count++
                    errorArray.push('Employees who are >50 years old (Female) Field is Required')
                }
                if (typeof items['Senior Management Employees (Male)'] !== 'number') {
                    count++
                    errorArray.push('Senior Management Employees (Male) Field is Required')
                }
                if (typeof items['Senior Management Employees (Female)'] !== 'number') {
                    count++
                    errorArray.push('Senior Management Employees (Female) Field is Required')
                }
                if (typeof items['Middle Management Employees (Male)'] !== 'number') {
                    count++
                    errorArray.push('Middle Management Employees (Male) Field is Required')
                }
                if (typeof items['Middle Management Employees (Female)'] !== 'number') {
                    count++
                    errorArray.push('Middle Management Employees (Female) Field is Required')
                }
                  if (typeof items['Non-management Employees (Male)'] !== 'number') {
                    count++
                    errorArray.push('Non-management Employees (Male) Field is Required')
                }
                if (typeof items['Non-management Employees (Female)'] !== 'number') {
                    count++
                    errorArray.push('Non-management Employees (Female) Field is Required')
                }

                if (typeof items['Senior Management Employees who are < 30 years old'] !== 'number') {
                    count++
                    errorArray.push('Senior Management Employees who are < 30 years old Field is Required')
                }
                if (typeof items['Senior Management Employees who are 30-50 years old'] !== 'number') {
                    count++
                    errorArray.push('Senior Management Employees who are 30-50 years old Field is Required')
                }
                if (typeof items['Senior Management Employees who are >50 years old'] !== 'number') {
                    count++
                    errorArray.push('Senior Management Employees who are >50 years old Field is Required')
                }
                
                if (typeof items['Middle Management Employees who are < 30 years old'] !== 'number') {
                    count++
                    errorArray.push('Middle Management Employees who are < 30 years old Field is Required')
                }
                if (typeof items['Middle Management Employees who are 30-50 years old'] !== 'number') {
                    count++
                    errorArray.push('Middle Management Employees who are 30-50 years old Field is Required')
                }
                if (typeof items['Middle Management Employees who are >50 years old'] !== 'number') {
                    count++
                    errorArray.push('Middle Management Employees who are >50 years old Field is Required')
                }
                if (typeof items['Non-management Employees who are < 30 years old'] !== 'number') {
                    count++
                    errorArray.push('Non-management Employees who are < 30 years old Field is Required')
                }
                if (typeof items['Non-management Employees who are 30-50 years old'] !== 'number') {
                    count++
                    errorArray.push('Non-management Employees who are 30-50 years old Field is Required')
                }
                if (typeof items['Non-management Employees who are >50 years old'] !== 'number') {
                    count++
                    errorArray.push('Non-management Employees who are >50 years old Field is Required')
                }

               if(count === 0){
                let set1 = items['Permanent Employees (Male)'] + items['Permanent Employees (Female)'] + items['Temporary  Employees (Male)'] + items['Temporary  Employees (Female)']
                let set2 = items['Full-time Employees (Male)'] + items['Full-time Employees (Female)'] +  items['Part-time Employees (Male)'] + items['Part-time Employees (Female)'] +  items['Non-guaranteed Hours Employees (Male)'] + items['Non-guaranteed Hours Employees (Female)']
                let set3 = items['Employees who are < 30 years old (Male)'] + items['Employees who are < 30 years old (Female)'] + items['Employees who are 30-50 years old (Male)'] + items['Employees who are 30-50 years old (Female)'] +items['Employees who are >50 years old (Male)'] +items['Employees who are >50 years old (Female)']
                let set4 = items['Senior Management Employees (Male)'] + items['Senior Management Employees (Female)'] + items['Middle Management Employees (Male)'] + items['Middle Management Employees (Female)'] + items['Non-management Employees (Male)'] + items['Non-management Employees (Female)']
                let set5 = items['Senior Management Employees who are < 30 years old'] + items['Senior Management Employees who are 30-50 years old'] +items['Senior Management Employees who are >50 years old'] + items['Middle Management Employees who are < 30 years old'] + items['Middle Management Employees who are 30-50 years old'] +items['Middle Management Employees who are >50 years old'] + items['Non-management Employees who are < 30 years old'] + items['Non-management Employees who are 30-50 years old'] +items['Non-management Employees who are >50 years old']
                if (set1 && (set1 === set2) && (set2 === set3) && (set3 === set4) && (set4 === set5)) {
                 
                } else if (items['Remarks'] && typeof items['Remarks'] === 'string' && items['Remarks'].length !== 0 ) {
                 
                } else {
        count++
        errorArray.push('Mismatch in count, so Remarks is Required')
                }   
            }



                let local = {};
                let newObj = {};
                let level = items['Entity Level'];
                if (level && items['Entity'] && items['Null Report(Yes/No)'] && items['Reporting Period'] && validateDateString(items['Reporting Period'])) {
                    const { result, obj } = getCoverageIdByText({
                        entity: items['Entity'],
                        level: level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null
                    }, rawsitelist);

                    if (result) {
                        newObj = { ...newObj, ...obj };
                        let dt = DateTime.utc();
                        const { found, assObj } = await getAssignment(items['Reporting Period'], obj, null, refineddata).then((assObj) => { return assObj });
                        console.log(found, assObj)
                        if (found) {
                            newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                            newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                            newObj['rp'] = items['Reporting Period'];
                            newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                            newObj['form_type'] = 1
                            newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                            newObj['logs'] = [];
                            newObj['submitted_by'] = login_data.id;
                            newObj['submitted_on'] = dt;
                            newObj['last_modified_on'] = dt;
                            newObj['reporter_modified_on'] = dt;
                            newObj['reporter_modified_by'] = login_data.id;
                            newObj['last_modified_by'] = login_data.id;
                            newObj['entityUserAssId'] = assObj[0].entityUserAssId;
                            newObj['entityAssId'] = assObj[0].entityAssId;

                            newObj['dcfId'] = refineddata[0].dcfId
                            if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                if (count === 0) {
                                    local['DPAN600']   = items['Permanent Employees (Male)'] 
                                    local['DPAN601']      = items['Permanent Employees (Female)'] 
                                    local['DPAN602']   = items['Temporary Employees (Male)'] 
                                    local['DPAN603']   = items['Temporary Employees (Female)']
                                    local['DPAN604']    = items['Full-time Employees (Male)'] 
                                    local['DPAN605']    =items['Full-time Employees (Female)'] 
                                    local['DPAN606']   =items['Part-time Employees (Male)'] 
                                    local['DPAN607']     = items['Part-time Employees (Female)'] 
                                    local['DPAN608']    = items['Non-guaranteed Hours Employees (Male)'] 
                                    local['DPAN609']    = items['Non-guaranteed Hours Employees (Female)']
                                local['DPAN610']     = items['Employees who are < 30 years old (Male)'] 
                                local['DPAN628']       = items['Employees who are < 30 years old (Female)'] 
                                local['DPAN611']     =  items['Employees who are 30-50 years old (Male)'] 
                                local['DPAN629']     =  items['Employees who are 30-50 years old (Female)'] 
                                     local['DPAN612']       = items['Employees who are >50 years old (Male)'] 
                                     local['DPAN630']      = items['Employees who are >50 years old (Female)']
                                 local['DPAN613']   = items['Senior Management Employees (Male)'] 
                                 local['DPAN614']     =  items['Senior Management Employees (Female)'] 
                                 local['DPAN615']    =  items['Middle Management Employees (Male)'] 
                                 local['DPAN616']    =  items['Middle Management Employees (Female)'] 
                                 local['DPAN617']    =  items['Non-management Employees (Male)'] 
                                 local['DPAN618']    =  items['Non-management Employees (Female)']
                                  local['DPAN619']  = items['Senior Management Employees who are < 30 years old'] 
                                  local['DPAN620'] =  items['Senior Management Employees who are 30-50 years old'] 
                                  local['DPAN621']  = items['Senior Management Employees who are >50 years old'] 
                                  local['DPAN622']  =  items['Middle Management Employees who are < 30 years old'] 
                                   local['DPAN623'] =  items['Middle Management Employees who are 30-50 years old'] 
                                   local['DPAN624'] = items['Middle Management Employees who are >50 years old'] 
                                   local['DPAN625'] =  items['Non-management Employees who are < 30 years old'] 
                                   local['DPAN626'] =  items['Non-management Employees who are 30-50 years old'] 
                                  local['DPAN627']  = items['Non-management Employees who are >50 years old']
                                  if(items['Remarks']){
                                    local['remarks'] = items['Remarks']
                                  }
                                    local2.push(newObj);
                                    newObj['response'] = [local];
                                    newObj['excel_data'] = [items]

                                    res(newObj);
                                } else {
                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                newObj['edit'] = 1
                                newObj['response'] = []
                                newObj['excel_data'] = [items]
                                newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                res(newObj);
                                local2.push(newObj);
                            } else {

                                rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                res(rejected)
                            }
                        } else if (!found && assObj[0].id) {
                            let oldIndex = quantitativesubmission.find(i => i.id === assObj[0].id)
                            let refObjData = assObj.filter(i => i.refobj)
                            if (oldIndex && refObjData.length !== 0) {
                                let refObj = refObjData[0].refobj
                                console.log(refObjData[0])
                                newObj['level'] = level.toString().toLowerCase().trim() === 'corporate' ? 0 : level.toString().toLowerCase().trim() === 'country' ? 1 : level.toString().toLowerCase().trim() === 'region' ? 2 : level.toString().toLowerCase().trim() === 'business unit' ? 3 : null;
                                newObj['reporting_period'] = getRPLuxon(items['Reporting Period']);
                                newObj['rp'] = items['Reporting Period'];
                                newObj['locationId'] = newObj[`tier${newObj['level']}_id`]
                                newObj['form_type'] = 1
                                newObj['user_type'] = login_data.role === 'clientuser' ? 1 : login_data.role === 'clientadmin' ? 0 : 2;
                                newObj['logs'] = [];
                                newObj['submitted_by'] = login_data.id;
                                newObj['submitted_on'] = dt;
                                newObj['last_modified_on'] = dt;
                                newObj['reporter_modified_on'] = dt;
                                newObj['reporter_modified_by'] = login_data.id;
                                newObj['last_modified_by'] = login_data.id;
                                newObj['entityUserAssId'] = refObj.id;
                                newObj['entityAssId'] = refObj.entityAssId;
                                newObj['standard'] = refObj.standard;
                                newObj['dcfId'] = refineddata[0].dcfId
                                if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'no') {
                                    if (count === 0) {
                                        local['DPAN600']   = items['Permanent Employees (Male)'] 
                                        local['DPAN601']      = items['Permanent Employees (Female)'] 
                                        local['DPAN602']   = items['Temporary Employees (Male)'] 
                                        local['DPAN603']   = items['Temporary Employees (Female)']
                                        local['DPAN604']    = items['Full-time Employees (Male)'] 
                                        local['DPAN605']    =items['Full-time Employees (Female)'] 
                                        local['DPAN606']   =items['Part-time Employees (Male)'] 
                                        local['DPAN607']     = items['Part-time Employees (Female)'] 
                                        local['DPAN608']    = items['Non-guaranteed Hours Employees (Male)'] 
                                        local['DPAN609']    = items['Non-guaranteed Hours Employees (Female)']
                                    local['DPAN610']     = items['Employees who are < 30 years old (Male)'] 
                                    local['DPAN628']       = items['Employees who are < 30 years old (Female)'] 
                                    local['DPAN611']     =  items['Employees who are 30-50 years old (Male)'] 
                                    local['DPAN629']     =  items['Employees who are 30-50 years old (Female)'] 
                                         local['DPAN612']       = items['Employees who are >50 years old (Male)'] 
                                         local['DPAN630']      = items['Employees who are >50 years old (Female)']
                                     local['DPAN613']   = items['Senior Management Employees (Male)'] 
                                     local['DPAN614']     =  items['Senior Management Employees (Female)'] 
                                     local['DPAN615']    =  items['Middle Management Employees (Male)'] 
                                     local['DPAN616']    =  items['Middle Management Employees (Female)'] 
                                     local['DPAN617']    =  items['Non-management Employees (Male)'] 
                                     local['DPAN618']    =  items['Non-management Employees (Female)']
                                      local['DPAN619']  = items['Senior Management Employees who are < 30 years old'] 
                                      local['DPAN620'] =  items['Senior Management Employees who are 30-50 years old'] 
                                      local['DPAN621']  = items['Senior Management Employees who are >50 years old'] 
                                      local['DPAN622']  =  items['Middle Management Employees who are < 30 years old'] 
                                       local['DPAN623'] =  items['Middle Management Employees who are 30-50 years old'] 
                                       local['DPAN624'] = items['Middle Management Employees who are >50 years old'] 
                                       local['DPAN625'] =  items['Non-management Employees who are < 30 years old'] 
                                       local['DPAN626'] =  items['Non-management Employees who are 30-50 years old'] 
                                      local['DPAN627']  = items['Non-management Employees who are >50 years old']
                                      if(items['Remarks']){
                                        local['remarks'] = items['Remarks']
                                      }
                                        newObj['id'] = oldIndex.id

                                        local2.push(newObj);
                                        newObj['response'] = [local];
                                        newObj['excel_data'] = [items]

                                        res(newObj);
                                    } else {
                                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                        res(rejected)
                                    }

                                } else if (items['Null Report(Yes/No)'].toString().toLowerCase().trim() === 'yes' && (!items['Type of Gas Refilled'] && !items['Type of AC/ Chiller / Freezer used'])) {
                                    newObj['edit'] = 1
                                    newObj['id'] = oldIndex.id
                                    newObj['response'] = []
                                    newObj['excel_data'] = [items]
                                    newObj['Attachment(Yes/No)-Default will be No'] = items['Attachment(Yes/No)-Default will be No'] ? items['Attachment(Yes/No)-Default will be No'].toString() : 'no'
                                    res(newObj);

                                    local2.push(newObj);
                                } else {

                                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                                    res(rejected)
                                }

                            } else {

                                rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                                res(rejected)
                            }
                        } else {

                            rejected.push({ ...items, "Rejection Remarks": 'You are not assigned to this entity on this form' })
                            res(rejected)
                        }

                    } else {



                        rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                        res(rejected)
                    }
                } else {


                    rejected.push({ ...items, "Rejection Remarks": errorArray.join(',') })
                    res(rejected)
                }
                res(rejected)
            } catch (e) {


                if (!e.found) {
                    rejected.push({ ...items, "Rejection Remarks": 'Ensure Imported Data Is For The Selected Year & Entities' })

                } else {
                    rejected.push({ ...items, "Rejection Remarks": 'Either Data Approved / Reviewed ' })
                }
                res(rejected)
                console.log(e)
            }

        }

        );

        return promise
    }



}
const getCoverageIdByText = (rowData, rawsitelist) => {
    let obj = { tier0_id: null, tier1_id: null, tier2_id: null, tier3_id: null }
    let result = false
    console.log(rowData);
    if (rowData.level === 0) {

        obj['tier0_id'] = 0
        result = true
    } else if (rowData.level === 1) {
        let country_index = rawsitelist.findIndex(
            (i) => i.name.toLowerCase().trim() === rowData.entity.toLowerCase().trim()
        );
        if (country_index !== -1) {

            obj['tier1_id'] = rawsitelist[country_index].id
            result = true

        }
    } else if (rowData.level === 2) {
        let city_index = rawsitelist
            .flatMap((i) =>
                i.locationTwos.flatMap((j) =>
                    j.locationThrees.map((k) => {
                        return {
                            site_id: k.id,
                            site_name: k.name,
                            city_id: j.id,
                            city_name: j.name,
                            country_id: i.id,
                            country_name: i.name,
                        };
                    })
                )
            )
            .findIndex((i) => {
                return i.city_name.toLowerCase().trim() === rowData.entity.toLowerCase().trim()
            });
        if (city_index !== -1) {
            result = true
            obj['tier2_id'] = rawsitelist.flatMap((i) =>
                i.locationTwos.flatMap((j) =>
                    j.locationThrees.map((k) => {
                        return {
                            site_id: k.id,
                            site_name: k.name,
                            city_id: j.id,
                            city_name: j.name,
                            country_id: i.id,
                            country_name: i.name,
                        };
                    })
                )
            )[city_index].city_id;
        }
    } else if (rowData.level === 3) {
        let site_index = rawsitelist
            .flatMap((i) =>
                i.locationTwos.flatMap((j) =>
                    j.locationThrees.map((k) => {
                        return {
                            site_id: k.id,
                            site_name: k.name,
                            city_id: j.id,
                            city_name: j.name,
                            country_id: i.id,
                            country_name: i.name,
                        };
                    })
                )
            )
            .findIndex((i) => {
                return i.site_name.toLowerCase().trim() === rowData.entity.toLowerCase().trim()
            });
        if (site_index !== -1) {
            result = true
            obj['tier3_id'] = rawsitelist.flatMap((i) =>
                i.locationTwos.flatMap((j) =>
                    j.locationThrees.map((k) => {
                        return {
                            site_id: k.id,
                            site_name: k.name,
                            city_id: j.id,
                            city_name: j.name,
                            country_id: i.id,
                            country_name: i.name,
                        };
                    })
                )
            )[site_index].site_id;
        }
    }
    return { result, obj };
};
const getAssignment = async (rp, obj, err, refineddata) => {
    console.log(rp)
    return new Promise((resolve, reject) => {
        (async () => {
            try {
                for (const item of refineddata) {
                    console.log(obj, item)
                    if (obj.tier0_id === item.tier0_id && obj.tier1_id === item.tier1_id && obj.tier2_id === item.tier2_id && obj.tier3_id === item.tier3_id) {

                        for (const card of item.dp_array) {
                            console.log(card, rp)
                            if (Object.keys(card)[0] !== 'name' && Object.keys(card)[0] === rp && Object.values(card)[0]) {

                                resolve({ found: Object.values(card)[0].filter(i => i.id !== undefined).length === 0, assObj: Object.values(card)[0] });
                                return; // Exit after resolving
                            }
                        }
                    }
                }
                if (err) {
                    err.push('Import Data Which Is Valid for Selected Year Reporting Period')
                }

                reject({ found: false }); // Reject if no match found
            } catch (error) {
                console.log(error)
                reject(error);
            }
        })();
    });
};
const getRPLuxon = (months) => {
    if (months.includes('to')) {
        let startDate = DateTime.fromFormat(months.split('to')[0].trim(), 'LLL-yyyy')
        let endDate = DateTime.fromFormat(months.split('to')[1].trim(), 'LLL-yyyy')
        let rp = []
        while (startDate <= endDate) {
            rp.push(startDate.toFormat('LL-yyyy'));
            startDate = startDate.plus({ months: 1 })
        }
        return rp
    } else {
        return [DateTime.fromFormat(months, 'LLL-yyyy').toFormat('LL-yyyy')]
    }
}
const randomIntFromInterval = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
};
const getID = () => {
    return (
        parseInt(Date.now() * Math.random()) +
        moment().unix() +
        randomIntFromInterval(1, 1000)
    );
};
function validateDateString(dateString) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Helper function to parse month and year
    function parseMonthYear(monthYear) {
        const [month, year] = monthYear.split("-");
        const monthIndex = months.indexOf(month);
        if (monthIndex === -1) return null;
        return { monthIndex, year: parseInt(year, 10) };
    }

    // Check single month format
    if (/^[A-Z][a-z]{2}-\d{4}$/.test(dateString)) {
        return true;
    }

    // Check range format
    const rangeMatch = dateString.match(/^([A-Z][a-z]{2}-\d{4}) to ([A-Z][a-z]{2}-\d{4})$/);
    if (!rangeMatch) return false;

    const start = parseMonthYear(rangeMatch[1]);
    const end = parseMonthYear(rangeMatch[2]);

    if (!start || !end) return false;

    const { monthIndex: startMonth, year: startYear } = start;
    const { monthIndex: endMonth, year: endYear } = end;

    if (startYear > endYear || (startYear === endYear && startMonth >= endMonth)) {
        return false; // Backward range or same month
    }

    const monthDifference = (endYear - startYear) * 12 + (endMonth - startMonth) + 1;
    return [2, 3, 6, 12].includes(monthDifference);
}
function findMatchingIds(array, ...titles) {
    let currentArray = array;
    const matchingIds = [];

    for (let i = 0; i < titles.length; i++) {
        const titleToMatch = titles[i].trim().toLowerCase();

        if (!currentArray) {
            // If currentArray is undefined, return the result and matchingIds
            return { result: false, id: matchingIds };
        }

        const item = currentArray.find(
            (obj) => obj.title.trim().toLowerCase() === titleToMatch
        );

        if (!item) {
            // If the title is not found, return the result and matchingIds
            return { result: false, id: matchingIds };
        }

        matchingIds.push(item.id);

        // Move to the next level if available, otherwise, set currentArray to undefined
        currentArray =
            item[`newEfSubcategory${i + 2}s`] !== undefined
                ? item[`newEfSubcategory${i + 2}s`]
                : undefined;
    }

    return { result: true, id: matchingIds };
}
export { importData }
