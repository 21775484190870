import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import $ from "jquery";
import { API } from "../../../components/constants/api_url";
import { ContextMenu } from 'primereact/contextmenu';
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from 'primereact/inputtextarea'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Tag } from "primereact/tag";
import APIServices from "../../../service/APIService";
import { Editor } from "primereact/editor";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

window.jQuery = $;
window.$ = $;
const { DateTime } = require('luxon')

const SRFInputEntrySubmission = () => {
    const selector = useSelector((state) => state.user.userdetail);
    const [form, setForm] = useState({ data1: [], title: 'Invalid Form' })
    const [sitelist, setSiteList] = useState([])
    const navigate = useHistory()
    const forceUpdate = useForceUpdate();
    const { id } = useParams();
    const params = useLocation()
    const dtref = useRef([{ current: null }, { current: null }, { current: null }, { current: null }, { current: null }, { current: null }, { current: null }, { current: null }, { current: null }, { current: null }, { current: null }, { current: null }, { current: null }, { current: null }]);

    const hardcodeddcf = []
    useEffect(() => {
    
 
        APIServices.get(API.DCF_Edit(id)).then((res) => {
          
       
                setForm(res.data)
        })
     
    }, [selector])

    const addRow = (data, index) => {
        console.log(data, form)
        let loc = JSON.parse(JSON.stringify(form))
        loc.data1[index].data.push(data[0])
        setForm(loc)
        forceUpdate()
    }
    const deleteRow = (rowData, rowindex, formindex) => {
        let loc = JSON.parse(JSON.stringify(form))
        loc.data1[formindex].data.splice(rowindex, 1)
        setForm(loc)
        forceUpdate()
    }
    const actionTemplate = (rowData, rowindex, formindex) => {


        return (
            <>

                <Button
                    icon="pi pi-trash"
                    className="mr-2 actionbtn" style={{
                        width: '20px',
                        height: '20px',
                        background: 'transparent',
                        color: 'palevioletred'
                    }}
                    onClick={() => {

                        if (form.data1[formindex].required === true) {

                            if (form.data1[formindex].data.length > 1) {
                                deleteRow(rowData, rowindex, formindex)
                            } else {
                                Swal.fire({
                                    position: "center",
                                    icon: "warning",
                                    title: "Mandatory to have atleast 1 entry",
                                    showConfirmButton: false,
                                    timer: 1500,
                                });
                            }

                        } else {
                            deleteRow(rowData, rowindex, formindex)
                        }

                    }}
                />
            </>
        )
    }
    const handleFileUpload = (e, item) => {
        let ext = e.target.files[0].name.substr(e.target.files[0].name.lastIndexOf('.'))
        let allowedext = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.pdf', '.PDF']
        if (allowedext.includes(ext)) {
            let formData = new FormData()
            formData.append('file', e.target.files[0])
            APIServices.post(API.FilesUpload, formData, {
                headers: {
                    'content-type': 'multipart/form-data'

                }
            }).then((res) => {
                res.data.files[0].extension = ext
                if (item.value === undefined) {
                    item['value'] = [res.data.files[0]]
                } else {
                    if (item.multiple) {
                        item['value'].push(res.data.files[0])
                    } else {
                        item['value'] = [res.data.files[0]]
                    }

                }
                forceUpdate()

            })
        } else {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "invalid file format, supported format JPEG,PNG & PDF only",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    }
    const resetFiles = (item, index) => {

        item.value = []
        forceUpdate()

    }
    const removeImage = (index, findex) => {

        form.data1[index].value.splice(findex, 1)
        forceUpdate()

    }

    const onCheckBoxSelected = (item, cbind) => {

        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = !items.selected
            }
        })
        item.value = item.values.filter(i => i.selected).map((i) => i.value)
        console.log(item)
        forceUpdate()
    }
    const onRadioButtonSelected = (item, cbind) => {

        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        item.value = item.values.filter(i => i.selected).map((i) => i.value)
        forceUpdate()
    }
    const onDateSelected = (item, val) => {

        item.value = val;
        forceUpdate()
    }
    const onNumberChange = (item, val, nan) => {
        if (nan !== undefined) {
            if (isNaN(val)) {
                item.value = undefined
            } else {
                item.value = val;
            }

        } else {
            item.value = val;
        }
        console.log(val)
        forceUpdate()
    }

    const onChangeDropwdown = (item, val) => {
        item.value = val;

        item.values.forEach((i) => {
            if (i.value === val) {
                i.selected = true
            } else {
                i.selected = false
            }
        })


        forceUpdate()
    }
    const onCellEditComplete = (e, dataind) => {
        let { rowData, newValue, cellIndex, field, rowIndex, originalEvent: event } = e;

        let loc = JSON.parse(JSON.stringify(form))
        setTimeout(() => {
            let item = getObjectAtIndex(loc.data1[dataind].data[rowIndex], cellIndex)
            item.data['value'] = rowData[field];
            loc.data1[dataind].data[rowIndex][loc.data1[dataind].value[cellIndex]].data['value'] = rowData[field];
            delete loc.data1[dataind].data[rowIndex][field]
            setForm(loc)
            console.log(loc)
            forceUpdate()
        }, 100)

    };
    function formatDate(dateInput, format) {
        let luxonDate;

        if (dateInput instanceof DateTime) {
            // If it's already a Luxon DateTime object, use it directly
            luxonDate = dateInput;
        } else if (typeof dateInput === 'string') {
            // If it's a string, parse it as a Luxon DateTime object
            luxonDate = DateTime.fromISO(dateInput);
        } else if (dateInput instanceof Date) {
            // If it's a JavaScript Date object, convert it to a Luxon DateTime object
            luxonDate = DateTime.fromJSDate(dateInput);
        } else {
            throw new Error('Invalid date input');
        }

        // Check if it's a valid Luxon DateTime object before formatting
        if (luxonDate instanceof DateTime) {
            // Format the date based on the provided format
            return luxonDate.toFormat(format);
        } else {
            throw new Error('Invalid date input');
        }
    }
    const getObjectAtIndex = (data, index) => {
        const keys = Object.keys(data);
        const key = keys[index];
        return data[key];
    };
    const renderEditor = (options) => {
        let item = getObjectAtIndex(options.rowData, parseInt(options.field.split('_')[1]))

        switch (item.type) {

            case 1:
                return (<InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />)
            case 2:
                return (<InputTextarea value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />)
            case 3:
                return (<InputText type="number" value={options.value} onChange={(e) => options.editorCallback(parseFloat(e.target.value))} />)
            case 4:

                return (<Dropdown optionLabel="label" optionValue="value" value={options.value} options={item.data.values} onChange={(e) => options.editorCallback(e.value)} />)
            case 6:

                return (<Calendar dateFormat="dd/mm/yy" value={options.value} onChange={(e) => options.editorCallback(e.value)} />)

            case 5:
                return null
        }
    }
    const renderTableData = (rowData) => {

        switch (rowData.type) {
            case 5:
                return (<div style={{ color: rowData.data.error === 1 && 'red' }} onClick={(e) => { e.preventDefault() }}>{rowData.data.label}</div>);
            case 1:
                return (<div style={{ color: rowData.data.error === 1 && 'red' }} onClick={(e) => { e.preventDefault() }}>{rowData.data.value === undefined ? 'click here' : rowData.data.value}</div>);
            case 2:
                return (<div style={{ color: rowData.data.error === 1 && 'red' }} onClick={(e) => { e.preventDefault() }}>{rowData.data.value === undefined ? 'click here' : rowData.data.value}</div>);
            case 3:
                return (<div style={{ color: rowData.data.error === 1 && 'red' }} onClick={(e) => { e.preventDefault() }}>{rowData.data.value === undefined ? 'click here' : rowData.data.value}</div>);
            case 4:
                return (<div style={{ color: rowData.data.error === 1 && 'red' }} onClick={(e) => { e.preventDefault() }}>{(rowData.data.value === null || rowData.data.value === undefined) ? 'Select option' : rowData.data.values.find((i) => { return i.value === rowData.data.value }).label}</div>);
            case 6:
                return (<div style={{ color: rowData.data.error === 1 && 'red' }} onClick={(e) => { e.preventDefault() }}> {(rowData.data.value === undefined || rowData.data.value == null) ? 'click here' : formatDate(rowData.data.value, 'dd-MM-yyyy')}</div>);
        }

    }

    const renderItems = (item, index) => {


        if (item.type === 'checkbox-group') {

            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 text-justify fs-16 fw-5'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5">
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="flex text-justify fs-14 fw-5" style={{ marginBottom: 10 }}>
                                    <Checkbox inputId={"cb" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => { onCheckBoxSelected(item, cbind) }} checked={cb.selected} />
                                    <label htmlFor={"cb" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }

                    </div>
                </div>
            )
        } else if (item.type === 'date') {

            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify' > {item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <Calendar className="col-5 fs-14 fw-4" value={(item.value !== null && item.value !== undefined) ? moment(item.value).toDate() : null} onChange={(e) => { onDateSelected(item, e.value) }} />
                </div>
            )
        } else if (item.type === 'number') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputText keyfilter="num" onWheel={(e) => e.target.blur()} style={{ width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, parseFloat(e.target.value), isNaN(e.target.value)) }} />
                    </div>
                </div>
            )
        } else if (item.type === 'paragraph') {
            return (
                <div className="flex flex-wrap  gap-3 fs-16 fw-5 text-justify justify-content-center" style={{ padding: 10 }}>

                    <label style={{ margin: 10 }}>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}</label>

                </div>
            )
        } else if (item.type === 'radio-group') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 grid" style={{ padding: 10 }} >
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="p-2 flex text-justify fs-14 fw-5 align-items-center">
                                    <RadioButton inputId={"rg" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => onRadioButtonSelected(item, cbind)} checked={cb.selected === true} />

                                    <label htmlFor={"rg" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'select') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>


                    <div className="col-5 fw-4 fs-14">
                        <Dropdown options={item.values} style={{ width: '100%' }} optionLabel='label' optionValue="label" value={item.value} onChange={(e) => { onChangeDropwdown(item, e.value) }} />
                    </div>

                </div>
            )
        } else if (item.type === 'text') {
            return (
                <div className="flex flex-wrap  gap-3 grid " style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputText style={{ width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, e.target.value) }} />
                    </div>
                </div>
            )
        } else if (item.type === 'textarea') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 " >
                        <InputTextarea style={{ width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, e.target.value) }} />
                    </div>
                </div>
            )
        }
        else if (item.type === 'htmleditor') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 " >
                        <div dangerouslySetInnerHTML={{ __html: item.label }} />
                    </div>
                    {/* <Editor value={item.value} style={{ width: '100%', padding: 10, maxHeight: 350, height: 'auto', overflow: 'scroll' }} onTextChange={(e) => onNumberChange(item, e.htmlValue)} /> */}


                </div>
            )
        } else if (item.type === 'file') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <div className="col-5">
                        <div style={{
                            background: '#f8f9fa',
                            border: '1px solid #ced4da',
                            borderRadius: '6px 6px 0px 0px',
                            padding: '8px'
                        }}>
                            <label htmlFor={'fp' + index} style={{
                                marginRight: 10,
                                padding: '5px',

                                background: 'white',
                                border: '1px solid cornflowerblue',
                                borderRadius: '10px',
                            }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-folder-open" />
                                Add Attachment
                            </label>
                            <label
                                onClick={() => { resetFiles(item, index) }}
                                style={{
                                    padding: '5px',
                                    fontSize: '15px',
                                    border: '1px solid indianred',
                                    background: 'white',
                                    borderRadius: '10px',
                                    color: 'indianred'
                                }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-undo" />
                                Reset
                            </label>
                            <input type='file' accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.pdf,.PDF" id={'fp' + index} hidden onChange={(e) => { handleFileUpload(e, item) }} ></input>
                        </div>
                        {item.value !== undefined && item.value.length !== 0 &&
                            <div className="col-12" style={{
                                maxHeight: 300,
                                overflow: 'auto',
                                border: '1px solid #ced4da'
                            }} >
                                <div style={{
                                    border: '1px solid #6366F170',
                                    borderRadius: '10px'
                                }}>
                                    {item.value.map((file, findex) => {

                                        return (
                                            <>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center', margin: 10
                                                }} >
                                                    <div className="flex align-items-center" style={{ width: '50%' }}>
                                                        {(file.extension === '.pdf' || file.extension === '.PDF') ?
                                                            <div>
                                                                <iframe src={API.Docs + file.originalname} /> </div> :
                                                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
                                                        <span className="flex flex-column text-left ml-3">
                                                            {file.originalname}
                                                            <small>{new Date().toLocaleDateString()}</small>
                                                        </span>
                                                    </div>
                                                    <Tag value={'View'} onClick={() => { window.open(API.Docs + file.originalname) }} style={{ width: '25%' }} severity="warning" className="px-3 py-2" />
                                                    <Button type="button" icon="pi pi-times" style={{ marginRight: 10 }} className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => removeImage(index, findex)} />
                                                </div>

                                            </>
                                        )
                                    })

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        } else if (item.type === 'table') {

            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <DataTable className="fullheight" style={{ width: '100%' }} value={item.data}  >


                        {item.value.map((h) => {

                            return <Column bodyClassName={(a) => { return a[h].type === 5 ? 'p-disabled' : '' }} header={h} body={(rowData) => { return renderTableData(rowData[h]); }} editor={(options) => { return renderEditor(options) }} onCellEditComplete={(e) => { onCellEditComplete(e, index) }} />;
                        })}


                    </DataTable>

                </div>
            )
        }
        else if (item.type === 'tableadd') {

            return (
                <div className="flex flex-wrap  gap-3 p-card" style={{ marginBottom: 15, padding: 10 }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label style={{ display: 'flex' }} className='col-12'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="flex justify-content-end" style={{ margin: 10, width: '100%' }}>
                        {item.data.length < item.maxrowlimit && <Button onClick={() => { addRow(item.newrow, index) }} icon='pi pi-plus'></Button>}
                    </div>
                    <DataTable scrollable showGridlines className="fullheight" style={{ width: '100%', maxHeight: 300 }} value={item.data}  >


                        {item.value.map((h) => {

                            return <Column bodyClassName={(a) => { return a[h].type === 5 ? 'p-disabled' : '' }} header={h} body={(rowData) => { return renderTableData(rowData[h]); }} editor={(options) => { return renderEditor(options) }} onCellEditComplete={(e) => { onCellEditComplete(e, index) }} />;
                        })}
                        <Column header='Action' style={{ width: 50 }} body={(rowData, e) => { return actionTemplate(rowData, e.rowIndex, index) }} />

                    </DataTable>

                </div>
            )
        }
        else if (item.type === 'checkpoint') {
            console.log(item)
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}

                        > help</i></span>} </label>
                    <div className="col-5" >
                        <div className="grid">
                            {item.values.map((i) => {
                                return <label onClick={() => { item.value = i.value; forceUpdate() }} className="col-2 mr-2" style={{ justifyContent: 'center', borderRadius: 5, display: 'flex', width: 110, color: item.value === i.value ? 'white' : 'black', background: item.value === i.value ? item.value === '1' ? 'green' : item.value === '2' ? 'red' : 'orange' : 'white', border: '1px solid darkgray' }}>{i.label}</label>
                            })}

                        </div>

                    </div>

                </div>
            )
        }

    }


    const checkHardcoded = () => {
        if (hardcodeddcf.includes(id)) {
            return true
        } else {
            return false
        }

    }
    const checkType1 = (item) => {
        if (!item.required) {
            return false
        } else if (item.required && item.value !== undefined && item.value.trim().length !== 0) {
            return false
        } else {
            return true
        }
    }
    const checkType2 = (item) => {
        if (!item.required) {
            return false
        } else if (item.required && item.value !== undefined && item.value !== null) {
            return false
        } else {
            return true
        }
    }
    const checkType3 = (item) => {
        if (!item.required) {
            return false
        } else if (item.required && item.value !== undefined && item.value.length !== 0) {
            return false
        } else {
            return true
        }
    }
    const checkForm = () => {
        let total = 0, count = 0
        form.data1.forEach((item) => {
            if (item.type === 'text' && item.required) {
                total += 1
                if (item.value !== undefined && item.value.trim().length !== 0) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'textarea' && item.required) {
                total += 1
                if (item.value !== undefined && item.value.trim().length !== 0) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'number' && item.required) {
                total += 1
                console.log(item.value)
                if (item.value !== undefined && !isNaN(item.value) && item.value.toString().trim().length !== 0) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'number' && item.required === false) {
                if (item.value !== undefined) {
                    if (item.value === null || isNaN(item.value)) {
                        count += 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) < 0) {
                        count += 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) >= 0) {
                        delete item.error
                    }
                }
            }
            else if (item.type === 'date' && item.required) {
                console.log(typeof item.value)
                total += 1
                if (item.value !== undefined && item.value !== null && (typeof item.value === 'object' || typeof item.value === 'string')) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'radio-group' && item.required) {
                total += 1
                if (item.value !== undefined && item.value.length !== 0) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'checkbox-group' && item.required) {
                total += 1
                if (item.value !== undefined && item.value.length !== 0) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'select' && item.required) {
                total += 1
                if (item.value !== undefined && item.value !== null) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'checkpoint' && item.required) {
                total += 1
                if (item.value !== undefined && item.value !== null) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'file' && item.required) {
                total += 1
                if (item.value !== undefined && item.value.length !== 0) {
                    count += 1
                    delete item.error
                } else {
                    item.error = 1
                }
            } else if (item.type === 'table' || item.type === 'tableadd') {
                if (item.type === 'tableadd' && item.required && item.data.length === 0) {
                    total -= total
                    item.error = 1
                } else {
                    delete item.error
                }

                Object.values(item.data).forEach((i) => {

                    Object.values(i).forEach((j) => {

                        if (j.type === 1 && j.data.required) {
                            total += 1
                            if (j.data.value !== undefined && j.data.value.trim().length !== 0) {
                                count += 1
                                delete j.data.error

                            } else {
                                j.data.error = 1
                            }
                        } else if (j.type === 2 && j.data.required) {
                            total += 1
                            if (j.data.value !== undefined && j.data.value.trim().length !== 0) {
                                count += 1
                                delete j.data.error

                            } else {
                                j.data.error = 1
                            }

                        } else if (j.type === 3 && j.data.required) {
                            total += 1
                            if (j.data.value !== undefined && !isNaN(j.data.value) && j.data.value.toString().trim().length !== 0) {
                                count += 1
                                delete j.data.error

                            } else {
                                j.data.error = 1
                            }

                        } else if (j.type === 4 && j.data.required) {
                            total += 1
                            if (j.data.value !== undefined && j.data.value !== null) {
                                count += 1
                                delete j.data.error

                            } else {
                                j.data.error = 1
                            }

                        } else if (j.type === 6 && j.data.required) {

                            total += 1
                            if (j.data.value !== undefined && item.value !== null && (typeof item.value === 'object' || typeof item.value === 'string')) {
                                count += 1
                                delete j.data.error
                            } else {
                                j.data.error = 1
                            }

                        }


                    })
                })
            }
        })
        forceUpdate()
        console.log(total, count, form.data1)
        return total === count
    }
    const getRP = (months) => {
        if (months.includes('to')) {
            let startDate = moment(months.split('to')[0].trim())
            let endDate = moment(months.split('to')[1].trim())
            let rp = []
            while (startDate <= endDate) {

                rp.push(startDate.format('MM-YYYY'));
                startDate.add(1, 'month');


            }
            return rp
        } else {
            return [moment(months).format('MM-YYYY')]
        }
    }
  
   
    return (
        <div className="grid" style={{ margin: 10 }}>
            <div className="col-12">
                {(selector.id !== undefined && form.data1.length !== 0) ?
                    <div >
                        <div className="fs-20 fw-7 clr-gray-900">
                            <h4><span className="mr-2">{'SRF ' + id}</span> {form.title}</h4>
                        </div>

                        {form.comments.trim().length !== 0 &&
                            <div className="p-card fw-5 fs-16" style={{ padding: 10, marginBottom: 20 }}>
                                <span style={{ fontWeight: 'bold' }}>Note :&nbsp;</span> {form.comments}
                            </div>}

                        {form.data1.length !== 0 &&
                            <div>
                                <div className="bg-white" style={{ borderRadius: 4 }}>

                                    <div style={{ padding: 24 }}>
                                        {form.data1.map((item, index) => {

                                            return renderItems(item, index)
                                        })

                                        }
                                    </div>
                                    <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                    <div className="grid" style={{ padding: 24 }} >
                                        <label className="col-5 fw-7 fs-16">Comments for Reviewer</label>
                                        <Editor className="text-area" value={form.remarks} style={{ width: '100%', padding: 10, maxHeight: 350, height: 158, overflow: 'scroll' }} onTextChange={(e) => { console.log(e.htmlValue); form.remarks = e.htmlValue; forceUpdate() }} />



                                    </div>
                                </div>
                                <div className="flex justify-content-end" style={{ marginTop: 20 }}>
                                    <Button label='Close' className="ml-4" onClick={() => { navigate.goBack() }} text ></Button>
                                    <Button label='Save as Draft' onClick={() => { }} className={"ml-4"} outlined ></Button>
                                    <Button label='Save and Submit' onClick={() => { }} className="ml-4" ></Button>
                                </div>




                            </div>


                        }




                    </div>
                    :
                    <div className="col-12 card">Form not found</div>
                    // <div className="col-12 card">You have no rights to access this page </div>

                }
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SRFInputEntrySubmission, comparisonFn);
