
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../../components/constants/api_url";
import APIServices from "../../service/APIService";


const initialState = {

    emissionFactor: [],


}
let uriString = {
    "include": [{ "relation": "stdYears", "scope": { "include": [{ "relation": "stdNames", "scope": { "include": [{ "relation": "stdScopes", "scope": { "include": [{ "relation": "stdTopics" }] } }] } }] } }]

}

let ef_std_api = () => { return API.STD_Country + `?filter=${encodeURIComponent(JSON.stringify(uriString))}` }

export const fetchEmissionFactor = createAsyncThunk('api/ef', async (id) => {
    return APIServices.get(ef_std_api()).then((res) => res.data)
})



const emissionFactor = createSlice({
    name: 'std_ef',
    initialState,

    extraReducers: (builder) => {

        builder.addCase(fetchEmissionFactor.fulfilled, (state, action) => { state.emissionFactor = action.payload; console.log(action.payload) })

    }



})

export default emissionFactor.reducer


